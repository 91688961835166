
import { Delete, PhotoCamera, Upload } from "@mui/icons-material";
// import { CircularProgress, IconButton } from "@mui/material";
import {
    CircularProgress,
    IconButton,
    TextField,
    Button,
    Snackbar,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    Icon,
  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRates } from "services/hospitalServices";
import { deleteRate, getRatesList } from "../hospital/Redux/actionCreator";
import { getMonetizationList} from "../users/Redux/actionCreator";
import { getVideoList, getUserVideoList, updateUserVideos} from "../users/Redux/actionCreator";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatDate } from "util/Helper";
import MDButton from "components/MDButton";
import { uploadHospitalRateFile } from "services/hospitalServices";
import { updateUserVideoById } from "services/videoService";
import UploadMonetizationFile from "../users/UploadMonetizationFile";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
import AddVideos from './components/addVideos';
const UserVideos = () => {

    const [selectedOptionPaid, setSelectedOptionPaid] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();

  //Handler Functions
  const handleDelete = (ID) => {
    dispatch(deleteRate(ID));
  };

  const handleCellClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleRowClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const {User_Videos_Data } = useSelector(
    (state) => state.user
  );
  // console.log("1382-->", Users_Data)
  const UsersVideoData = User_Videos_Data?.User_Videos_DataInfo;

  console.log("1-->", UsersVideoData);
  

  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        // const rowName = cellValues?.row?._id
        // return rowName
        const Id = cellValues?.row?._id;
        return Id;
        // return <Link to={`/videoManagement/video/${Id}`}>{Id}</Link>;
      },
      flex: 1,
    },
    {
      field: "usersInfo",
      headerName: `User Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.usersInfo?.name?.full
        const rowId = cellValues?.row?.usersInfo?._id
        // return rowName
          return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
      },
      flex: 1,

      // valueGetter: getCreatedAT,
    },
    {
      field: "videosInfo",
      headerName: `Video Title`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.videosInfo?.title
        const rowId = cellValues?.row?.videosInfo?._id
          return <Link to={`/videoManagement/video/${rowId}`}>{rowName}</Link>;
      },
      flex: 1,

      // valueGetter: getCreatedAT,
    },
    {
      field: "watchedPercentage",
      headerName: `Watched Percentage`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.watchedPercentage
        return rowName || "0"
      },
      flex: 1,
    },
    {
        field: "isWatched",
        headerName: `Is Watched`,
        // renderCell: (cellValues) => {
        //   const rowName = cellValues?.row?.isWatched
        //   if(rowName === false){
        //     return "False"
        //   }else{
        //     return "True"
        //   }
        // },
        renderCell: (cellValues) => {
            // console.log(cellValues);
            const isPaid = cellValues?.row?.isWatched;
            let selectedOptionPaidValue;
    
            if (selectedOptionPaid === false && isPaid === true) {
              selectedOptionPaidValue = "True";
            } else if (selectedOptionPaid === true && isPaid === true) {
              selectedOptionPaidValue = "True";
            } else if (isPaid === true) {
              selectedOptionPaidValue = "True";
            } else {
              selectedOptionPaidValue = "False";
            }
    
            const handleOptionChange = async (event) => {
              const selectedValue = event.target.value === "True";
    
              // Update the local state
              setSelectedOptionPaid(selectedValue);
    
              // const updatedIsPaidValue = selectedValue === 'Paid';
              const payload = {
                isWatched: selectedValue,
              };
    
              try {
                await dispatch(
                    updateUserVideos({ payload, id: cellValues.row._id })
                );
    
                // Update the local item
                const updatedData = UsersVideoData?.map((item) => {
                  if (item?._id === cellValues.row?._id) {
                    return { ...item, isWatched: selectedValue };
                  }
                  return item;
                });
                // dispatch(getUserVideoList(updatedData));
              } catch (error) {
                console.error("Error updating status:", error);
                // Handle error if needed
              }
            };
    
            // console.log("selectedOptionPaid", selectedOptionPaidValue)
            return (
              <div>
                <Select
                  value={selectedOptionPaidValue}
                  onChange={handleOptionChange}
                  label="Payment Status"
                  size="small"
                  style={{ paddingRight: "20px" }}
                >
                  <MenuItem value="True">
                    {/* {selectedOptionPaidValue ? "True" : "False"} */}
                    {selectedOptionPaidValue ? "Watched" : "Not Watched"}
                  </MenuItem>
                  <MenuItem value="False">
                    {/* {selectedOptionPaidValue ? "False" : "True"} */}
                    {selectedOptionPaidValue ? "Not Watched" : "Watched"}
                  </MenuItem>
                </Select>
              </div>
            );
          },
        
        flex: 1,
      },

  ];  

const [isOpen, setisOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getUserVideoList());
  }, []);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

    
  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        // title="Add Video"
        title1="UserVideos"
        // isOpen={isOpen}
        showisDeleted={false}
        showDialog={false}
        // handleDialogOpen={handleDialogOpen}
        // handleDialogClose={handleDialogClose}
        // handleUploadDialogClose={uploadDialogClose}
        // handleUploadDialogOpen={uploadDialogOpen}
        // dialogContent={
        //   <AddVideos handleDialogClose={handleDialogClose} />
        // }
        uploadDialogContent={
          <UploadMonetizationFile
            handleUploadDialogClose={uploadDialogClose}
            title="Monetization File"
          />
        }
      />

      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          density="compact"
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `Rates-${formatDate()}`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                marginCandidate: false,
                marginHospital: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={UsersVideoData.length > 0 ? false : true}
          columns={columns}
          rows={[...UsersVideoData]}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default UserVideos;

