import { IconButton, InputLabel, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { MenuItem, Select, TextField } from "@material-ui/core";
import MDInput from "components/MDInput";
import { useEffect, useRef, useState } from "react";
import { get, useForm } from "react-hook-form";
import MDButton from "../../../components/MDButton/index";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideoList,
  setVideo,
} from "../../users/Redux/actionCreator";
import { setPhoto } from "../../../layouts/events/Redux/actionCreator";
import { toast } from "react-toastify";
import { videoValidation } from "util/validations/hospitalPanelValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText } from "@material-ui/core";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { addNewVideo } from "../../../services/videoService";

const AddVideos = ({ handleDialogClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(videoValidation),
  });

  const fileInputRef = useRef(null);
  const thumbFileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedThumbFileName, setSelectedThumbFileName] = useState("");
  const [fileLogo, setFileLogo] = useState();
  const [thumbFileLogo, setThumbFileLogo] = useState();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    // console.log("data", data)

    let payload = {
      index: data.index,
      title: data.title,
      url: data.url,
      thumbNailUrl: data.thumbNailUrl,
    };

    try {
      const response = await addNewVideo(payload);
      console.log("response", response);
      if (response) {
        dispatch(getVideoList());
        handleDialogClose();
      }
    } catch (error) {
      console.error("Error creating monetization:", error);
    }
  };

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event) => {
    if (errors?.url?.message) errors.url.message = "";
    const file = event.target.files[0];
    setSelectedFileName(file.name);
    dispatch(setVideo(file))
      .then((response) => {
        const logoUrl = response.payload.data.data[0].url;
        setValue("url", logoUrl);
        setFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };

  const handleThumbFileIconClick = () => {
    if (thumbFileInputRef.current) {
      thumbFileInputRef.current.click();
    }
  };
  const handleThumbNailFileChange = (event) => {
    const file = event.target.files[0];
    // console.log("file>>", file)
    setSelectedThumbFileName(file.name);
    dispatch(setPhoto(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        const logoUrl = response.payload.data.data[0].url;
        // console.log("url", logoUrl);
        setValue("thumbNailUrl", logoUrl);
        setThumbFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <InputLabel fullWidth>Index</InputLabel>
          </Grid>
          <Grid item xs={9.5}>
            <form noValidate>
              <MDInput
                placeholder="Index"
                sx={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("index")}
                error={errors?.index?.message}
                helperText={errors?.index?.message}
              />
            </form>
          </Grid>
          <Grid item xs={2.5}>
            <InputLabel fullWidth>Title</InputLabel>
          </Grid>
          <Grid item xs={9.5}>
            <form noValidate>
              <MDInput
                placeholder="Title"
                sx={{ width: "100%" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("title")}
                error={errors?.title?.message}
                helperText={errors?.title?.message}
              />
            </form>
          </Grid>

          {/* video Upload */}
          <Grid item xs={2.5}>
            <InputLabel fullWidth className="input-labels">
              Upload Video
            </InputLabel>
          </Grid>
          <Grid item xs={8.5}>
            <form noValidate>
              <Box display="flex" alignItems="center">
                <MDInput
                  placeholder="Upload video file"
                  sx={{ width: "100%" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  value={selectedFileName}
                  readOnly
                />
                <AttachFileIcon
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                    width: "2rem",
                    height: "2rem",
                  }}
                  onClick={handleFileIconClick}
                />
                
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  accept=".mp4, .avi, .mov, .mkv, .flv"
                  onChange={handleFileChange}
                />
              </Box>
            </form>
          </Grid>

          {/* Thumb Upload */}
          <Grid item xs={2.5}>
            <InputLabel fullWidth className="input-labels">
              Upload Thumb Nail
            </InputLabel>
          </Grid>
          <Grid item xs={8.5}>
            <form noValidate>
              <Box display="flex" alignItems="center">
                <MDInput
                  placeholder="Upload Thumb Nail file"
                  sx={{ width: "100%" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  value={selectedThumbFileName}
                  readOnly
                />
                <AttachFileIcon
                  style={{
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                    width: "2rem",
                    height: "2rem",
                  }}
                  onClick={handleThumbFileIconClick}
                />
                <input
                  ref={thumbFileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  // accept=".img, .png"
                  onChange={handleThumbNailFileChange}
                />
              </Box>
            </form>
          </Grid>

          <MDButton
            variant="contained"
            color="info"
            type="submit"
            sx={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            Create
          </MDButton>
        </Grid>
      </form>
    </div>
  );
};

export default AddVideos;
