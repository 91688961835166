import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useFieldArray, useForm } from "react-hook-form";

// import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import { Edit } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link, Box } from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { setPhoto } from "../../../layouts/events/Redux/actionCreator";
import { setVideo } from "../../users/Redux/actionCreator";
// import MDButton from "./../../components/MDButton/index";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigationType } from "react-router-dom";
import {
  getVideoByIdService,
  updateVideoById,
} from "../../../services/videoService";
import { updateVideo } from "../../users/Redux/actionCreator";

import SelectDropDownNew from "./../../../examples/DropDownNew/index";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { toast } from "react-toastify";

function UpdateVideos() {
  const navigation = useNavigationType();
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileLogo, setFileLogo] = useState();
  const [logoName, setLogoName] = useState("");

  const thumbFileInputRef = useRef(null);
  const [selectedThumbFileName, setSelectedThumbFileName] = useState("");;
  const [thumbFileLogo, setThumbFileLogo] = useState();
  const [thumbLogoName, setThumbLogoName] = useState("");

  const [responseData, setResponseData] = useState([]);

  // const code = window.location.href.split("code")[1].split("/")[1];
  // const ID = window.location.href.split("code")[1].split("/")[2];
  const ID = window.location.href
    .split("videoManagement/video")[1]
    .split("/")[1];
  // console.log("ID", ID);
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getData = async () => {
    // let response = await getJobJobsByIdService(ID);
    let response = await getVideoByIdService(ID);
    // console.log("426--->", response);

    setResponseData(response[0]);
    setLogoName(response[0].url);
    setThumbLogoName(response[0].thumbNailUrl);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: async () => await getData(),
  });

  const [updated, setUpdated] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectEnableOptions = [
    {
      key: "true",
      value: "true",
    },
    {
      key: "false",
      value: "false",
    },
  ];
  //   console.log("responseData", responseData);
  // const [value, setValue] = useState(null);
  const onSubmit =  (data) => {
    // console.log("onSubmit", data);
    try {
      const payload = {
        index: data.index,
        title: data.title,
        url: data.url,
        enable: data?.enable ? data?.enable : false,
        thumbNailUrl: data.thumbNailUrl,
      };
    //   console.log("payload", payload);

      updateVideoById({ payload: payload, id: ID })
    .then((result) => {
        // console.log("Dispatch result:", result);
        if (result?.data?.status === true) {
            toast.success("Updated Successfully", {
                position: "top-right",
            });
            navigate("/videoManagement/video");
        } 
    })
    .catch((error) => {
        // Handle any unexpected errors during the dispatch call
        console.error("Error during update:", error);
        toast.error("An error occurred while updating the video", {
            position: "top-right",
        });
    });
    } catch (error) {
      console.error("Error during form submission:", error);
      // Handle the error appropriately
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const formValues = getValues();
  //   console.log("1074-->", formValues);
  // console.log("1-->",getValues("createdAt"))

  const formattedCreatedAt = getValues("createdAt")
    ? dayjs(getValues("createdAt"))?.format("YYYY/MM/DD")
    : null;

  const formattedUpdatedAt = getValues("updatedAt")
    ? dayjs(getValues("updatedAt"))?.format("YYYY/MM/DD")
    : null;

  // console.log("responseData", responseData)

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (errors?.client?.logo?.message) errors.client.logo.message = "";
    const file = event.target.files[0];
    // console.log("file>>", file)
    setSelectedFileName(file.name);
    dispatch(setVideo(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        const logoUrl = response.payload.data.data[0].url;
        // console.log("url", logoUrl);
        // Assuming data.client.logo is an array
        setValue("url", logoUrl);
        setFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };

  const handleThumbFileIconClick = () => {
    if (thumbFileInputRef.current) {
      thumbFileInputRef.current.click();
    }
  };
  const handleThumbNailFileChange = (event) => {
    const file = event.target.files[0];
    // console.log("file>>", file)
    setSelectedThumbFileName(file.name);
    dispatch(setPhoto(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        const logoUrl = response.payload.data.data[0].url;
        // console.log("url", logoUrl);
        setValue("thumbNailUrl", logoUrl);
        setThumbFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}  sx={{marginTop: "1.5rem"}}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Controller
                              control={control}
                              {...register(`enable`)}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={props.onChange}
                                />
                              )}
                            />
                          }
                          label="Enabled"
                        />
                      </FormGroup>
                    </Grid>

                     

                      {/* Verification status */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Index
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "1rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Index"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("index")}
                          //   value={responseData?.index}
                        />
                      </Grid>

                      {/* City */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Title
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Title"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("title")}
                          //   value={responseData?.title}
                        />
                      </Grid>

                      {/* Video Upload*/}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Video URL
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <form noValidate>
                          <Box display="flex" alignItems="center">
                            <MDInput
                              // onKeyDown={(e) => {
                              //     e.key === "Enter" && e.preventDefault();
                              //   }}
                              placeholder="Upload Video"
                              sx={{ width: "100%", marginTop: "2rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              //   value={logoName ? logoName : selectedFileName}
                              value={
                                selectedFileName ? selectedFileName : logoName
                              }
                              readOnly
                            />

                            <AttachFileIcon
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginTop: "2rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={handleFileIconClick}
                            />
                            <input
                              ref={fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                               accept=".mp4, .avi, .mov, .mkv, .flv"
                              onChange={handleFileChange}
                            />
                          </Box>
                        </form>
                        {/* {fileLogo ? (
                          <></>
                        ) : (
                          <FormHelperText>
                            {errors?.client?.logo?.message}
                          </FormHelperText>
                        )} */}
                      </Grid>

                      {/* ThumbNail  Upload*/}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Thumb Nail URL
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <form noValidate>
                          <Box display="flex" alignItems="center">
                            <MDInput
                              // onKeyDown={(e) => {
                              //     e.key === "Enter" && e.preventDefault();
                              //   }}
                              placeholder="Upload Video"
                              sx={{ width: "100%", marginTop: "2rem" }}
                              inputProps={{
                                style: { fontSize: 15, fontWeight: "bold" },
                              }}
                              //   value={logoName ? logoName : selectedFileName}
                              value={
                                selectedThumbFileName ? selectedThumbFileName : thumbLogoName
                              }
                              readOnly
                            />

                            <AttachFileIcon
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginTop: "2rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                              }}
                              onClick={handleThumbFileIconClick}
                            />
                            <input
                              ref={thumbFileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleThumbNailFileChange}
                            />
                          </Box>
                        </form>
                        {/* {fileLogo ? (
                          <></>
                        ) : (
                          <FormHelperText>
                            {errors?.client?.logo?.message}
                          </FormHelperText>
                        )} */}
                      </Grid>

                      

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedCreatedAt}
                          {...register("createdAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={formattedUpdatedAt}
                          {...register("updatedAt")}
                        />
                      </Grid>

                      

                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ color: "#fff" }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default UpdateVideos;
