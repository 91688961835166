
import { Delete, PhotoCamera, Upload } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRates } from "services/hospitalServices";
import CreateMonetizationRate from "./components/CreateMonetizationRate";
import { deleteRate, getRatesList } from "../hospital/Redux/actionCreator";
import { getMonetizationList } from "./Redux/actionCreator";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatDate } from "util/Helper";
import MDButton from "components/MDButton";
import { uploadHospitalRateFile } from "services/hospitalServices";
import { uploadMonetizationFile } from "services/userServices";
import UploadMonetizationFile from "../users/UploadMonetizationFile";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
const MonetizationMLP = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  //Handler Functions
  const handleDelete = (ID) => {
    dispatch(deleteRate(ID));
  };

  const handleCellClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleRowClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };
  

  function getWorkCity(params) {
    return `${params?.row?.city?.name|| "NA"}`;
  }

  function getRole(params) {
    return `${params?.row?.role?.name|| "NA"}`;
  }

  function getDepartment(params) {
    return `${params?.row?.department?.name|| "NA"}`;
  }

  function getSpecialization(params) {
    return `${params?.row?.specialization?.name|| "NA"}`;
  }

  function getNumExperience(params) {
    return `${params?.row?.numExperience?.name|| "NA"}`;
  }

  const columns = [
    
    {
      field: "_id",
      headerName: `Unique ID`,
      renderCell: (cellValues) => {
        // const rowName = cellValues?.row?._id
        // return rowName
        const Id = cellValues?.row?._id;
        // return Id;
        return <Link to={`/users/monetization/${Id}`}>{Id}</Link>;
      },
      flex: 1,
    },
    {
      field: "country",
      headerName: `Country`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.country
        return rowName
      },
      flex: 1,

    },
    {
      field: "city",
      headerName: `City`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.city?.name
        return rowName || "NA"
      },
      flex: 1,

      valueGetter: getWorkCity,
    },
    {
      field: "role",
      headerName: `Role`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.role?.name
        return rowName || "NA"
      },
      flex: 1, 
    valueGetter: getRole
  },
    {
      field: "department",
      headerName: `Department`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.department?.name
        return rowName || "NA"
      },
      flex: 1,
      valueGetter: getDepartment
    },
   
    {
      field: "specialization",
      headerName: `Specialization`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.specialization?.name
        return rowName || "NA"
      },
      flex: 1,
      valueGetter: getSpecialization 
    },

    {
      field: "experiance",
      headerName: `Experiance`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.numExperience?.name
        return rowName || "NA"
        
      },
      flex: 1,
      valueGetter: getNumExperience
    },
    {
        field: "valueType",
        headerName: `Value Type`,
        renderCell: (cellValues) => {
          const rowName = cellValues?.row?.valueType
        //   return rowName
        return (
            <Tooltip title={rowName} arrow>
              <div>{rowName}</div>
            </Tooltip>
          );
        },
        flex: 1.5,
      },
      {
        field: "value",
        headerName: `Value`,
        renderCell: (cellValues) => {
          // const rowName = cellValues?.row?.value
          // return rowName
          var a ;
          const rowName = cellValues?.row?.value
          if (rowName) {
            const valueType = cellValues?.row?.valueType
            if(valueType === "AbsoluteByPercentage"){
              a = rowName
              return `${a}%`;
            }
            a = rowName
            return `${a}`;
          } else {
            a = 0;
            return `${a}`;
          }
        },
        flex: 1.5,
      },

    // {
    //   field: "MaxJobcount",
    //   headerName: `Max Job count`,
    //   renderCell: (cellValues) => {
    //     const rowName = cellValues?.row?.maximumJob;
    //    return rowName || "NA"
    //   },
    //   // flex: 1.5,
    // },
    // {
    //   field: "RelativeDiscount",
    //   headerName: `Discount`,
    //   renderCell: (cellValues) => {
    //     const rowName = cellValues?.row?.relativeDiscount;
    //     return rowName || "NA"
        
    //   },
    //   // flex: 1.5,

    // //   valueGetter: getCreatedAT,
    // },

    // {
    //   field: "PPPDays",
    //   headerName: `PPP Days`,
    //   renderCell: (cellValues) => {
    //     var a;
    //     // const rowName = cellValues?.row?.createdAt;
    //     const rowName = cellValues?.row?.created_at;
    //     // console.log("1-->", rowName)
    //     if (rowName) {
    //       // const dt   = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
    //       // console.log("dt", dt)
    //       // return `${a}`;
    //       a = moment.utc(rowName).utcOffset("+05:30").format("HH:mm:ss");
    //       return `${a}`;
    //     } else {
    //       a = "NA";
    //       return `${a}`;
    //     }
    //     // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

    //     // return created;
    //   },
    // },

    // {
    //   field: "NonPPPDays",
    //   headerName: `Non PPP Days`,
    //   renderCell: (cellValues) => {
    //     var a;
    //     const rowName = cellValues?.row?.updatedAt;
    //     if (rowName) {
    //       a = moment(rowName).format("YYYY/MM/DD");
    //       return `${a}`;
    //     } else {
    //       a = "NA";
    //       return `${a}`;
    //     }
    //     // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

    //     // return updated;
    //   },
    //   type: "date",
    //   // flex: 1.5,
    // },
  ];  

const [isOpen, setisOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getMonetizationList());
  }, []);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const { hospital_rates_Data } = useSelector((state) => state?.hospital);
  var rates = hospital_rates_Data?.ratesInfo;

  const { Monetization_MLP } = useSelector(
        (state) => state.user
      );
      // console.log("1382-->", Users_Data)
      const monetizationUsers = Monetization_MLP?.Monetization_MLP_DataInfo;
    
      // console.log("1-->", premiumUsers);
    
      //   console.log("DocVerifaction_List_Data", DocVerifaction_List_Data);
      const totalCount = Monetization_MLP?.total;

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getRates({
        criteria: {
          isDeleted: checked,
        },
      });

      setDeletedRecords(response?.data?.docs);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getRates({
        criteria: {
          isDeleted: checked,
        },
      });
      rates = response?.data?.docs;
    }
  };

  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
} 

//title="Create Rate"   
//             showDialog={false}
//             title1="Monetization MLP"
//             showisDeleted={false}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Create Monetization"
        title1="Monetization MLP"
        isOpen={isOpen}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleDeleteApi={handleDeleteApi}
        Upload Props
        handleUploadDialogClose={uploadDialogClose}
        handleUploadDialogOpen={uploadDialogOpen}
        isOpenUpload={isOpenUpload}
        showUpload={true}
        dialogContent={
          <CreateMonetizationRate handleDialogClose={handleDialogClose} />
        }
        uploadDialogContent={
          <UploadMonetizationFile
            handleUploadDialogClose={uploadDialogClose}
            title="Monetization File"
          />
        }
      />

      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          density="compact"
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `MonetizationMLP-${formatDate()}`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                marginCandidate: false,
                marginHospital: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={monetizationUsers.length > 0 ? false : true}
          columns={columns}
          rows={showDeltedRecords ? deletedRecords : [...monetizationUsers]}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default MonetizationMLP;

