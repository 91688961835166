//*************Users *********************** */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadPhoto } from "services/userServices";

import { getUserByIdService } from "services/userServices";
import { updateUserService } from "services/userServices";
import { addNewUser } from "services/userServices";
import { deleteUserService } from "services/userServices";
import { getUsers } from "services/userServices";
import { getFilteredUserService } from "services/userServices";
import { getSearchedUserService } from "services/userServices";
import { getPremiumUsers } from "services/userServices";
import { getDocsFilteredService } from "services/userServices";
import { addMonetization } from "services/userServices";
import { getMonetizationService } from "services/userServices";
import { getMonetizationDiscount } from "services/userServices";
import { updateMonetizationDiscount } from "services/userServices";
import { updateMonetization } from "services/userServices";
import { uploadMonetizationFile } from "services/userServices";
import { getVideoService } from "services/videoService";
import { addNewVideo } from "services/videoService";
import { uploadVideo } from "services/videoService";
import { updateVideoById } from "services/videoService";
import { getUserVideoService } from "services/videoService";
import { updateUserVideoById } from "services/videoService";

// List USer
export const getUsersList = createAsyncThunk(
  "/user/listUsers",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getUsers(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//filter service User
export const getFilteredListUser = createAsyncThunk(
  "/user/filteredUser",
  async (payload, thunkAPI) => {
    // console.log("121-->",payload)
    const response = await getFilteredUserService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

//searched user
export const getSearchedListUser = createAsyncThunk(
  "/user/searchedUser",
  async (payload, thunkAPI) => {
    // console.log("121-->",payload)
    const response = await getSearchedUserService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);

// Delete User
export const deleteUser = createAsyncThunk(
  `/user/delete`,
  async (payload, thunkAPI) => {
    const response = await deleteUserService(payload);
    if (response?.status === 200) {
      thunkAPI.dispatch(getUsersList());
    }
    return response;
  }
);
//Add user
export const addUser = createAsyncThunk("/user", async (payload, thunkAPI) => {
  const { response, error } = await addNewUser(payload);
  thunkAPI.dispatch(getUsersList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});
//Get User By ID
export const getUserByID = createAsyncThunk(
  "/user/listUsers/id",
  async (payload, thunkAPI) => {
    const response = await getUserByIdService(payload);
    // if (error) {    //   return thunkAPI.rejectWithValue(error);    // }
    return response;
  }
);
//Update User
export const updateUser = createAsyncThunk(
  "/user/update",
  async (payload, thunkAPI) => {
    const { response, error } = await updateUserService(payload);
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);

export const setProfilePhoto = createAsyncThunk(
  "user/imageUpload",
  async (payload, thunkAPI) => {
    const response = await uploadPhoto([payload]);

    return response;
  }
);


//get premium users
// List USer
export const getPremiumUsersList = createAsyncThunk(
  "/premium/listPremiumUsers",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getPremiumUsers(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//filtered premium
//filter Invoice service
export const getFilteredDocsDetails = createAsyncThunk(
  "premium/filteredDocs",
  async (payload,) => {
    // console.log("121-->",payload)
    const response = await getDocsFilteredService(payload);
    // console.log("17>>>>", response)
    return response;
  }
);


//monetization


//--> Create Monetization
export const createMonetization = createAsyncThunk(
  "users/createmonDetails",
  async (payload, thunkAPI) => {
    const { response, error } = await addMonetization(payload);

    // if (response?.status == 200) {
    //   toast.success("Created Successfully", {
    //     position: "top-right",
    //   });
    // }
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);


//monetization list
export const getMonetizationList = createAsyncThunk(
  "/user/listMonetization",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getMonetizationService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//monetization discount 
export const getMonetizationDiscountList = createAsyncThunk(
  "/user/listDiscountMonetization",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getMonetizationDiscount(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);


//update discount monetization
export const UpdateMonetizationD = createAsyncThunk(
  "/monetizationDiscount/id",
  async (payload, thunkAPI) => {
    const { response, error } = await updateMonetizationDiscount(payload);
    return response;
  }
);


//update updateMonetization
export const UpdateMonetizationRule = createAsyncThunk(
  "/monetization/id",
  async (payload, thunkAPI) => {
    const { response, error } = await updateMonetization(payload);
    return response;
  }
);


//Upload Rate Card

export const uploadMonetizationCard = createAsyncThunk(
  "monetization/uploadCard",
  async (payload, thunkAPI) => {
    const { response, error } = await uploadMonetizationFile(payload);
    thunkAPI.dispatch(getMonetizationList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    console.log("actionResponse", response)
    return response;
  }
);



// List Video
export const getVideoList = createAsyncThunk(
  "/video/listvideo",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getVideoService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//Add new videos
export const addVideo = createAsyncThunk("/video", async (payload, thunkAPI) => {
  // console.log("addVideo", payload)
  const { response, error } = await addNewVideo(payload);
  thunkAPI.dispatch(getVideoList());
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return response;
});

//add videos
export const setVideo = createAsyncThunk(
  "video/upload",
  async (payload, thunkAPI) => {
    const response = await uploadVideo(payload);

    return response;
  }
);

//update video
export const updateVideo = createAsyncThunk(
  "/video/update",
  async (payload, thunkAPI) => {
    console.log("payloadaction",payload)
    const { response, error } = await updateVideoById(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);


// List user Video
export const getUserVideoList = createAsyncThunk(
  "/userVideo/video",
  async (payload, thunkAPI) => {
    // console.log("listUsers", payload)
    const response = await getUserVideoService(payload);
    // if (error) {
    //   return thunkAPI.rejectWithValue(error);
    // }
    return response;
  }
);

//update user
export const updateUserVideos = createAsyncThunk(
  "/userVideo/update",
  async (payload, thunkAPI) => {
    const { response, error } = await updateUserVideoById(payload);
    thunkAPI.dispatch(getUserVideoList());
    if (error) {
      return thunkAPI.rejectWithValue(error);
    }
    return response;
  }
);
