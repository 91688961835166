import { useEffect } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// Images
import jobizoLogo1 from "assets/images/jobizoLogo1.png";
import Login from "layouts/beforeAuth/login";
import GigBill from "layouts/billing/components/GigBill";
import AdditionalTrainingForm from "layouts/common/AdditionalTrainingForm";
import DepartmentTypeForm from "layouts/common/DepartmentType/DepartmentTypeForm";
import DocumentTypeForm from "layouts/common/DocumentType/DocumentTypeForm";
import ExperienceForm from "layouts/common/Experience/ExperienceForm";
import LanguageForm from "layouts/common/Languages/LanguageForm";
import ProfessionForm from "layouts/common/Profession/ProfessionForm";
import PRTForm from "layouts/common/ProfessionRegisterationType/PRTForm";
import QualificationForm from "layouts/common/Qualification/QualificationForm";
import SoftwareForm from "layouts/common/Softwares/SoftwareForm";
import SpecialisationForm from "layouts/common/Specialisation/SpecialisationForm";
import CityDetailsForm from "layouts/common/cities/CityDetailsForm";
import AdminUserForm from "layouts/dashboards/adminUsers/AdminUserForm";
import RoleUserForm from "layouts/dashboards/roles/RoleUserForm";
import EventCreation from "layouts/events/EventCreation";
import EventUpdation from "layouts/events/EventUpdation";
import DepartmentDetailsForm from "layouts/hospital/components/DepartmentDetailsForm";
import OrganizationDetailsForm from "layouts/hospital/components/OrganizationDetailsForm";
import RateDetailsForm from "layouts/hospital/components/RateDetailsForm";
import BranchUserForm from "layouts/hospital/hospitalBranch/BranchUserForm";
import CompanyDetailsForm from "layouts/hospital/hospitalCompany/CompanyDetailsForm";
import HospitalCorporateForm from "layouts/hospital/hospitalCorporate/HospitalCorporateForm";
import LongTermJobsForm from "layouts/job/LongTermJobsForm";
import ApplicationDetailsById from "layouts/job/components/ApplicationDetailsById";
import JobDetailsByBranch from "layouts/job/components/JobDetailsByBranch";
import JobDetailsByCandidateType from "layouts/job/components/JobDetailsByCandidateType";
import JobDetailsByDepartment from "layouts/job/components/JobDetailsByDepartment";
import PaymentTermDetailForm from "layouts/transaction/payment terms/PaymentTermDetailForm";
import PaymentDetailsForm from "layouts/transaction/payments/PaymentDetailsForm";
import UserInvoice from "layouts/userInvoices/component/UserInvoice";
import UserDetailByNameForm from "layouts/users/UserDetailForms/UsersDetailByNameForm";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateOutlet from "./PrivateRoute";
import JobDetailsByJobCode from "layouts/job/components/JobDetailsByJobCode";
import InterviewDetailsById from "layouts/events/components/InterviewJobDetailsById";
import ReferralDetailsById from "layouts/events/components/ReferralDetailsById";
// BankDetails  from 'layouts/userInvoices/component/BankDetailsById';
import BankDetailsById from "./layouts/userInvoices/component/BankDetailsById";
import Availability from "layouts/availbility/Availability";
import AvailabilityDetailsByAvailabilityId from "layouts/availbility/Components/AvailibilityDetailsByavailibilityID";
import GigBillTax from "layouts/billing/components/GigBillTax";
import PermaAppDetailByID from "layouts/permanent/components/PermaApplicationDetailsById";
import PermaJobDetailByID from "layouts/permanent/components/PermaJobDetailsById";
import PermanentJobDetailsByJobCode from "layouts/permanent/components/PermanentJobDetailsByJobCode";
import SkillsDetailsForm from "layouts/common/Skills/SkillsDetailsForm";
import DocVerificationDetailById from "layouts/common/docverification/DocVerificationDetailById";
import UserTaxInvoice from "layouts/userInvoices/component/ProfessionalTaxInvoice";
import PremiumUserJobCount from "layouts/users/UserDetailForms/PremiumUserBYJobCount";
import UpdateMonetizationById from "./layouts/users/components/UpdateMonetizationById";
import GCCJobDetailsByJobCode from "layouts/gccJob/components/GCCJobDetailsByJobCode";
import GCCDepartmentForm from "layouts/common/GCCDepartment/GCCDepartmentForm";
import GCCExperienceForm from "layouts/common/GCCExperience/GCCExperienceForm";
import UpdateVideos from "layouts/videoManagement/components/UpdateVideos";

export default function App() {
  const [controller] = useMaterialUIController();
  const { layout, sidenavColor, darkMode } = controller;
  const { t_id } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && t_id && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={jobizoLogo1}
              brandName="Jobizo"
              routes={routes}
            />
          </>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path={"/"} element={<PrivateOutlet />}>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="/users/name/:id" element={<UserDetailByNameForm />} />
            <Route
              path="/users/premiumuser/:id"
              element={<PremiumUserJobCount />}
            />
            <Route
              path="/users/documentverifications/:id"
              element={<DocVerificationDetailById />}
            />
            <Route
              path="/users/monetization/:id"
              element={<UpdateMonetizationById />}
            />

            <Route
              path="/jobDetails/code/:id/:id"
              element={<JobDetailsByJobCode />}
            />
            <Route
              path="/jobDetails/branch/:id"
              element={<JobDetailsByBranch />}
            />
            <Route
              path="/jobDetails/candidateType/:id"
              element={<JobDetailsByCandidateType />}
            />
            <Route
              path="/jobDetails/department/:id"
              element={<JobDetailsByDepartment />}
            />
            <Route path="/jobs/application/id/:id" element="" />
            <Route
              path="/jobs/application/job/:id"
              element={<JobDetailsByJobCode />}
            />
            <Route
              path="/jobs/application/professional/:id"
              element={<UserDetailByNameForm />}
            />
            <Route path="/jobs/application/organization/:id" element="" />
            <Route path="/locumBill/view/:id" element={<GigBill />} />
            <Route path="/locumBill/view1/:id" element={<GigBillTax />} />
            <Route path="/userInvoice/view/:id" element={<UserInvoice />} />
            <Route
              path="/professionalTaxInvoice/view/:id"
              element={<UserTaxInvoice />}
            />
            <Route
              path="/dashboards/admin/:userName"
              element={<AdminUserForm />}
            />
            <Route
              path="/dashboards/roles/:roleName"
              element={<RoleUserForm />}
            />
            <Route
              path="/hospital/branch/:branchName"
              element={<BranchUserForm />}
            />
            <Route
              path="/jobs/permanentJobs/:id"
              element={<LongTermJobsForm />}
            />
            <Route
              path="/common/additionalTrainings/:id"
              element={<AdditionalTrainingForm />}
            />
            <Route path="/common/cities/:id" element={<CityDetailsForm />} />
            <Route
              path="/common/additionalskills/:id"
              element={<SkillsDetailsForm />}
            />
            {/* <Route path="/users/documentverifications/:id" element={<DocVerificationDetailById />} /> */}
            <Route
              path="/hospital/department/:id"
              element={<DepartmentDetailsForm />}
            />
            <Route
              path="/hospital/organization/:id"
              element={<OrganizationDetailsForm />}
            />
            <Route
              path="/hospital/corporate/:id"
              element={<HospitalCorporateForm />}
            />
            <Route
              path="/hospital/company/:id"
              element={<CompanyDetailsForm />}
            />
            <Route path="/hospital/rates/:id" element={<RateDetailsForm />} />
            <Route
              path="/jobs/application/:id"
              element={<ApplicationDetailsById />}
            />
            <Route
              path="/common/qualification/:id"
              element={<QualificationForm />}
            />
            <Route path="/common/profession/:id" element={<ProfessionForm />} />
            <Route path="/common/softwares/:id" element={<SoftwareForm />} />
            <Route
              path="/common/specialization/:id"
              element={<SpecialisationForm />}
            />

            <Route path="/eventDetails/create" element={<EventCreation />} />
            <Route
              path="/eventDetails/update/:id"
              element={<EventUpdation />}
            />

            <Route path="/common/experience/:id" element={<ExperienceForm />} />
            <Route path="/common/languages/:id" element={<LanguageForm />} />
            <Route path="/common/registrationTypes/:id" element={<PRTForm />} />
          </Route>
          <Route
            path="/common/departmentTypes/:id"
            element={<DepartmentTypeForm />}
          />
          <Route
            path="/common/documentTypes/:id"
            element={<DocumentTypeForm />}
          />
          <Route
            path="/common/internationalDepartment/:id"
            element={<GCCDepartmentForm />}
          />
          <Route
            path="/common/internationalExperience/:id"
            element={<GCCExperienceForm />}
          />
          {/* Transaction Form Routes */}
          <Route
            path="/transaction/order/:id"
            element={<PaymentDetailsForm />}
          />
          <Route
            path="/transaction/transactions/:id"
            element={<PaymentTermDetailForm />}
          />

          <Route path="/eventDetails/update/:id" element={<EventUpdation />} />

          <Route path="/id/:id" element={<InterviewDetailsById />} />

          <Route path="/referralId/:id" element={<ReferralDetailsById />} />

          <Route path="/bankId/:id" element={<BankDetailsById />} />
          <Route path="/availbility/:id" element={<Availability />} />
          <Route
            path="/availbilityid/:id"
            element={<AvailabilityDetailsByAvailabilityId />}
          />

          <Route
            path="/permanent/application/:id"
            element={<PermaAppDetailByID />}
          />
          <Route path="/permanent/job/:id" element={<PermaJobDetailByID />} />
          <Route
            path="/permanent/job/code/:id/:id"
            element={<PermanentJobDetailsByJobCode />}
          />

          <Route
            path="/international/job/code/:id/:id"
            element={<GCCJobDetailsByJobCode />}
          />

          <Route path="/videoManagement/video/:id" element={<UpdateVideos />} />
        </Routes>

        <ToastContainer />
      </ThemeProvider>
    </>
  );
}
