import { Delete } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@mui/material/Button";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getUsers } from "services/userServices";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  deleteUser,
  getUsersList,
  getFilteredListUser,
  getSearchedListUser,
} from "./Redux/actionCreator";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterRole,
  filterCity,
  filterSpecialization,
  filterOrganization,
  updateStartDate,
  updateEndDate,
} from "./Redux/reducer";
import {
  setDepartmentType,
  setRoleType,
  setCityList,
  setSpecialisationsType,
} from "layouts/events/Redux/actionCreator";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import Papa from "papaparse";
import { toast } from "react-toastify";

function HospitalUsers() {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleDelete = (ID) => {
    dispatch(deleteUser(ID));
  };

  function getName(params) {
    return `${params?.row?.name?.full || "NA"}`;
  }
  function getProfession(params) {
    return `${params?.row?.professionalType?.name || "NA"}`;
  }
  function getDepartment(params) {
    return `${params?.row?.departments[0]?.name || "NA"}`;
  }
  function getSpecialization(params) {
    return `${params?.row?.specialization?.name || "NA"}`;
  }
  function getPhone(params) {
    return `${params?.row?.phoneNumbers[0]?.number || "NA"}`;
  }

  function getEmail(params) {
    return `${params?.row?.emails[0]?.address || "NA"}`;
  }

  function getATraining(params) {
    return `${params?.row?.additionalTraining[0]?.name || "NA"}`;
  }

  function getSalutation(params) {
    return `${params?.row?.name?.salutation || "NA"}`;
  }

  function getFirst(params) {
    return `${params?.row?.name?.first || "NA"}`;
  }
  function getMiddle(params) {
    return `${params?.row?.name?.middle || "NA"}`;
  }
  function getLast(params) {
    return `${params?.row?.name?.last || "NA"}`;
  }

  function getCurrentlyEmp(params) {
    // return `${params?.row?.profile?.currentlyEmployed || "NA"}`;
    return `${params?.row?.profile?.currentlyEmployed || "NA"}`;
  }
  function getGender(params) {
    return `${params?.row?.profile?.gender || "NA"}`;
  }

  function getPan(params) {
    return `${params?.row?.profile?.pan || "NA"}`;
  }

  function getAadhar(params) {
    return `${params?.row?.profile?.aadhar || "NA"}`;
  }

  function getFacebook(params) {
    return `${params?.row?.profile?.social?.facebook || "NA"}`;
  }

  function getLinkedin(params) {
    return `${params?.row?.profile?.social?.linkedin || "NA"}`;
  }

  function getCovid(params) {
    return `${params?.row?.profile?.covid || "NA"}`;
  }

  function getJobHistory(params) {
    return `${params?.row?.profile?.jobHistory || "NA"}`;
  }

  function getEducation(params) {
    return `${params?.row?.profile?.education || "NA"}`;
  }

  function getOrganization(params) {
    return `${params?.row?.profile?.organization?.name || "NA"}`;
  }

  function getRefrence(params) {
    return `${params?.row?.profile?.references || "NA"}`;
  }

  function getRole(params) {
    return `${params?.row?.role?.name || "NA"}`;
  }

  function getKeystone(params) {
    return `${params?.row?.canAccessKeystone || "NA"}`;
  }
  function getALocation(params) {
    return `${params?.row?.avatar?.location || "NA"}`;
  }

  function getAFileName(params) {
    return `${params?.row?.avatar?.fileName || "NA"}`;
  }

  function getAUri(params) {
    return `${params?.row?.avatar?.uri || "NA"}`;
  }

  function getABucket(params) {
    return `${params?.row?.avatar?.bucket || "NA"}`;
  }
  function getARegion(params) {
    return `${params?.row?.avatar?.region || "NA"}`;
  }
  function getAMimeType(params) {
    return `${params?.row?.avatar?.mimeType || "NA"}`;
  }
  function getASecureUrl(params) {
    return `${params?.row?.avatar?.avatar?.SecureUrl || "NA"}`;
  }
  function getAIsSecured(params) {
    return `${params?.row?.avatar?.avatar?.isSecured || "NA"}`;
  }
  function getACdnUrl(params) {
    return `${params?.row?.avatar?.avatar?.avatarCdnUrl || "NA"}`;
  }

  function getCompany(params) {
    return `${params?.row?.organizations[0]?.organization?.name || "NA"}`;
  }

  function getDocuments(params) {
    return `${params?.row?.documents?.length || "NA"}`;
  }
  function getAAddress(params) {
    return `${params?.row?.address?.address || "NA"}`;
  }
  function getACity(params) {
    return `${params?.row?.address?.city || "NA"}`;
  }

  function getIELTS(params) {
    return `${params?.row?.ieltsScore || "NA"}`;
  }
  function getAdditionalSkills(params) {
    var a;
    var rowName = params?.row?.additionalSkill[0];
    // console.log("rowName", rowName.length)
    if (rowName) {
      a = params?.row?.additionalSkill[0]?.name;
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }
  function getAState(params) {
    return `${params?.row?.address?.state || "NA"}`;
  }
  function getAPin(params) {
    return `${params?.row?.address?.pin || "NA"}`;
  }
  function getACountry(params) {
    return `${params?.row?.address?.country || "NA"}`;
  }
  function getACoords(params) {
    var a;
    if (params?.row?.address?.coords?.[0]?.name) {
      a = params?.row?.address?.coords?.[0]?.name;
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // return `${params?.row?.address?.coords[0]?.name || "NA"}`;
  }
  function getCreatedAt(params) {
    var a;
    const roleName = params?.row?.created_at;
    // const a = moment(roleName).format("YYYY/MM/DD");
    // return a;
    if (roleName) {
      a = moment(roleName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }
  function getUpdatedAt(params) {
    var a;
    const roleName = params?.row?.updated_at;
    // const a = moment(roleName).format("YYYY/MM/DD");
    // return a;
    if (roleName) {
      a = moment(roleName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }
  function getCreatedBy(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }
  function getUpdatedBy(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }

  function getPDateYear(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.year || "NA"
    }`;
  }
  function getPDateMonth(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.month || "NA"
    }`;
  }
  function getPDateDate(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.date || "NA"
    }`;
  }
  function getPDateHour(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.hour || "NA"
    }`;
  }
  function getPDateMin(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.min || "NA"
    }`;
  }
  function getPDateSec(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.sec || "NA"
    }`;
  }
  function getPDateMillis(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.millis || "NA"
    }`;
  }
  function getPDateTz(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.tz || "NA"
    }`;
  }
  function getPDateTs(params) {
    return `${
      params?.row?.professionalRegistration?.registrationDate?.ts || "NA"
    }`;
  }

  function getPEDateYear(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.year || "NA"}`;
  }
  function getPEDateMonth(params) {
    return `${
      params?.row?.professionalRegistration?.expiryDate?.month || "NA"
    }`;
  }
  function getPEDateDate(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.date || "NA"}`;
  }
  function getPEDateHour(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.hour || "NA"}`;
  }
  function getPEDateMin(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.min || "NA"}`;
  }
  function getPEDateSec(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.sec || "NA"}`;
  }
  function getPEDateMillis(params) {
    return `${
      params?.row?.professionalRegistration?.expiryDate?.millis || "NA"
    }`;
  }

  function getWorkCity(params) {
    return `${params?.row?.workCity?.name || "NA"}`;
  }

  function getFullName(params) {
    return `${params?.row?.name?.full || "NA"}`;
  }

  function getPEDateTz(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.tz || "NA"}`;
  }
  function getPEDateTs(params) {
    return `${params?.row?.professionalRegistration?.expiryDate?.ts || "NA"}`;
  }

  function getPNumber(params) {
    return `${params?.row?.professionalRegistration?.number || "NA"}`;
  }

  function getBBeneficiary(params) {
    return `${params?.row?.bankDetails?.beneficiaryName || "NA"}`;
  }

  function getBAccountNumber(params) {
    return `${params?.row?.bankDetails?.accountNumber || "NA"}`;
  }
  function getBDetail(params) {
    return `${params?.row?.bankDetails?.ifsc || "NA"}`;
  }

  function getBName(params) {
    return `${params?.row?.bankDetails?.bankName || "NA"}`;
  }

  function getBBranchAddress(params) {
    return `${params?.row?.bankDetails?.branchAddress || "NA"}`;
  }

  function getAttempts(params) {
    return `${params?.row?.testPaperAttempts[0]?.name || "NA"}`;
  }
  function getQualification(params) {
    return `${params?.row?.qualification?.name || "NA"}`;
  }
  function getNumExperience(params) {
    return `${params?.row?.numExperience?.name || "NA"}`;
  }

  function getCAddress(params) {
    return `${params?.row?.correspondenceAddress?.address || "NA"}`;
  }

  function getCCity(params) {
    return `${params?.row?.correspondenceAddress?.city || "NA"}`;
  }

  function getCState(params) {
    return `${params?.row?.correspondenceAddress?.state || "NA"}`;
  }

  function getCPin(params) {
    return `${params?.row?.correspondenceAddress?.pin || "NA"}`;
  }

  function getCCountry(params) {
    return `${params?.row?.correspondenceAddress?.country || "NA"}`;
  }

  function getCCoords(params) {
    return `${params?.row?.correspondenceAddress.Coords?.length || "NA"}`;
  }

  function getCEmployed(params) {
    return `${params?.row?.profile?.currentlyEmployed || "NA"}`;
  }

  function getLanguage(params) {
    var arr = [];
    {
      params?.row?.languageKnown &&
        params?.row?.languageKnown.map((elem) => {
          arr.push(elem.name);
        });
    }
    return `${arr}`;
  }

  function getWorked(params) {
    var arr = [];
    {
      params?.row?.workedOn.map((elem) => {
        arr.push(elem.name);
      });
    }
    return `${arr}`;
  }

  function getDOB(params) {
    const rowName = params?.row?.profile?.dob;
    var dayyy = new Date(rowName);
    var a, b, c, dateNow;
    if (rowName !== undefined) {
      a = dayyy.getDay();
      b = dayyy.getMonth() + 1;
      c = dayyy.getFullYear();
      dateNow = a + "/" + b + "/" + c;
      return dateNow;
    }
    var dateNOW = new Date(rowName);
    return dateNOW;
  }

  const columns = [
    {
      field: "role",
      headerName: `Role`,
      renderCell: (cellValues) => {
        // console.log("render", cellValues);
        const roleName = cellValues?.row?.role?.name;
        return roleName;
      },
      valueGetter: getRole,
    },
    //Name Columns
    {
      field: "name",
      headerName: `Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.full;
        const rowId = cellValues?.row?._id;
       let emailId = window.localStorage.getItem("email_id");
       console.log("emailId", emailId)
       const handleClick = (e) => {
        if (emailId === "operationtech@jobizo.com") {
          e.preventDefault();
          toast.error("You don't have access"); 
        }
      };
  
      return (
        <Tooltip title={rowName} arrow>
          <Link to={`name/${rowId}`} onClick={handleClick}>
            {rowName}
          </Link>
        </Tooltip>
      );
      },
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "emails",
      headerName: `Emails`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.emails[0]?.address;
        // const rowLength = rowValues?.length;
        // return `${rowLength} Values`;
        return rowValues;
      },
      flex: 1.5,
      valueGetter: getEmail,
    },
    {
      field: "phoneNumbers",
      headerName: `Phone Numbers`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.phoneNumbers[0]?.number;
        // const rowLength = rowValues?.length;
        // return `${rowLength} Values`;
        return rowValues;
      },
      flex: 1.5,
      valueGetter: getPhone,
    },
    {
      field: "status",
      headerName: `Status`,
    },
    {
      field: "specialization",
      headerName: `Specialization`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.specialization?.name;
        return roleName;
      },
      flex: 1.5,
      valueGetter: getSpecialization,
    },
    {
      field: "progress",
      headerName: `Progress`,
      type: "number",
    },
    {
      field: "creationSource",
      headerName: `CreationSource`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.creationSource;
        var a;
        if (roleName) {
          a = roleName;
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      flex: 1.5,
    },

    {
      field: "documents",
      headerName: `Documents`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.documents;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: getDocuments,
    },

    {
      field: "department",
      headerName: `Departments`,

      renderCell: (cellValues) => {
        console.log("cellValues", cellValues)
        var rowProfession;
        if (cellValues?.row?.departments.length > 0) {
          rowProfession = cellValues?.row?.departments[0];
          //const rowProfession = b ? b : "";
          return (
            <Link to={`/common/departmentTypes/${rowProfession?._id}`}>
              {rowProfession?.name}
            </Link>
          );
        } else {
          return "NA";
        }
      },
      valueGetter: getDepartment,
    },
    {
      field: "currentlyEmployed",
      headerName: `Currently Employed`,
      renderCell: (cellValues) => {
        return cellValues?.row?.profile?.currentlyEmployed
          ? cellValues?.row?.profile?.currentlyEmployed
          : "NA";
      },
      type: "boolean",
      valueGetter: getCEmployed,
    },

    {
      field: "organizations",
      headerName: `Organization Name`,
      renderCell: (cellValues) => {
        // const roleName = cellValues?.row?.profile?.organization?.name
        //   ? cellValues?.row?.profile?.organization?.name
        //   : "NA";
        // return roleName;
        var a = "NA";
        var rowName = cellValues?.row?.organizations;
        // console.log("rowName-->", rowName);
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          // console.log("2-->", rowName[0]?.name);
          a = rowName[0]?.name;
          return a;
        } else {
          // console.log("aa-->", a);
          return a;
        }
      },
      valueGetter: getOrganization,
    },
    {
      field: "workCity",
      headerName: `City`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.workCity?.name;
        return roleName;
      },
      valueGetter: getWorkCity,
    },

    {
      field: "salutationName",
      headerName: `Salutation Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.salutation;
        return rowName;
      },
      valueGetter: getSalutation,
    },
    {
      field: "firstName",
      headerName: "First Name",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.first;
        return rowName;
      },
      valueGetter: getFirst,
    },
    {
      field: "lastName",
      headerName: `Last Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.last;
        return rowName;
      },
      valueGetter: getLast,
    },
    {
      field: "enabled",
      headerName: `Enabled`,
      type: "boolean",
    },
    {
      field: "company",
      headerName: `Company`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.organizations[0]?.organization?.name;
        return rowName;
      },
      valueGetter: getCompany,
    },
    {
      field: "middleName",
      headerName: `Middle Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.middle;
        return rowName;
      },
      valueGetter: getMiddle,
    },
    {
      field: "fullName",
      headerName: `Full Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.name?.full;
        return rowName;
      },
      valueGetter: getFullName,
    },
    //Avatar Columns
    {
      field: "avatarLocation",
      headerName: `Avatar Location`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.location;
        return rowName;
      },
      valueGetter: getALocation,
    },
    {
      field: "avatarFileName",
      headerName: `Avatar File Name`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.fileName;
        return rowName;
      },
      valueGetter: getAFileName,
    },
    {
      field: "avatarUri",
      headerName: `Avatar Uri`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.uri;
        return rowName;
      },
      valueGetter: getAUri,
    },

    {
      field: "avatrBucket",
      headerName: `Avatar Bucket`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.bucket;
        return rowName;
      },
      valueGetter: getABucket,
    },
    {
      field: "avatarRegion",
      headerName: `Avatar Region`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.region;
        return rowName;
      },
      valueGetter: getARegion,
    },

    {
      field: "avatarMimeType",
      headerName: `Avatar Mime Type`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.mimeType;
        return rowName;
      },
      valueGetter: getAMimeType,
    },

    {
      field: "avatarSecureUrl",
      headerName: `Avatar Secure URl`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.avatar?.SecureUrl;
        return rowName;
      },
      valueGetter: getASecureUrl,
    },
    {
      field: "avatarIsSecured",
      headerName: `Avatar isSecured`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.avatar?.isSecured;
        return rowName;
      },
      type: "boolean",
      valueGetter: getAIsSecured,
    },
    {
      field: "avatarCdnUrl",
      headerName: `Avatar CDN URl`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.avatar?.avatar?.avatarCdnUrl;
        return rowName;
      },
      valueGetter: getACdnUrl,
    },
    //Address Columns
    {
      field: "address",
      headerName: `Address`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.address?.address;
        return rowName;
      },
      valueGetter: getAAddress,
    },
    {
      field: "addressCity",
      headerName: `Address City`,
      renderCell: (cellValues) => {
        // console.log("cellValues: ", cellValues)
        const rowName = cellValues?.row?.address?.city;
        return rowName;
      },
      valueGetter: getACity,
    },
    {
      field: "ieltsScore",
      headerName: `IELTS Score`,
      renderCell: (cellValues) => {
        // console.log("cellValues: ", cellValues)
        const rowName = cellValues?.row?.ieltsScore;
        return rowName;
      },
      valueGetter: getIELTS,
    },
    {
      field: "additionalSkill",
      headerName: `Additional Skill`,
      renderCell: (cellValues) => {
        // console.log("cellValues: ", cellValues)
        // const rowName = cellValues?.row?.additionalSkill[0];
        // return rowName;

        // var a;
        // var rowName = cellValues?.row?.additionalSkill[0];
        // // console.log("rowName", rowName.length)
        // if (rowName) {
        //   a = cellValues?.row?.additionalSkill[0]?.name;
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return `${a}`;
        // }

        const additionalSkills = cellValues?.row?.additionalSkill;
        if (additionalSkills && additionalSkills.length > 0) {
          const skillNames = additionalSkills
            .map((skill) => skill.name)
            .join(", ");
          // console.log("skillNames", skillNames)
          return (
            <Tooltip title={skillNames} arrow>
              <div>{skillNames}</div>
            </Tooltip>
          );
          // return skillNames;
        } else {
          return "NA";
        }
      },
      valueGetter: getAdditionalSkills,
    },
    {
      field: "isPremium",
      headerName: `IsPremium`,
      renderCell: (cellValues) => {
        // console.log(cellValues.row);
        var a;
        const roleName = cellValues?.row?.isPremium;
        if (roleName) {
          a = roleName;
          if(a === true){
            return "True";
          }else{
            return "False";
          }
          
        } else {
          a = "False";
          return `${a}`;
        }
        // let rowName = "false";
        // rowName = cellValues?.row?.isPremium;
        // console.log("1--->",rowName)
        // return rowName;
      },
      flex: 1.5,
      // valueGetter: getEmail,
    },
    {
      field: "addressState",
      headerName: `Address State`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.address?.state;
        return rowName;
      },
      valueGetter: getAState,
    },
    {
      field: "addressPin",
      headerName: `Address Pin`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.address?.pin;
        return rowName;
      },
      valueGetter: getAPin,
    },
    {
      field: "addressCountry",
      headerName: `Address Country`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.address?.country;
        return rowName;
      },
      valueGetter: getACountry,
    },
    {
      field: "addressCoords",
      headerName: `Address Coords`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.address?.coords?.[0]?.name;
        // const rowLength = rowValues?.length;
        // console.log("1>>", rowValues)
        // console.log("2>>", rowLength)
        // return `${rowLength} Values`;
        var a;
        if (rowValues) {
          a = rowValues?.length;
          return `${a} Values`;
          // return `${rowLength} Values`;
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      valueGetter: getACoords,
    },
    // CorrespondenceAddress Columns
    {
      field: "correspondenceAddress",
      headerName: `Correspondence Address `,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.correspondenceAddress?.address;
        return rowName;
      },
      valueGetter: getCAddress,
    },

    {
      field: "correspondenceAddressCity",
      headerName: `Correspondence Address City`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.correspondenceAddress?.city;
        return rowName;
      },
      valueGetter: getCCity,
    },

    {
      field: "correspondenceAddressState",
      headerName: `Correspondence Address State`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.correspondenceAddress?.state;
        return rowName;
      },
      valueGetter: getCState,
    },
    {
      field: "correspondenceAddressPin",
      headerName: `Correspondence Address Pin`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.correspondenceAddress?.pin;
        return rowName;
      },
      valueGetter: getCPin,
    },
    {
      field: "correspondenceAddressCountry",
      headerName: `Correspondence Address Country`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.correspondenceAddress?.country;
        return rowName;
      },
      valueGetter: getCCountry,
    },

    {
      field: "correspondenceAddressCoords",
      headerName: `Correspondence Address Coords`,
      renderCell: (cellValues) => {
        const rowValues =
          cellValues?.row?.correspondenceAddress?.coords[0]?.name;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: getCCoords,
    },
    //Profile Columns

    {
      field: "profileSocialFacebook",
      headerName: `Profile Socail Facebook`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.social?.facebook;
        return rowName;
      },
      valueGetter: getFacebook,
    },
    {
      field: "profileSocialLinkedin",
      headerName: `Profile Socail Linkedin`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.social?.linkedin;
        return rowName;
      },
      valueGetter: getLinkedin,
    },
    {
      field: "profileDOB",
      headerName: `Profile DOB`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.dob;

        if (cellValues?.row?.profile?.dob)
          return moment(cellValues?.row?.profile?.dob).format("DD/MM/YYYY");
        else return "NA";
      },
      type: "dob",
      valueGetter: getDOB,
    },
    {
      field: "profileCurrentlyEmployed",
      headerName: `Profile Currently Employed`,
      renderCell: (cellValues) => {
        // const rowName = cellValues?.row?.profile?.currentlyEmployed;
        // return rowName;
        return `${cellValues?.row?.profile?.currentlyEmployed || "NA"}`;
      },
      // type: "boolean",
      valueGetter: getCurrentlyEmp,
    },
    {
      field: "profileGender",
      headerName: `Profile Gender`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.gender;
        return rowName;
      },
      valueGetter: getGender,
    },
    {
      field: "profilePan",
      headerName: `Pan Number`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.pan;
        return rowName;
      },
      valueGetter: getPan,
    },
    {
      field: "profileAAdhar",
      headerName: `Profile Aadhar`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.aadhar;
        return rowName;
      },
      valueGetter: getAadhar,
    },

    {
      field: "profileCovid",
      headerName: `Profile Covid`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.profile?.covid;
        return rowName;
      },
      valueGetter: getCovid,
    },
    {
      field: "profileJobHistory",
      headerName: `Profile Job History`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.profile?.jobHistory;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: getJobHistory,
    },
    {
      field: "profileEducation",
      headerName: `Profile Education`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.profile?.education;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: getEducation,
    },
    {
      field: "profileReferences",
      headerName: `Profile References`,
      renderCell: (cellValues) => {
        const rowValues = cellValues?.row?.profile?.references;
        const rowLength = rowValues?.length;
        return `${rowLength} Values`;
      },
      valueGetter: getRefrence,
    },

    {
      field: "professionalType",
      headerName: `Professional Type`,
      renderCell: (cellValues) => {
        var b;
        if (cellValues?.row?.professionalType) {
          b = cellValues?.row?.professionalType?.name;
          const rowProfession = b ? b : "";
          return (
            <Link to={`profession/${rowProfession}`}>{rowProfession}</Link>
          );
        } else return "";
      },
      valueGetter: getProfession,
    },

    {
      field: "_id",
      headerName: `ID`,
    },
    {
      field: "testPaperAttempts",
      headerName: `Test Paper Attempts`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.testPaperAttempts[0]?.name;
        return roleName;
      },
      valueGetter: getAttempts,
    },
    {
      field: "languageKnown",
      headerName: `Language Known`,
      renderCell: (cellValues) => {
        // console.log("lan>>", cellValues);

        const languageKnowns = cellValues?.row?.languageKnownNew;
        if (languageKnowns && languageKnowns.length > 0) {
          const languageNames = languageKnowns
            .map((language) => language)
            .join(", ");
          console.log("languageNames", languageNames);
          return (
            <Tooltip title={languageNames} arrow>
              <div>{languageNames}</div>
            </Tooltip>
          );
        } else {
          return "NA";
        }
      },
      flex: 1,
      valueGetter: getLanguage,
    },
    {
      field: "workedOn",
      headerName: `Worked On`,
      renderCell: (cellValues) => {
        var arr = [];
        {
          cellValues?.row?.workedOn.map((elem) => {
            arr.push(elem.name);
          });
        }
      },
      valueGetter: getWorked,
    },
    {
      field: "additionalTraining",
      headerName: ` Additional Training`,
      renderCell: (cellValues) => {
        const additionalTrainings = cellValues?.row?.additionalTrainingNew;
        if (additionalTrainings && additionalTrainings.length > 0) {
          const additionalTrainingsNames = additionalTrainings
            .map((additional) => additional)
            .join(", ");
          console.log("additionalTrainingsNames", additionalTrainingsNames);
          return (
            <Tooltip title={additionalTrainingsNames} arrow>
              <div>{additionalTrainingsNames}</div>
            </Tooltip>
          );
        } else {
          return "NA";
        }
      },
      flex: 1,
      valueGetter: getATraining,
    },
    {
      field: "password",
      headerName: `Password`,
    },

    {
      field: "qualification",
      headerName: `Qualification`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.qualification?.name;
        return roleName;
      },
      valueGetter: getQualification,
    },

    {
      field: "numExperience",
      headerName: `Num Experience`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.numExperience?.name;
        return roleName;
      },
      valueGetter: getNumExperience,
    },

    {
      field: "created_at",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.created_at;
        if (roleName) {
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const getDayy = new Date(roleName);
        // var a = getDayy.getDate();
        // var b = getDayy.getMonth();
        // var c = getDayy.getFullYear();
        // var FullDate = a + "/" + b + "/" + c;
        // return FullDate;
      },
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "updated_at",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.updated_at;
        if(roleName){
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        }else{
          a = "NA";
          return `${a}`;
        }
        // const roleName = cellValues?.row?.updated_at;

        // const a = moment(roleName).format("YYYY/MM/DD");

        // return a;
        // const getDayy = new Date(roleName);
        // var a = getDayy.getDate();
        // var b = getDayy.getMonth();
        // var c = getDayy.getFullYear();
        // var FullDate = a + "/" + b + "/" + c;
        // return FullDate;
      },
      type: "date",
      valueGetter: getUpdatedAt,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.createdBy?.name?.full;
        return roleName;
      },
      valueGetter: getCreatedBy,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.updatedBy?.name?.full;
        return roleName;
      },
      valueGetter: getUpdatedBy,
    },
    {
      field: "canAccessKeystone",
      headerName: `Can Access KeyStone`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.canAccessKeystone;
        return roleName;
      },
      valueGetter: getKeystone,
    },

    {
      field: "profileCompleted",
      headerName: `Profile Completed`,
    },

    {
      field: "bankDetailsAccountNumber",
      headerName: `Bank Details Account Number`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.bankDetails?.accountNumber;
        return roleName;
      },
      valueGetter: getBAccountNumber,
    },

    {
      field: "bankDetailsBankName",
      headerName: `Bank Details Bank Name`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.bankDetails?.bankName;
        return roleName;
      },
      valueGetter: getBName,
    },

    {
      field: "bankDetailsBeneficiaryName",
      headerName: `Bank Details Beneficiary Name`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.bankDetails?.beneficiaryName;
        return roleName;
      },
      valueGetter: getBBeneficiary,
    },

    {
      field: "bankDetailsBranchAddress",
      headerName: `Bank Details Branch Address`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.bankDetails?.branchAddress;
        return roleName;
      },
      valueGetter: getBBranchAddress,
    },
    {
      field: "bankDetailsifsc",
      headerName: `Bank Details IFSC`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.bankDetails?.ifsc;
        return roleName;
      },
      valueGetter: getBDetail,
    },
    {
      field: "professionalRegistrationRegistrationDateYear",
      headerName: `Professional Registration Registration Date Year`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.year;
        return roleName;
      },

      valueGetter: getPDateYear,
    },
    {
      field: "professionalRegistrationExpiryDateYear",
      headerName: `Professional Registration Expiry Date Year`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.year;
        return roleName;
      },

      valueGetter: getPEDateYear,
    },

    {
      field: "professionalRegistrationRegistrationDateMonth",
      headerName: `Professional Registration Registration Date Month`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.month;
        return roleName;
      },

      valueGetter: getPDateMonth,
    },

    {
      field: "professionalRegistrationRegistrationDateDate",
      headerName: `Professional Registration Registration Date Date`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.date;
        return roleName;
      },

      valueGetter: getPDateDate,
    },

    {
      field: "professionalRegistrationExpirationDateMonth",
      headerName: `Professional Registration Expiration Date Month`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.month;
        return roleName;
      },

      valueGetter: getPEDateMonth,
    },
    {
      field: "professionalRegistrationExpirationDateDate",
      headerName: `Professional Registration Expiration Date Date`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.date;
        return roleName;
      },

      valueGetter: getPEDateDate,
    },

    {
      field: "professionalRegistrationRegistrationDateHour",
      headerName: `Professional Registration Registration Date Hour`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.hour;
        return roleName;
      },

      valueGetter: getPDateHour,
    },
    {
      field: "professionalRegistrationExpirationDateHour",
      headerName: `Professional Registration Expiration Date Hour`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.hour;
        return roleName;
      },

      valueGetter: getPEDateHour,
    },
    {
      field: "professionalRegistrationRegistrationDateMin",
      headerName: `Professional Registration Registration Date Min`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.min;
        return roleName;
      },

      valueGetter: getPDateMin,
    },

    {
      field: "professionalRegistrationExpirationDateMin",
      headerName: `Professional Registration Expiration Date Min`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.min;
        return roleName;
      },

      valueGetter: getPEDateMin,
    },

    {
      field: "professionalRegistrationRegistrationDateSec",
      headerName: `Professional Registration Registration Date Sec`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.sec;
        return roleName;
      },

      valueGetter: getPDateSec,
    },
    {
      field: "professionalRegistrationExpirationDateSec",
      headerName: `Professional Registration Expiration Date Sec`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.sec;
        return roleName;
      },

      valueGetter: getPEDateSec,
    },

    {
      field: "professionalRegistrationRegistrationDateMillis",
      headerName: `Professional Registration Registration Date Millis`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.millis;
        return roleName;
      },

      valueGetter: getPDateMillis,
    },
    {
      field: "professionalRegistrationExpirationDateMillis",
      headerName: `Professional Registration Expiration Date Millis`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.millis;
        return roleName;
      },

      valueGetter: getPEDateMillis,
    },
    {
      field: "professionalRegistrationRegistrationDateTz",
      headerName: `Professional Registration Registration Date Tz`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.tz;
        return roleName;
      },

      valueGetter: getPDateTz,
    },
    {
      field: "professionalRegistrationExpirationDateTz",
      headerName: `Professional Registration Expiration Date Tz`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.tz;
        return roleName;
      },

      valueGetter: getPEDateTz,
    },
    {
      field: "professionalRegistrationRegistrationDateTs",
      headerName: `Professional Registration Registration Date Ts`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.registrationDate?.ts;
        return roleName;
      },

      valueGetter: getPDateTs,
    },
    {
      field: "professionalRegistrationExpirationDateTs",
      headerName: `Professional Registration Expiration Date Ts`,
      renderCell: (cellValues) => {
        const roleName =
          cellValues?.row?.professionalRegistration?.expiryDate?.ts;
        return roleName;
      },

      valueGetter: getPEDateTs,
    },

    {
      field: "professionalRegistrationRegistrationNumber",
      headerName: `Professional Registration Registration Number`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.professionalRegistration?.number;
        return roleName;
      },
      valueGetter: getPNumber,
    },

    {
      field: "delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          {" "}
          <DeleteDialog
            message={`Are you sure you want to delete ${row?.name?.full}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton
                aria-label="delete"
                size="small"
                // disabled={editDeleteVisibility}
              >
                {" "}
                <Delete fontSize="small" onClick={handlePopupOpen} />{" "}
              </IconButton>
            )}
          />{" "}
        </>
      ),
      sortable: false,
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [lastSearchKey, setLastSearchKey] = useState("");

  const handleDeleteApi = async (checked) => {
    if (checked) {
      setShowDeletedRecords(true);
      //API CALL for Delted Records
      const response = await getUsers(checked);
      setDeletedRecords(response?.data?.data);
    } else {
      setShowDeletedRecords(false);
      //API CALL for Not Delted Records
      const response = await getUsers(checked);
    }
  };

  const { Users_Data } = useSelector((state) => state.user);
  // console.log("1382-->", Users_Data)
  const users = Users_Data?.Users_DataInfo;
  // console.log(">>>>", users);

  const { Users_Filtered_Data } = useSelector((state) => state.user);
  const filteredUserData = Users_Filtered_Data?.Users_Filtered_DataInfo;
  // console.log("1-->", filteredUserData)

  const { Users_Searched_Data } = useSelector((state) => state.user);
  const SearchedUserData = Users_Searched_Data?.Users_Searched_DataInfo;
  // console.log("1-->", SearchedUserData);

  const totalCount = Users_Data?.total;
  const currentPage = useSelector((state) => state.user.currentPage);
  const pageSize = useSelector((state) => state.user.pageSize);
  const searchKey = useSelector((state) => state.user.searchKey);
  const filterRoleState = useSelector((state) => state.user.role);
  const filterWorkCity = useSelector((state) => state.user.workCity);
  const filterSpecializationData = useSelector(
    (state) => state.user.specialization
  );
  const filterOrganizationData = useSelector(
    (state) => state.user.organization
  );
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);

  const [role, setRole] = useState("");
  const [city, setCity] = useState("");
  const [getSpecializationValue, setGetSpecializationValue] = useState("");
  const [organizationsValue, setOrganizationsValue] = useState("");

  const roleOptions = [];
  const cityOptions = [];
  const departmentTypeOption = [];
  const organizationOptions = [];

  const { Role_Type_Data, City_List_Data, Specialization_Type_Data } =
    useSelector((state) => state.events);
  const { OrganizationsData } = useSelector((state) => state.common);

  const roleData = Role_Type_Data.roleInfo;
  const cityData = City_List_Data.cityListInfo;
  const specializationsData = Specialization_Type_Data.specializationInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;

  roleData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    roleOptions.push(obj);
  });

  cityData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    cityOptions.push(obj);
  });

  specializationsData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentTypeOption.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getUsersList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          role: filterRoleState,
          workCity: filterWorkCity,
          specialization: filterSpecializationData,
          organization: filterOrganizationData,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterRoleState,
    filterWorkCity,
    filterSpecializationData,
    filterOrganizationData,
    startDate,
    endDate,
  ]);



  
  
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getFilteredListUser({
  //         searchKey: searchKey,
  //         role: filterRoleState,
  //         workCity: filterWorkCity,
  //         specialization: filterSpecializationData,
  //         organization: filterOrganizationData,
  //         startDate: startDate,
  //         endDate: endDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   searchKey,
  //   filterRoleState,
  //   filterWorkCity,
  //   filterSpecializationData,
  //   filterOrganizationData,
  //   startDate,
  //   endDate,
  // ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const hasAnyFilter =
  //       searchKey.trim() !== "" ||
  //       filterRoleState?.trim() !== "" ||
  //       filterWorkCity?.trim() !== "" ||
  //       filterSpecializationData?.trim() !== "" ||
  //       filterOrganizationData?.trim() !== "" ||
  //       startDate.trim() !== "" ||
  //       endDate.trim() !== "";

  //     try {
  //       if (hasAnyFilter) {
  //         await dispatch(
  //           getFilteredListUser({
  //             searchKey: searchKey,
  //             role: filterRoleState,
  //             workCity: filterWorkCity,
  //             specialization: filterSpecializationData,
  //             organization: filterOrganizationData,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       } else {
  //         // If nothing is specified, you might want to handle this case
  //         await dispatch(
  //           getUsersList({
  //             currentPage: currentPage,
  //             pageSize: pageSize,
  //             searchKey: searchKey,
  //             role: filterRoleState,
  //             workCity: filterWorkCity,
  //             specialization: filterSpecializationData,
  //             organization: filterOrganizationData,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKey,
  //   filterRoleState,
  //   filterWorkCity,
  //   filterSpecializationData,
  //   filterOrganizationData,
  //   startDate,
  //   endDate,
  // ]);

  useEffect(() => {
    dispatch(setRoleType());
    dispatch(setCityList());
    dispatch(setSpecialisationsType());
    dispatch(setOrganizationsDropdown());
  }, []);

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  // const handleDownloadData = async () => {
  //   // console.log("1239>>>>>>", filteredJobData);
  //   // console.log("================================", softwares);
  //   // const filterAll = filteredJobData ? filteredJobData : softwares;
  //   // Apply your filter and search criteria
  //   // const filteredData =filteredJobData.filter(item => {
  //   //   return (
  //   //     (selectedOption === '' ) &&
  //   //     (manualValue === '' ) ||
  //   //     (searchKey === '' || item?.jobId?.includes(searchKey) || item?.branch?.includes(searchKey) || item?.city?.includes(searchKey))
  //   //   );
  //   // });
  //   //  console.log("1260---->", filteredData)

  //   const dataSource = softwares || filteredJobData;

  //   const formatDate = (dateString) => {
  //     return moment(dateString).format("YYYY/MM/DD");
  //   };

  //   const columnMapping = {
  //     code: "JobId",
  //     "branch.name": "Hospital Branch",
  //     "candidateType.name": "Candidate Type",
  //     "department.name": "Department",
  //     state: "State",
  //     numberOfJobs: " Number Of Jobs",
  //     numberOfDays: "Number Of Days",
  //     maxRate: "MaxRate",
  //     minRate: "MinRate",
  //     "branch.address.city": "Hospital City",
  //     isJobFinished: "Shift Status Flag",
  //     createdAt: "Created At",
  //     updatedAt: "Updated At",
  //   };

  //   // const filterAll = filteredData ? filteredData : softwares;

  //   const transformedData = dataSource.map((item) => {
  //     const transformedItem = {};
  //     Object.keys(columnMapping).forEach((databaseKey) => {
  //       const displayKey = columnMapping[databaseKey];
  //       if (databaseKey === "createdAt" || databaseKey === "updatedAt") {
  //         transformedItem[displayKey] = formatDate(item[databaseKey]);
  //       } else {
  //         const keys = databaseKey.split(".");
  //         let value = item;
  //         for (const key of keys) {
  //           value = value?.[key];
  //         }
  //         transformedItem[displayKey] = value;
  //       }
  //     });
  //     return transformedItem;
  //   });

  //   // Convert the filtered data to a CSV string using PapaParse
  //   const csv = Papa.unparse(transformedData);

  //   // Create a Blob object with the CSV data
  //   const blob = new Blob([csv], { type: "text/csv" });

  //   // Create a download link and trigger the download
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = "Jobizo Super Admin.csv";
  //   link.click();
  // };

  // Function to handle the download when the button is clicked
  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
    try {
      setLoading(true);
  
      const hasAnyFilter =
        searchKey.trim() !== "" ||
        filterRoleState?.trim() !== "" ||
        filterWorkCity?.trim() !== "" ||
        filterSpecializationData?.trim() !== "" ||
        filterOrganizationData?.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "";
    let csvData = ""
      try {
      
        if (hasAnyFilter) {
         const data = await dispatch(
            getFilteredListUser({
              searchKey,
              role: filterRoleState,
              workCity: filterWorkCity,
              specialization: filterSpecializationData,
              organization: filterOrganizationData,
              startDate,
              endDate,
            })
          );
          console.log("data", data)
          csvData = data.payload.data
        } else {
           const data = await dispatch(
            getFilteredListUser({
              searchKey,
              role: filterRoleState,
              workCity: filterWorkCity,
              specialization: filterSpecializationData,
              organization: filterOrganizationData,
              startDate,
              endDate,
            })
          );
          console.log("data", data)
          csvData = data.payload.data
        }

       
          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Users-${formatDate()}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        
       
    
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
 
     
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };


  // const handleDownloadData = async () => {
  //   try {
  //     // console.log("filter-->", filteredUserData)

  //     // if(filteredJobData && filteredJobData.length > 0){
  //     const response = filteredUserData;
  //     // console.log("1405-->",response)
  //     const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     // link.download = 'Jobizo Super Admin.csv';
  //     link.download = `Users-${formatDate()}.csv`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     // }else
  //     // console.log("users>>>>>", users);
  //     // if (users && users.length > 0) {
  //     //   console.log("222>>", users)
  //     //   const formatDate = (dateString) => {
  //     //     return moment(dateString).format("YYYY/MM/DD");
  //     //   };

  //     //   const columnMapping = {
  //     //     "role.name": "Role",
  //     //     "name.full": "Name",
  //     //     "emails[0].address": "Emails",
  //     //     "phoneNumbers[0].number": "Phone Numbers",
  //     //     status: "Status",
  //     //     "specialization.name": "Specialization",
  //     //     progress: "Progress",
  //     //     "profile.organization.name": "Organization Name",
  //     //     "workCity.name": "City",
  //     //     createdAt: "Created At",
  //     //   };
  //     //   const transformedData = users.map((item) => {
  //     //     const transformedItem = {};
  //     //     Object.keys(columnMapping).forEach((databaseKey) => {
  //     //       const displayKey = columnMapping[databaseKey];
  //     //       if (databaseKey === "createdAt") {
  //     //         transformedItem[displayKey] = formatDate(item[databaseKey]);
  //     //       } else {
  //     //         if (/\[\d+\]/.test(databaseKey)) {
  //     //           const nestedKeys = databaseKey.split(".");
  //     //           let nestedValue = item;
  //     //           for (const nestedKey of nestedKeys) {
  //     //             const indexMatch = nestedKey.match(/\[(\d+)\]/);
  //     //             if (indexMatch) {
  //     //               const index = parseInt(indexMatch[1]);
  //     //               nestedValue = nestedValue?.[nestedKey.replace(/\[\d+\]/, "")]?.[index];
  //     //             } else {
  //     //               nestedValue = nestedValue?.[nestedKey];
  //     //             }
  //     //           }
  //     //           transformedItem[displayKey] = nestedValue || "NA";
  //     //         }else{

  //     //         const keys = databaseKey.split(".");
  //     //         let value = item;
  //     //         for (const key of keys) {
  //     //           value = value?.[key];
  //     //         }
  //     //         transformedItem[displayKey] = value || "NA";
  //     //       }
  //     //       }
  //     //     });

  //     //     return transformedItem;
  //     //   });

  //     //   const csv = Papa.unparse(transformedData);

  //     //   // Create a Blob object with the CSV data
  //     //   const blob = new Blob([csv], { type: "text/csv" });

  //     //   // Create a download link and trigger the download
  //     //   const link = document.createElement("a");
  //     //   link.href = URL.createObjectURL(blob);
  //     //   link.download = "Jobizo Super Admin.csv";
  //     //   link.click();
  //     // } else {
  //     //   console.error("Failed to fetch the file");
  //     // }
  //   } catch (error) {
  //     console.error("Error during file download:", error);
  //   }
  // };

  // console.log("filterRoleState", filterRoleState);
  const handleRoleFilterChange = (e) => {
    const selectedRole = e.target.value;
    let selectedRoleObjectID;

    roleOptions.forEach((role) => {
      // console.log("role" , role)
      if (role.value === selectedRole) {
        selectedRoleObjectID = role.key;
      }
    });

    setRole(selectedRole);

    dispatch(filterRole(selectedRoleObjectID));
  };

  const handleWorkCityFilterChange = (e) => {
    const selectedCity = e.target.value;
    let selectedCityObjectID;

    cityOptions.forEach((city) => {
      // console.log("role" , role)
      if (city.value === selectedCity) {
        selectedCityObjectID = city.key;
      }
    });

    setCity(selectedCity);

    dispatch(filterCity(selectedCityObjectID));
  };

  const handleSpecializationFilterChange = (e) => {
    const selectedSpecialization = e.target.value;
    let selectedSpecializationObjectID;

    departmentTypeOption.forEach((spec) => {
      if (spec.value === selectedSpecialization) {
        selectedSpecializationObjectID = spec.key;
      }
    });

    setGetSpecializationValue(selectedSpecialization);

    dispatch(filterSpecialization(selectedSpecializationObjectID));
  };

  const handleOrganizationsFilterChange = (e) => {
    const selectedOrganizations = e.target.value;
    let selectedOrganizationsObjectID;

    organizationOptions.forEach((orga) => {
      if (orga.value === selectedOrganizations) {
        selectedOrganizationsObjectID = orga.key;
      }
    });

    setOrganizationsValue(selectedOrganizations);

    dispatch(filterOrganization(selectedOrganizationsObjectID));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate));
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    dispatch(searchKeyword(sanitizedSearchKey));
  };
  // console.log("inputValue>>", inputValue)

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value.trim()) {
      // Check if the input value is empty or contains only whitespace
      // window.location.reload();
      setInputValue("");
      dispatch(getUsersList()); // Dispatch action to reset search
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        showDialog={false}
        title1="Nurse/Hospital User"
        // isOpen={isOpen}

        showisDeleted={false}
        handleDeleteApi={handleDeleteApi}
      />
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px", paddingRight: "20px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
        <MenuItem value="Role">Role</MenuItem>
        {/* <MenuItem value="Name">Name</MenuItem> */}
        {/* <MenuItem value="Email">Emails</MenuItem> */}
        <MenuItem value="Specialisation">Specialization</MenuItem>
        <MenuItem value="Organization">Organization</MenuItem>
        <MenuItem value="workCity">City</MenuItem>
      </Select>
      {selectedOption && (
        <>
          {selectedOption === "Role" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select Role
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select Role"
                // value={filterAll.Role || ""}
                value={role || ""}
                onChange={handleRoleFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {roleOptions.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : selectedOption === "workCity" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select City
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select City"
                // value={filterAll.Role || ""}
                value={city || ""}
                onChange={handleWorkCityFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {cityOptions.map((city) => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : selectedOption === "Specialisation" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select Specialization
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select Specialization"
                // value={filterAll.Role || ""}
                value={getSpecializationValue || ""}
                onChange={handleSpecializationFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {departmentTypeOption.map((spec) => (
                  <MenuItem key={spec.value} value={spec.value}>
                    {spec.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : selectedOption === "Organization" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select Organization
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select Organization"
                // value={filterAll.Role || ""}
                value={organizationsValue || ""}
                onChange={handleOrganizationsFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {organizationOptions.map((orga) => (
                  <MenuItem key={orga.value} value={orga.value}>
                    {orga.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      )}

      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          // onChange={(e) => updateStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => handleEndDateChange(e.target.value)}
          // onChange={(e) => updateEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
      </>
      {/* </div> */}
      <Button
        onClick={handleDownloadData}
        variant="contained"
        color="primary"
        disabled={loading}
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
          // transition: 'background-color 0.3s',
          // backgroundColor: 'primary',
        }}
        // onMouseOver={(e) => e.target.style.backgroundColor = '#1976D2'} // Change to your primary color
        // onMouseOut={(e) => e.target.style.backgroundColor = 'primary'}
      >
       {loading ? "Loading..." : "Export"}
      </Button>
      <>
        <TextField
          label={`Search by name, email, phone,pan`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by name,email,phone`}
        value={searchKey}
        // onChange={(e) => dispatch(searchKeyword(e.target.value))}
        onChange={(e) => {
          const sanitizedSearchKey = e.target.value.replace(/[+#^&*()$]/g, "");
          dispatch(searchKeyword(sanitizedSearchKey));
        }}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          density="compact"
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                //Name Columns
                salutationName: false,
                fullName: false,
                lastName: false,
                firstName: false,
                middleName: false,
                delete: false,
                //Avatar Columns
                _id: false,
                avatarLocation: false,
                avatarFileName: false,
                avatarUri: false,
                avatrBucket: false,
                avatarRegion: false,
                avatarMimeType: false,
                avatarSecureUrl: false,
                avatarIsSecured: false,
                avatarCdnUrl: false,
                //Address Columns
                address: false,
                addressState: false,
                addressCity: false,
                addressPin: false,
                addressCoords: false,
                company: false,
                //correspondence Address Columns
                correspondenceAddress: false,
                correspondenceAddressCity: false,
                correspondenceAddressState: false,
                correspondenceAddressPin: false,
                correspondenceAddressCountry: false,
                correspondenceAddressCoords: false,
                //Profile
                profileSocialFacebook: false,
                profileSocialLinkedin: false,
                profileDOB: false,
                profileCurrentlyEmployed: false,
                profileGender: false,
                profilePan: true,
                profileAAdhar: false,
                profileCovid: false,
                profileJobHistory: false,
                profileEducation: false,
                profileReferences: false,
                // progressSteps:false,
                //Arrays
                emails: true,
                phoneNumbers: true,
                addressCountry: false,
                enabled: false,
                //Normal Coumns
                ID: false,
                testPaperAttempts: false,
                languageKnown: false,
                workedOn: false,
                additionalTraining: false,
                password: false,
                role: true,
                documents: false,
                department: false,
                additionalSkill: false,
                currentlyEmployed: false,
                professionalType: false,
                qualification: false,
                numExperience: false,
                workCity: true,
                organizations: true,

                created_at: true,
                createdBy: false,
                updated_at: false,
                updatedBy: false,

                canAccessKeystone: false,
                specialization: true,
                status: true,
                progress: true,
                creationSource: false,
                profileCompleted: false,

                //Bank Details
                bankDetailsAccountNumber: false,
                bankDetailsBankName: false,
                bankDetailsBeneficiaryName: false,
                bankDetailsBranchAddress: false,
                bankDetailsifsc: false,

                professionalRegistrationRegistrationDateYear: false,
                professionalRegistrationRegistrationDateMonth: false,
                professionalRegistrationRegistrationDateDate: false,
                professionalRegistrationRegistrationDateHour: false,
                professionalRegistrationRegistrationDateMin: false,
                professionalRegistrationRegistrationDateSec: false,
                professionalRegistrationRegistrationDateMillis: false,
                professionalRegistrationRegistrationDateTz: false,
                professionalRegistrationRegistrationDateTs: false,

                professionalRegistrationExpirationDateHour: false,
                professionalRegistrationExpiryDateYear: false,
                professionalRegistrationExpirationDateMonth: false,
                professionalRegistrationExpirationDateDate: false,
                professionalRegistrationExpirationDateMin: false,
                professionalRegistrationExpirationDateSec: false,
                professionalRegistrationExpirationDateMillis: false,
                professionalRegistrationExpirationDateTz: false,
                professionalRegistrationExpirationDateTs: false,
                professionalRegistrationRegistrationNumber: false,
              },
            },
          }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress />,
          }}
          // loading={users?.length > 0 ? false : true}
          loading={loading && <CircularProgress />}
          columns={columns}
          rows={showDeltedRecords ? deletedRecords : [...users]}
          // rows={displayRows}
          // pagination
          // pageSize={100}
          // rowsPerPageOptions={[100]}

          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          disableColumnFilter
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default HospitalUsers;
