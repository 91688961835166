import {
  CircularProgress,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Delete, ElectricalServicesOutlined } from "@mui/icons-material";
import CreateApplicationDialog from "./components/CreateApplicationDialog";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
import { DeleteJobs } from "layouts/common/Redux/actionCreator";
import {
  deleteJobApplication,
  getJobApplicationList,
  getFilteredListApp,
  getAllListApp,
} from "./Redux/actionCreator";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import moment from "moment";
import {
  getJobApplicationService,
  getAllJobApplicationService,
} from "shared/services";
import { toast } from "react-toastify";
import { formatDate } from "util/Helper";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@mui/material/Button";
import Papa from "papaparse";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  searchKeywordApp,
  filterData,
  updateStartDate,
  updateEndDate,
} from "./Redux/reducer";
const Applications = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const { job_Data } = useSelector((state) => state.job);
  // const softwares = job_Data?.job_Data_info;
  const { job_Application_Data } = useSelector((state) => state.job);
  const listFilterData = job_Application_Data?.job_Application_Data_info;

  const { job_All_Application_Data } = useSelector((state) => state.job);
  const softwares = job_All_Application_Data?.job_All_Application_Data_info;

  // console.log("allData", softwares);

  const { application_Filtered_Data } = useSelector((state) => state.job);
  const filteredApplicationData =
    application_Filtered_Data?.application_Filtered_Data_info;
  console.log("1111>>", filteredApplicationData)

  const totalCount = job_All_Application_Data?.total;
  const totalCount1 = job_Application_Data?.total;
  const currentPage = useSelector((state) => state.job.currentPage);
  const pageSize = useSelector((state) => state.job.pageSize);
  const searchKeyApp = useSelector((state) => state.job.searchKeyApp);
  const filterAll = useSelector((state) => state.job.filterAll);
  const startDate = useSelector((state) => state.job.startDate);
  const endDate = useSelector((state) => state.job.endDate);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [branchValue, setBranchValue] = useState("");
  const [organizationsValue, setOrganizationsValue] = useState("");
  const [applicationStateValue, setApplicationStateValue] = useState("");
  const [jobStateValue, setJobStateValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  

  

  const branchOptions = []; 
  const organizationOptions = [];
 
  const { hospital_branch_Data } = useSelector((state) => state.hospital);
  const { OrganizationsData } = useSelector((state) => state.common);

  const branchData = hospital_branch_Data?.branchInfo;
  const organizationInfo = OrganizationsData?.organizationsInfo;


  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });
  
  const selectApplicationStateOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "PENDING_TEST",
      value: "PENDING_TEST",
    },
    {
      key: "REVIEW",
      value: "REVIEW",
    },
    {
      key: "PENDING_INTERVIEW",
      value: "PENDING_INTERVIEW",
    },
    {
      key: "FINISHED",
      value: "FINISHED",
    },
    {
      key: "WITHDRAW",
      value: "WITHDRAW",
    },
    {
      key: "SCHEDULED_INTERVIEW",
      value: "SCHEDULED_INTERVIEW",
    },
  ];

  const selectJobStateOptions = [
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "APPROVED",
      value: "APPROVED",
    },
    {
      key: "ONGOING",
      value: "ONGOING",
    },
    {
      key: "VALIDATION",
      value: "VALIDATION",
    },
    {
      key: "COMPLETED",
      value: "COMPLETED",
    },
    {
      key: "CANCELLED",
      value: "CANCELLED",
    },
  ];  
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading1(true);
      await dispatch(
        getAllListApp({
          currentPage: currentPage,
          pageSize: pageSize,
        })
      );
      setLoading1(false);
    };

    fetchData();
  }, [dispatch, currentPage, pageSize]);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getJobApplicationList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKeyApp: searchKeyApp,
          filterAll: filterAll,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKeyApp,
    filterAll,
    startDate,
    endDate,
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getFilteredListApp({
  //         searchKeyApp: searchKeyApp,
  //         filterAll: filterAll,
  //         startDate: startDate,
  //         endDate: endDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, searchKeyApp, filterAll, startDate, endDate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const hasAnyFilter =
  //     searchKeyApp?.trim() !== "" ||
  //       Object.values(filterAll).some((value) => value?.trim() !== "") ||
  //       startDate.trim() !== "" ||
  //       endDate.trim() !== "";

  //     try {
  //       if (hasAnyFilter) {
  //         await dispatch(
  //           getFilteredListApp({
  //             searchKeyApp: searchKeyApp,
  //             filterAll: filterAll,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       } else {
  //         // If nothing is specified, you might want to handle this case
  //         await dispatch(
  //           getJobApplicationList({
  //             currentPage: currentPage,
  //             pageSize: pageSize,
  //             searchKeyApp: searchKeyApp,
  //             filterAll: filterAll,
  //             startDate: startDate,
  //             endDate: endDate,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKeyApp,
  //   filterAll,
  //   startDate,
  //   endDate,
  // ]);

  useEffect(() => {
    dispatch(HospitalBranchList([true]));
    dispatch(setOrganizationsDropdown());
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(getJobApplicationList({ currentPage: currentPage, pageSize: pageSize,searchKey: searchKey,filterAll: filterAll, startDate: startDate, endDate: endDate  }));
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, currentPage, pageSize, searchKey, filterAll, startDate, endDate]);
  // useEffect(() => {
  //   dispatch(getJobApplicationList({ currentPage: currentPage, pageSize: pageSize }));
  // }, [dispatch,currentPage,pageSize]);

  //Delete Application Function
  const handleDelete = (ID) => {
    dispatch(deleteJobApplication(ID));
  };

  //Value Getter Functions
  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }
  function getJOBID(params) {
    return `${params?.row?.job?.name || "NA"}`;
  }
  function getJOBStatus(params) {
    return `${params?.row?.job?.state || "NA"}`;
  }
  function getMaxRate(params) {
    return `${params?.row?.job?.maxRate || "NA"}`;
  }
  function getMinRate(params) {
    return `${params?.row?.job?.minRate || "NA"}`;
  }

  function getBranch(params) {
    return `${params?.row?.job?.branch?.name || "NA"}`;
  }

  function getProfessionName(params) {
    return `${params?.row?.professional?.name?.full || "NA"}`;
  }

  function getOrganizationName(params) {
    return `${params?.row?.organization?.name || "NA"}`;
  }

  function getAppliedAtDate(params) {
    var a;
    if (params?.row?.appliedAt) {
      var b = params?.row?.appliedAt?.date;
      var c = params?.row?.appliedAt?.month;
      a = `${params?.row?.appliedAt?.year}/${c < 10 ? "0" + c : c}/${
        b < 10 ? "0" + b : b
      }`;
      return `${a}`;
    } else {
      a = "NA";
      return a;
    }
  }

  function getAppliedAtTime(params) {
    var a;
    if (params?.row?.appliedAt) {
      a = `${params?.row?.appliedAt?.hour}:${params?.row?.appliedAt?.min}:${params?.row?.appliedAt?.sec}`;
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }
  function getAppliedAtMonth(params) {
    return `${params?.row?.appliedAt?.month || "NA"}`;
  }
  // function getAppliedAtDate(params) {
  //   return `${params?.row?.appliedAt?.date || "NA"}`;
  // }
  function getAppliedAtHour(params) {
    return `${params?.row?.appliedAt?.hour || "NA"}`;
  }
  function getAppliedAtMin(params) {
    return `${params?.row?.appliedAt?.min || "NA"}`;
  }
  function getAppliedAtSec(params) {
    return `${params?.row?.appliedAt?.sec || "NA"}`;
  }
  function getAppliedAtMillis(params) {
    return `${params?.row?.appliedAt?.millis || "NA"}`;
  }
  function getAppliedAtTz(params) {
    return `${params?.row?.appliedAt?.tz || "NA"}`;
  }
  function getAppliedAtTs(params) {
    return `${params?.row?.appliedAt?.ts || "NA"}`;
  }

  function getRejectedByDate(params) {
    // var a;
    // if (params?.row?.rejectedAt)
    //   a = `${params?.row?.rejectedAt?.date}/${params?.row?.rejectedAt?.month}/${params?.row?.rejectedAt?.year}`;
    // else a = "NA";

    // return `${a}`;
    var a;
    if (params?.row?.rejectedAt) {
      var b = params?.row?.rejectedAt?.date;
      var c = params?.row?.rejectedAt?.month;
      a = ` ${params?.row?.rejectedAt?.year}/${c < 10 ? "0" + c : c}/${
        b < 10 ? "0" + b : b
      }`;
      return `${a}`;
    } else {
      a = "NA";
      return a;
    }
  }
  function getRejectedByTime(params) {
    var a;
    if (params?.row?.rejectedAt)
      a = `${params?.row?.rejectedAt?.hour}:${params?.row?.rejectedAt?.min}:${params?.row?.rejectedAt?.sec}`;
    else a = "NA";
    return `${a}`;
  }

  function getInterviewByTime(params) {
    var a;
    if (params?.row?.interview)
      a = `${params?.row?.interview?.hour}:${params?.row?.interview?.min}:${params?.row?.interview?.sec}`;
    else a = "NA";
    return `${a}`;
  }

  function getInterviewByDate(params) {
    var a;
    if (params?.row?.interview)
      a = `/${params?.row?.interview?.year}/${params?.row?.interview?.month}/${params?.row?.interview?.date}`;
    else a = "NA";
    return `${a}`;
  }

  function getApprovedByTime(params) {
    var a;
    if (params?.row?.approved)
      a = `${params?.row?.approved?.hour}:${params?.row?.approved?.min}:${params?.row?.approved?.sec}`;
    else a = "NA";
    return `${a}`;
  }

  function getApprovedByDate(params) {
    var a;
    if (params?.row?.approved)
      a = `${params?.row?.approved?.year}/${params?.row?.approved?.month}/${params?.row?.approved?.date}`;
    else a = "NA";
    return `${a}`;
  }

  function getTestRequestByTime(params) {
    var a;
    if (params?.row?.test)
      a = `${params?.row?.test?.hour}:${params?.row?.test?.min}:${params?.row?.test?.sec}`;
    else a = "NA";
    return `${a}`;
  }

  function getTestRequestByDate(params) {
    var a;
    if (params?.row?.test)
      a = `${params?.row?.test?.year}/${params?.row?.test?.month}/${params?.row?.test?.date}`;
    else a = "NA";
    return `${a}`;
  }

  function getInterviewRequestByTime(params) {
    var a;
    if (params?.row?.interview)
      a = `${params?.row?.interview?.hour}:${params?.row?.interview?.min}:${params?.row?.interview?.sec}`;
    else a = "NA";
    return `${a}`;
  }

  function getInterviewRequestByDate(params) {
    var a;
    if (params?.row?.interview)
      a = `/${params?.row?.interview?.year}/${params?.row?.interview?.month}/${params?.row?.interview?.date}`;
    else a = "NA";
    return `${a}`;
  }

  function getRejectedMonth(params) {
    return `${params?.row?.rejectedAt?.month || "NA"}`;
  }
  function getRejectedDate(params) {
    return `${params?.row?.rejectedAt?.date || "NA"}`;
  }

  function getRejectedHour(params) {
    return `${params?.row?.rejectedAt?.hour || "NA"}`;
  }

  function getRejectedMin(params) {
    return `${params?.row?.rejectedAt?.min || "NA"}`;
  }

  function getRejectedSec(params) {
    return `${params?.row?.rejectedAt?.sec || "NA"}`;
  }
  function getRejectedMillis(params) {
    return `${params?.row?.rejectedAt?.millis || "NA"}`;
  }

  function getRejectedTz(params) {
    return `${params?.row?.rejectedAt?.tz || "NA"}`;
  }

  function getRejectedTs(params) {
    return `${params?.row?.rejectedAt?.ts || "NA"}`;
  }

  function getUpdatedByName(params) {
    return `${params?.row?.updatedBy?.name?.full || "NA"}`;
  }
  function getCreatedByName(params) {
    return `${params?.row?.createdBy?.name?.full || "NA"}`;
  }
  function getCreatedAT(params) {
    var a;
    var rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.createdAt);
    // var dayyy = new Date(rowName);
    // var a, b, c;
    // a = dayyy.getDate();
    // b = dayyy.getMonth() + 1;
    // c = dayyy.getFullYear();
    // var dateNow;

    // // dateNow = a + "/" + b + "/" + c;
    // // dateNow = `${a < 10 ? "0" + a : a}/${b < 10 ? "0" + b : b}/${c}`;
    // // dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
    // // return dateNow;
    // if(dateNow){
    //   dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
    //   return `${dateNow}`;
    // }else{
    //   dateNow = "NA";
    //   return `${dateNow}`;
    // }
  }

  function getUpdatedAT(params) {
    var a;
    var rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
    // var rowName = formatDate(params?.row?.updatedAt);
    // var dayyy = new Date(rowName);
    // var a, b, c;
    // a = dayyy.getDate();
    // b = dayyy.getMonth() + 1;
    // c = dayyy.getFullYear();
    // var dateNow;

    // // dateNow = a + "/" + b + "/" + c;
    // // dateNow = `${a < 10 ? "0" + a : a}/${b < 10 ? "0" + b : b}/${c}`;
    // dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
    // return dateNow;
  }
  // function consequtiveTimeStartDateTime(params) {
  //   var a;
  //   if (params?.row?.job?.consequtiveTime?.start) {
  //     var b = params?.row?.job?.consequtiveTime?.start?.date;
  //     var c = params?.row?.job?.consequtiveTime?.start?.month;
  //     a = `${params?.row?.job?.consequtiveTime?.start?.year}/${
  //       c < 10 ? "0" + c : c
  //     }/${b < 10 ? "0" + b : b}`;
  //     return `${a}`;
  //   } else {
  //     a = "NA";
  //     return `${a}`;
  //   }
  // }

  // function nonConsequtiveTimeStartDateTime(params) {
  //   var a;
  //   if (params?.row?.job?.nonConsequtiveTime?.start) {
  //     var b = params?.row?.job?.nonConsequtiveTime?.start?.date;
  //     var c = params?.row?.job?.nonConsequtiveTime?.start?.month;
  //     a = `${params?.row?.job?.nonConsequtiveTime?.start?.year}/${
  //       c < 10 ? "0" + c : c
  //     }/${b < 10 ? "0" + b : b}`;
  //     return `${a}`;
  //   } else {
  //     a = "NA";
  //     return `${a}`;
  //   }
  // }

  const formatDate = (dateString) => {
    if (dateString) {
      return moment(dateString).format("YYYY/MM/DD");
    } else {
      return "NA";
    }
  };

  const formatISTDateTime1 = (timestamp) => {
    if (!timestamp) {
      return "NA";
    }

    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    const ISTDateTime = new Date(parseInt(timestamp, 10)).toLocaleString(
      "en-IN",
      options
    );
    // console.log("3-->", ISTDateTime)
    const [day, month, year] = ISTDateTime.split("/").map((part) =>
      part.trim()
    );

    const formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
    // return ISTDateTime;
  };

  const formatISTDateTime2 = (timestamp) => {
    if (!timestamp) {
      return "NA";
    }

    const options = {
      timeZone: "Asia/Kolkata",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      // hour12: false, // Use 24-hour format
      hour12: true,
    };

    const ISTDateTime = new Date(parseInt(timestamp, 10)).toLocaleString(
      "en-IN",
      options
    );
    // console.log("3-->", ISTDateTime)
    return ISTDateTime;
  };

  function consequtiveTimeStartDateTime(params) {
    const rowName = params?.row?.job?.consequtiveTime?.start?.datetime;
    let formattedDateTime = "NA";
    if (rowName) {
      formattedDateTime = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
    }
    return formattedDateTime;
  }

  function nonConsequtiveTimeStartDateTime(params) {
    const rowName = params?.row?.job?.nonConsequtiveTime?.start?.datetime;
    let formattedDateTime = "NA";
    if (rowName) {
      formattedDateTime = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
    }
    return formattedDateTime;
  }

  //JOB APPLICATION COLUMNS
  const JobApplicationsColumns = [
    {
      field: "Id",
      headerName: "Id",

      renderCell: (cellValues) => {
        const Id = cellValues?.row?._id;

        return (
          <Tooltip title={`${Id}`} arrow>
            <Link to={`${Id}`}>{Id}</Link>
          </Tooltip>
        );
        // return <Link to={`${Id}`}>{Id}</Link>;
      },
      valueGetter: getID,
      // flex: 1,
    },
    {
      field: "Job",
      headerName: `Job ID`,
      renderCell: (cellValues) => {
        const Job = cellValues?.row?.job;
        const Job_id = cellValues?.row?.job?._id;

        return (
          <Link to={`/jobDetails/code/${Job?.name}/${Job?._id}`}>
            {Job?.name}
          </Link>
        );
      },
      valueGetter: getJOBID,
      // flex: 1.5,
    },
    {
      field: "jobStatus",
      headerName: `Job Status`,
      renderCell: (cellValues) => {
        const Job = cellValues?.row?.job;
        const Job_id = cellValues?.row?.job?.state;

        return Job_id;
      },
      valueGetter: getJOBStatus,

      // flex: 1.2,
    },
    {
      field: "consequtiveTimeStartDatetime",
      headerName: "Consequtive Start Date & Time",
      // headerName: (
      //   <div style={{ fontSize: '12px', }}>
      //     Consequtive Start Date & Time
      //   </div>
      // ),
      renderCell: (cellValues) => {
        // console.log("consq>>>>", cellValues);
        // var a;
        // if (cellValues?.row?.job?.consequtiveTime?.start) {
        //   var b = cellValues?.row?.job?.consequtiveTime?.start?.date;
        //   var c = cellValues?.row?.job?.consequtiveTime?.start?.month;
        //   a = ` ${
        //     cellValues?.row?.job?.consequtiveTime?.start?.year
        //   }/${c < 10 ? "0" + c : c}/${b < 10 ? "0" + b : b}`;
        //   return `${a}`;
        // }else

        // if (cellValues?.row?.job?.consequtiveTime?.start) {
        //   console.log("?>>>",cellValues?.row?.job?.consequtiveTime?.start?.datetime)
        //   return formatDate(
        //     cellValues?.row?.job?.consequtiveTime?.start?.datetime
        //   );
        //   // return `${a}`
        // }

        //  else {
        //   a = "NA";
        //   return `${a}`;
        // }

        // var a
        const rowName = cellValues?.row?.job?.consequtiveTime?.start?.datetime;
        // if (rowName) {
        //   a = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return `${a}`;
        // }

        let formattedDateTime = "NA";
        if (rowName) {
          formattedDateTime = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
        }

        return (
          <Tooltip title={formattedDateTime} arrow>
            <div>{formattedDateTime}</div>
          </Tooltip>
        );
      },
      type: "date",
      valueGetter: consequtiveTimeStartDateTime,
      // flex: 1.5,
    },
    {
      field: "nonConsequtiveTimeEndDatetime",
      // headerName: "Non Consequtive Start Date & Time",
      headerName: (
        <div style={{ fontSize: "16px" }}>
          Non Consequtive Start Date & Time
        </div>
      ),
      renderCell: (cellValues) => {
        // console.log("nonconseq>>>>", cellValues);
        var a;
        // if (cellValues?.row?.job?.nonConsequtiveTime?.start) {
        //   var b = cellValues?.row?.job?.nonConsequtiveTime?.start?.date;
        //   var c = cellValues?.row?.job?.nonConsequtiveTime?.start?.month;
        //   a = `${
        //     cellValues?.row?.job?.nonConsequtiveTime?.start?.year
        //   }/${c < 10 ? "0" + c : c}/${b < 10 ? "0" + b : b} `;
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return `${a}`;
        // }

        // if (cellValues?.row?.job?.nonConsequtiveTime?.start) {
        //   console.log("?>>>", cellValues?.row?.job?.nonConsequtiveTime?.start);
        //   return formatDate(
        //     cellValues?.row?.job?.nonConsequtiveTime?.start?.datetime
        //   );
        //   // return `${a}`
        // }

        var a;
        const rowName =
          cellValues?.row?.job?.nonConsequtiveTime?.start?.datetime;
        // if (rowName) {
        //   a = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return `${a}`;
        // }

        let formattedDateTime = "NA";
        if (rowName) {
          formattedDateTime = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
        }

        return (
          <Tooltip title={formattedDateTime} arrow>
            <div>{formattedDateTime}</div>
          </Tooltip>
        );
      },
      type: "date",
      valueGetter: nonConsequtiveTimeStartDateTime,
      // flex: 1.5,
    },
    {
      field: "Professional",
      headerName: `Professional`,
      // headerName: (
      //   <div style={{ fontSize: '12px' }}>
      //     Professional
      //   </div>
      // ),

      renderCell: (cellValues) => {
        const Professional = cellValues?.row?.professional;
        const formattedName = Professional?.name?.full || "NA";
        return (
          // <Link to={`/users/name/${Professional?._id}`}>
          //   {Professional?.name?.full}
          // </Link>
          <Tooltip title={`${formattedName}`} arrow>
            <Link to={`/users/name/${Professional?._id}`}>{formattedName}</Link>
          </Tooltip>
        );
        // );
      },
      valueGetter: getProfessionName,
      // flex: 1.5,
    },
    {
      field: "mobileNumber",
      headerName: `Mobile Number`,
      renderCell: (cellValues) => {
        // console.log("cellValues", cellValues?.row?.professional?.phoneNumbers)
        var a;
        var rowName = cellValues?.row?.professional?.phoneNumbers;
        // console.log("rowName", rowName.length)
        let formattedName = "NA";
        if (rowName) {
          formattedName =
            cellValues?.row?.professional?.phoneNumbers[0]?.number;
        }

        return (
          <Tooltip title={formattedName} arrow>
            <div>{formattedName}</div>
          </Tooltip>
        );
        // if (rowName) {
        //   a = cellValues?.row?.professional?.phoneNumbers[0]?.number
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return `${a}`;
        // }
      },
      // valueGetter: getBranch,

      // flex: 1.5,
    },
    {
      field: "state",
      headerName: `State`,
      // flex: 1.5,
    },
    {
      field: "hospitalBranch",
      headerName: `Hospital Branch`,
      renderCell: (cellValues) => {
        // return cellValues?.row?.job?.branch?.name;
        const rowName = cellValues?.row?.job?.branch?.name;
        let formattedName = "NA";
        if (rowName) {
          formattedName = rowName;
        }

        return (
          <Tooltip title={formattedName} arrow>
            <div>{formattedName}</div>
          </Tooltip>
        );
      },
      valueGetter: getBranch,

      // flex: 1.5,
    },
    {
      field: "Organization",
      headerName: `Organization`,
      renderCell: (cellValues) => {
        const Organization = cellValues?.row?.organization;
        return (
          <Link to={`/hospital/company/${Organization?._id}`}>
            {Organization?.name}
          </Link>
        );
      },
      flex: 1,
      valueGetter: getOrganizationName,
    },
    {
      field: "getMaxRate",
      headerName: `MaxRate`,
      renderCell: (cellValues) => {
        const roleName = cellValues?.row?.MaxRate;
        return roleName;
      },
      // flex: 1.5,
      valueGetter: getMaxRate,
    },
    {
      field: "getMinRate",
      headerName: `MinRate`,
      renderCell: (cellValues) => {
        // console.log("cellValues>>", cellValues)
        const roleName = cellValues?.row?.MinRate;
        return roleName;
      },
      // flex: 1.5,
      valueGetter: getMinRate,
    },
    {
      field: "appliedAtDate",
      headerName: `Applied At Date`,
      renderCell: (cellValues) => {
        var Organization;
        // if (cellValues?.row?.appliedAt)
        //   Organization =
        //     cellValues?.row?.appliedAt?.date +
        //     "/" +
        //     cellValues?.row?.appliedAt?.month +
        //     "/" +
        //     cellValues?.row?.appliedAt?.year;
        // else Organization = "";
        // return Organization;

        const ISTDate = formatISTDateTime1(cellValues?.row?.appliedAt?.ts);
        // console.log(">>>IST",ISTDate);
        return ISTDate;

        // var a;
        // if (cellValues?.row?.appliedAt) {
        //   var b = cellValues?.row?.appliedAt?.date;
        //   var c = cellValues?.row?.appliedAt?.month;
        //   a = ` ${cellValues?.row?.appliedAt?.year}/${c < 10 ? "0" + c : c}/${
        //     b < 10 ? "0" + b : b
        //   }`;
        //   return `${a}`;
        // } else {
        //   a = "NA";
        //   return a;
        // }
      },
      type: "date",
      valueGetter: getAppliedAtDate,
    },
    {
      field: "appliedAtTime",
      headerName: `Applied At Time`,
      renderCell: (cellValues) => {
        const ISTDate = formatISTDateTime2(cellValues?.row?.appliedAt?.ts);
        // console.log(">>>IST",ISTDate);
        return ISTDate;
        // var Organization;
        // if (cellValues?.row?.appliedAt)
        //   Organization =
        //     cellValues?.row?.appliedAt?.hour +
        //     ":" +
        //     cellValues?.row?.appliedAt?.min +
        //     ":" +
        //     cellValues?.row?.appliedAt?.sec;
        // else Organization = "NA";
        // return Organization;
      },
      valueGetter: getAppliedAtTime,
    },

    {
      field: "rejectedAtDate",
      headerName: `Rejected At Date`,
      renderCell: (cellValues) => {
        // var Organization;
        // if (cellValues?.row?.rejectedAt)
        //   Organization =
        //     cellValues?.row?.rejectedAt?.date +
        //     "/" +
        //     cellValues?.row?.rejectedAt?.month +
        //     "/" +
        //     cellValues?.row?.rejectedAt?.year;
        // else Organization = "";
        // return Organization;
        var a;
        if (cellValues?.row?.rejectedAt) {
          var b = cellValues?.row?.rejectedAt?.date;
          var c = cellValues?.row?.rejectedAt?.month;
          a = `${cellValues?.row?.rejectedAt?.year}/${c < 10 ? "0" + c : c}/${
            b < 10 ? "0" + b : b
          }`;
          return `${a}`;
        } else {
          a = "NA";
          return a;
        }
      },
      type: "date",
      valueGetter: getRejectedByDate,
    },

    {
      field: "rejectedAtTime",
      headerName: `Rejected At Time`,
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.rejectedAt)
          Organization =
            cellValues?.row?.rejectedAt?.hour +
            ":" +
            cellValues?.row?.rejectedAt?.min +
            ":" +
            cellValues?.row?.rejectedAt?.sec;
        else Organization = "";
        return Organization;
      },
      valueGetter: getRejectedByTime,
    },

    {
      field: "interviewByDate",
      headerName: `Interview Date`,
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.interview)
          Organization =
            cellValues?.row?.interview?.date +
            "/" +
            cellValues?.row?.interview?.month +
            "/" +
            cellValues?.row?.interview?.year;
        else Organization = "";
        return Organization;
      },
      type: "date",
      valueGetter: getInterviewByDate,
    },

    {
      field: "interviewByTime",
      headerName: `Interview Time`,
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.interview)
          Organization =
            cellValues?.row?.interview?.hour +
            ":" +
            cellValues?.row?.interview?.min +
            ":" +
            cellValues?.row?.interview?.sec;
        else Organization = "";
        return Organization;
      },
      valueGetter: getInterviewByTime,
    },

    {
      field: "ApprovedByDate",
      // headerName: "Approved Date",
      // headerName: "Accepted Date",
      headerName: (
        // fontWeight: 'bold', color: '#333'
        <div style={{ fontSize: "16px" }}>Accepted Date</div>
      ),
      renderCell: (cellValues) => {
        // console.log("cellValues>>", cellValues)

        // var Organization;
        // if (cellValues?.row?.approvedAt)
        //   Organization =
        //     cellValues?.row?.approvedAt?.date +
        //     "/" +
        //     cellValues?.row?.approvedAt?.month +
        //     "/" +
        //     cellValues?.row?.approvedAt?.year;
        // else Organization = "NA";
        // console.log(">>>",cellValues?.row?.approvedAt?.ts);
        const ISTDate = formatISTDateTime1(cellValues?.row?.approvedAt?.ts);
        // console.log(">>>IST",ISTDate);
        return ISTDate;
        // return Organization;
      },
      // flex: 1.5,
      type: "date",
      valueGetter: getApprovedByDate,
    },

    {
      field: "ApprovedByTime",
      // headerName: `Approved Time`,
      // headerName: `Accepted Time`,
      headerName: <div style={{ fontSize: "16px" }}>Accepted Time</div>,
      renderCell: (cellValues) => {
        // var Organization;
        // if (cellValues?.row?.approvedAt)
        //   Organization =
        //     cellValues?.row?.approvedAt?.hour +
        //     ":" +
        //     cellValues?.row?.approvedAt?.min +
        //     ":" +
        //     cellValues?.row?.approvedAt?.sec;
        // else Organization = "NA";
        // return Organization;
        const ISTDate = formatISTDateTime2(cellValues?.row?.approvedAt?.ts);
        // console.log(">>>IST",ISTDate);
        return ISTDate;
      },
      // flex: 1.5,
      valueGetter: getApprovedByTime,
    },

    {
      field: "InterviewRequestByDate",
      headerName: "Interview Request Date",
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.interviewRequest)
          Organization =
            cellValues?.row?.interviewRequest?.date +
            "/" +
            cellValues?.row?.interviewRequest?.month +
            "/" +
            cellValues?.row?.interviewRequest?.year;
        else Organization = "";
        return Organization;
      },
      type: "date",
      valueGetter: getInterviewRequestByDate,
    },

    {
      field: "InterviewRequestByTime",
      headerName: `Interview Request Time`,
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.interviewRequest)
          Organization =
            cellValues?.row?.interviewRequest?.hour +
            ":" +
            cellValues?.row?.interviewRequest?.min +
            ":" +
            cellValues?.row?.interviewRequest?.sec;
        else Organization = "";
        return Organization;
      },
      valueGetter: getInterviewRequestByTime,
    },

    {
      field: "TestRequestByDate",
      headerName: "Test Request Date",
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.testRequest)
          Organization =
            cellValues?.row?.testRequest?.date +
            "/" +
            cellValues?.row?.testRequest?.month +
            "/" +
            cellValues?.row?.testRequest?.year;
        else Organization = "";
        return Organization;
      },
      type: "date",
      valueGetter: getTestRequestByDate,
    },

    {
      field: "TestRequestByTime",
      headerName: `Test Request Time`,
      renderCell: (cellValues) => {
        var Organization;
        if (cellValues?.row?.testRequest)
          Organization =
            cellValues?.row?.testRequest?.hour +
            ":" +
            cellValues?.row?.testRequest?.min +
            ":" +
            cellValues?.row?.testRequest?.sec;
        else Organization = "";
        return Organization;
      },
      valueGetter: getTestRequestByTime,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.createdAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.createdAt);
        // var dayyy = new Date(rowName);
        // var a, b, c;
        // a = dayyy.getDate();
        // b = dayyy.getMonth() + 1;
        // c = dayyy.getFullYear();
        // var dateNow;

        // // dateNow = a + "/" + b + "/" + c;
        // if(dateNow){
        //   dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
        //   return `${dateNow}`;
        // }else{
        //   dateNow = "NA";
        //   return `${dateNow}`;
        // }
      },
      type: "date",
      // flex: 1.5,
      valueGetter: getCreatedAT,
    },
    {
      field: "updatedAt",
      headerName: `Updated At`,
      renderCell: (cellValues) => {
        var a;
        var rowName = cellValues?.row?.updatedAt;
        if (rowName) {
          a = moment(rowName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // var rowName = formatDate(cellValues?.row?.updatedAt);
        // var dayy = new Date(rowName);

        // let a = dayy.getDate();
        // let b = dayy.getMonth() + 1;
        // let c = dayy.getFullYear();
        // // return a + "/" + b + "/" + c;
        // const dateNow = `${c}/${b < 10 ? "0" + b : b}/${a < 10 ? "0" + a : a}`;
        // return dateNow;
      },
      type: "date",

      valueGetter: getUpdatedAT,
    },
    {
      field: "createdBy",
      headerName: `Created By`,
      renderCell: (cellValues) => {
        return cellValues?.row?.createdBy?.name?.full;
      },
      valueGetter: getCreatedByName,
    },
    {
      field: "updatedBy",
      headerName: `Updated By`,

      renderCell: (cellValues) => {
        return cellValues?.row?.updatedBy?.name?.full;
      },
      valueGetter: getUpdatedByName,
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: ({ row }) => (
        <>
          {" "}
          <DeleteDialog
            message={`Are you sure you want to delete ${row?._id}?`}
            handleConfirmation={() => {
              handleDelete(row._id);
            }}
            render={(handlePopupOpen) => (
              <IconButton aria-label="delete" size="small">
                {" "}
                <Delete fontSize="small" onClick={handlePopupOpen} />{" "}
              </IconButton>
            )}
          />{" "}
        </>
      ),
      sortable: false,
    },
  ];

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);
  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const [showDeltedRecords, setShowDeletedRecords] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);

  const handleDeleteApi = async (checked) => {
    // if (checked) {
    //   setShowDeletedRecords(true);
    //   //API CALL for Delted Records
    //   const response = await getJobApplicationService(checked);

    //   setDeletedRecords(response?.data?.docs);
    // } else {
    //   setShowDeletedRecords(false);
    //   //API CALL for Not Delted Records
    //   const response = await getJobApplicationService(checked);
    // }
  };

  // const { job_Application_Data } = useSelector((state) => state.job);
  // const softwares = job_Application_Data?.job_Application_Data_info;
  const job_Application_Data_Status =
    job_Application_Data?.job_Application_Data_status;

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  function formattDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
    setLoading(true); // Start loading
  
    try {
      const hasAnyFilter =
        searchKeyApp?.trim() !== "" ||
        Object.values(filterAll).some((value) => value?.trim() !== "") ||
        startDate.trim() !== "" ||
        endDate.trim() !== "";
      let csvData = ""
        if (hasAnyFilter) {
          const data = await dispatch(
            getFilteredListApp({
              searchKeyApp,
              filterAll,
              startDate,
              endDate,
            })
          );
          csvData = data.payload.data
        } else {
          const data = await dispatch(
            getFilteredListApp({
              searchKeyApp,
              filterAll,
              startDate,
              endDate,
            })
          );
          csvData = data.payload.data
        }
        // Generate CSV and download
        const csvContent = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(csvContent);
        link.download = `Applications-${formattDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching or downloading data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  


  
  


  // const handleDownloadData = () => {
  //   // if(filteredApplicationData && filteredApplicationData.length > 0){
  //   const response = filteredApplicationData;
  //   // console.log("1405-->",response)
  //   const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //   const link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  //   // link.download = "Jobizo Super Admin.csv";
  //   link.download = `Applications-${formattDate()}.csv`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
   
  // };

  const handleFilterChange = (key, value) => {
    dispatch(filterData({ ...filterAll, [key]: value }));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate)); // Dispatch action to update startDate in Redux store
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate)); // Dispatch action to update endDate in Redux store

    // Trigger API call when both startDate and endDate are available
    // if (startDate && endDate) {
    //   // Example: Call your API here
    //   console.log('API call with start date:', startDate, 'end date:', endDate);
    // }
  };

  const rows =
  searchKeyApp || filterAll || startDate || endDate
      ? [...listFilterData]
      : [...softwares];

  // console.log("listFilterData", listFilterData); 

  // console.log("1>>", searchKey);
  // console.log("2>>", hasNonEmptyString(filterAll));
  // console.log("3>>", startDate);
  // console.log("4>>", endDate);

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    // setSelectedOption(selectedRoleValue);
    let selectedBranchObjectValue;
    branchOptions.forEach((branch) => {
          if (branch.value === selectedBranch) {
            selectedBranchObjectValue = branch.value;
          }
        });
    setBranchValue(selectedBranch);
    dispatch(filterData({ ...filterAll, "HospitalBranch": selectedBranchObjectValue }));
  };

  const handleOrganizationsFilterChange = (e) => {
    const selectedOrganizations = e.target.value;
    let selectedOrganizationsObjectValue;

    organizationOptions.forEach((orga) => {
      if (orga.value === selectedOrganizations) {
        selectedOrganizationsObjectValue = orga.value;
      }
    });

    setOrganizationsValue(selectedOrganizations);

    dispatch(filterData({ ...filterAll, "OrganizationName": selectedOrganizationsObjectValue }));
  };

  const handleApplicationStateFilterChange = (e) => {
    const selectedApplicationState = e.target.value;
    let selectedApplicationStateValue;

    selectApplicationStateOptions.forEach((appState) => {
      if (appState.value === selectedApplicationState) {
        selectedApplicationStateValue = appState.value;
      }
    });

    setApplicationStateValue(selectedApplicationState);

    dispatch(filterData({ ...filterAll, "state": selectedApplicationStateValue }));
  };
  
  // selectJobStateOptions
  //jobStateValue, setJobStateValue

  const handleJobStateFilterChange = (e) => {
    const selectedJobState = e.target.value;
    let selectedJobStateValue;

    selectJobStateOptions.forEach((jobState) => {
      if (jobState.value === selectedJobState) {
        selectedJobStateValue = jobState.value;
      }
    });

    setJobStateValue(selectedJobState);

    dispatch(filterData({ ...filterAll, "JobStatus": selectedJobStateValue }));
  };

  function hasNonEmptyString(obj) {
    return Object.values(obj).some(
      (value) => typeof value === "string" && value.trim() !== ""
    );
  }

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    dispatch(searchKeywordApp(sanitizedSearchKey));
    
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


  return (
    <DashboardLayout>
      {" "}
      <DashboardNavbar />
      <FilterBar
        title="Create Application"
        title1="Application"
        isOpen={isOpen}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        showisDeleted={false}
        handleDeleteApi={handleDeleteApi}
        dialogContent={
          <CreateApplicationDialog handleDialogClose={handleDialogClose} />
        }
      />{" "}
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
         {/* <MenuItem value="JobCode">Job ID</MenuItem> */}
        <MenuItem value="JobStatus">Job Status</MenuItem>
        {/* <MenuItem value="ProfName">Professional Name</MenuItem>  */}
        <MenuItem value="HospitalBranch">Hospital Branch</MenuItem>
        <MenuItem value="OrganizationName">Organization Name</MenuItem> 
        <MenuItem value="state">State</MenuItem> 
      </Select>
      {selectedOption && (
        <>
          {
          selectedOption === "JobStatus" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select JobStatus
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select JobStatus"
                // value={filterAll.Role || ""}
                value={jobStateValue || ""}
                onChange={handleJobStateFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {selectJobStateOptions.map((jobState) => (
                  <MenuItem key={jobState.value} value={jobState.value}>
                    {jobState.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : 
          selectedOption === "HospitalBranch" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select HospitalBranch
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select HospitalBranch"
                // value={filterAll.Role || ""}
                // value={filterAll.HospitalBranch || ""}
                value={branchValue || ""}
                onChange={handleBranchFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {branchOptions.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : 
          selectedOption === "OrganizationName" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select OrganizationName
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select OrganizationName"
                // value={filterAll.Role || ""}
                value={organizationsValue || ""}
                onChange={handleOrganizationsFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {organizationOptions.map((orga) => (
                  <MenuItem key={orga.value} value={orga.value}>
                    {orga.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : 
          selectedOption === "state" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select State
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select State"
                // value={filterAll.Role || ""}
                value={applicationStateValue || ""}
                onChange={handleApplicationStateFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {selectApplicationStateOptions.map((appState) => (
                  <MenuItem key={appState.value} value={appState.value}>
                    {appState.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : 
          null}
        </>
      )}
      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          // onChange={(e) => updateStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => handleEndDateChange(e.target.value)}
          // onChange={(e) => updateEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
      </>
      {/* </div> */}
      <Button
        onClick={handleDownloadData}
        variant="contained"
        color="primary"
        disabled={loading}
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
          // transition: 'background-color 0.3s',
          // backgroundColor: 'primary',
        }}
        // onMouseOver={(e) => e.target.style.backgroundColor = '#1976D2'} // Change to your primary color
        // onMouseOut={(e) => e.target.style.backgroundColor = 'primary'}
      >
       {loading ? "Loading..." : "Export"}
      </Button>
      <>
        <TextField
          label={`Search by JobId,ProfName`}
          value={inputValue}
          onChange={(e) =>
            setInputValue(e.target.value)
          }
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by JobId,ProfName,HospitalName`}
        value={searchKey}
        onChange={(e) => dispatch(searchKeyword(e.target.value))}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      <MDBox sx={{ marginTop: "2rem" }}>
        {" "}
        {!searchKeyApp &&
        !hasNonEmptyString(filterAll) &&
        !startDate &&
        !endDate ? (
          <DataGrid
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                // showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: true,
                  updatedAt: false,
                  // appliedAt: false,
                  createdBy: false,
                  updatedBy: false,
                  delete: false,
                  getMinRate: false,
                  getMaxRate: false,
                  TestRequestByDate: false,
                  TestRequestByTime: false,
                  InterviewRequestByDate: false,
                  InterviewRequestByTime: false,
                  ApprovedByTime: true,
                  ApprovedByDate: true,
                  interviewByDate: false,
                  interviewByTime: false,
                  rejectedAtTime: false,
                  rejectedAtDate: false,
                  appliedAtTime: true,
                  appliedAtDate: true,

                  Organization: false,
                },
              },
            }}
            columns={JobApplicationsColumns}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            // loading={softwares.length > 0 ? false : true} loading1
            loading={loading1 && <CircularProgress />}
            // loading={
            //   softwares?.length === 0
            //     ? loading && <CircularProgress />
            //     : loading1 && <CircularProgress />
            // }
            // loading = {loading ? <CircularProgress /> : softwares.length > 0 ? false : true}
            // rows={[...softwares]}
            rows={softwares && softwares.length > 0 ? [...softwares] : []}
            // rows={softwares?.length > 0 ? [...softwares] : [...listFilterData]}
            // rows={showDeltedRecords ? deletedRecords : [...softwares]}
            // pagination
            // pageSize={100}
            // rowsPerPageOptions={[100]}
            pageSize={pageSize}
            rowCount={totalCount}
            paginationMode="server"
            pagination
            page={currentPage}
            onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
            onPageChange={(newPage) => dispatch(pageChanged(newPage))}
            rowsPerPageOptions={[10, 25, 50, 100]}
            density="compact"
            //isRowSelectable
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
            disableColumnFilter
          />
        ) : (
          <DataGrid
            getRowId={(row) => row._id} // with filter
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                // showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: true,
                  updatedAt: false,
                  // appliedAt: false,
                  createdBy: false,
                  updatedBy: false,
                  delete: false,
                  getMinRate: false,
                  getMaxRate: false,
                  TestRequestByDate: false,
                  TestRequestByTime: false,
                  InterviewRequestByDate: false,
                  InterviewRequestByTime: false,
                  ApprovedByTime: true,
                  ApprovedByDate: true,
                  interviewByDate: false,
                  interviewByTime: false,
                  rejectedAtTime: false,
                  rejectedAtDate: false,
                  appliedAtTime: true,
                  appliedAtDate: true,

                  Organization: false,
                },
              },
            }}
            columns={JobApplicationsColumns}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            // loading={softwares.length > 0 ? false : true} loading1
            loading={loading && <CircularProgress />}
            // loading={
            //   softwares?.length === 0
            //     ? loading && <CircularProgress />
            //     : loading1 && <CircularProgress />
            // }
            // loading = {loading ? <CircularProgress /> : softwares.length > 0 ? false : true}
            // rows={[...softwares]}
            rows={
              listFilterData && listFilterData.length > 0
                ? [...listFilterData]
                : []
            }
            // rows={softwares?.length > 0 ? [...softwares] : [...listFilterData]}
            // rows={showDeltedRecords ? deletedRecords : [...softwares]}
            // pagination
            // pageSize={100}
            // rowsPerPageOptions={[100]}
            pageSize={pageSize}
            rowCount={totalCount1}
            paginationMode="server"
            pagination
            page={currentPage}
            onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
            onPageChange={(newPage) => dispatch(pageChanged(newPage))}
            rowsPerPageOptions={[10, 25, 50, 100]}
            density="compact"
            //isRowSelectable
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
            disableColumnFilter
          />
        )}
        {/* <DataGrid
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                createdAt: true,
                updatedAt: false,
                // appliedAt: false,
                createdBy: false,
                updatedBy: false,
                delete: false,
                getMinRate: false,
                getMaxRate: false,
                TestRequestByDate: false,
                TestRequestByTime: false,
                InterviewRequestByDate: false,
                InterviewRequestByTime: false,
                ApprovedByTime: true,
                ApprovedByDate: true,
                interviewByDate: false,
                interviewByTime: false,
                rejectedAtTime: false,
                rejectedAtDate: false,
                appliedAtTime: true,
                appliedAtDate: true,

                Organization: false,
              },
            },
          }}
          columns={JobApplicationsColumns}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          // loading={softwares.length > 0 ? false : true} loading1
          // loading={loading && <CircularProgress />}
          loading={
            softwares?.length === 0
              ? loading && <CircularProgress />
              : loading1 && <CircularProgress />
          }
          // loading = {loading ? <CircularProgress /> : softwares.length > 0 ? false : true}
          // rows={[...softwares]}
          // rows={softwares && softwares.length > 0 ? [...softwares] : []}
          rows={softwares?.length > 0 ? [...softwares] : [...listFilterData]}
          // rows={showDeltedRecords ? deletedRecords : [...softwares]}
          // pagination
          // pageSize={100}
          // rowsPerPageOptions={[100]}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          density="compact"
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          disableColumnFilter
        /> */}
      </MDBox>
    </DashboardLayout>
  );
};
export default Applications;
