import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Icon,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchInvoiceDetailsData,
  getFilteredInvoiceDetails,
  uploadPaidInvoice,
  updateInvoiceById,
  getInvoiceFinanceByDetails,
  getInvoiceOperationByDetails,
} from "./Redux/actionCreator";
import {
  pageChanged,
  pageLimit,
  searchKeyword,
  filterBranch,
  updateStartDate,
  updateEndDate,
  updateStartDueDate,
  updateEndDueDate,
} from "./Redux/reducer";
import { HospitalBranchList } from "layouts/hospital/Redux/actionCreator";
import Menu from "@material-ui/core/Menu";
import jsPDF from "jspdf";
import JSZip from "jszip";
import UploadDialog from "./UploadPaidDialog";
import axiosInstance from "../../axios";
import SampleFinanceFile from "../../assets/Sample Files/Sample Finance Data.csv";
import SampleOperationFile from "../../assets/Sample Files/Sample Operation Data.csv";

// const RecordsPerPage = 50;
const UserInvoiceDetails = (handleUploadDialogClose) => {
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [filteredInvoiceData, setFilteredInvoiceData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");
  // const [currentPage, setCurrentPage] = useState(0);
  const [currentFilterPage, setCurrentFilterPage] = useState(0);
  const [totalFilterRecords, setFilterTotalRecords] = useState(0);
  const [filterRecordsPerPage, setFilterRecordsPerPage] = useState(10);
  const [showPagination, setShowPagination] = useState(false);
  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  // const [selectedOptionPaid, setSelectedOptionPaid] = useState('Unpaid');
  const [selectedOptionPaid, setSelectedOptionPaid] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");
  const [inputDueStartDate, setInputDueStartDate] = useState("");
  const [inputDueEndDate, setInputDueEndDate] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [invoiceFilteredAllData, setInvoiceFilteredAllData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isDownloadingFor, setIsDownloadingFor] = useState(null);

  // const [invoiceData, setInvoiceData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { Invoice_Details_Data } = useSelector((state) => state.invoice);
  var invoiceData = Invoice_Details_Data?.invoiceDetailsInfo;
  // console.log("invoiceData", invoiceData)

  const { Invoice_Filtered_Data } = useSelector((state) => state.invoice);
  const invoiceFilteredData = Invoice_Filtered_Data?.invoiceFilteredInfo;
  console.log("filtered invoice>>>", invoiceFilteredData);

  const { Invoice_FinanceBy_Data } = useSelector((state) => state.invoice);
  const invoiceFinanceByData = Invoice_FinanceBy_Data?.invoiceFinanceByInfo;
  // console.log("financeByData>>>", invoiceFinanceByData)

  const { Invoice_OperationBy_Data } = useSelector((state) => state.invoice);
  const invoiceOperationByData =
    Invoice_OperationBy_Data?.invoiceOperationByInfo;
  // console.log("operationsByData>>>", invoiceOperationByData);

  const totalCount = Invoice_Details_Data?.total;
  const currentPage = useSelector((state) => state.invoice.currentPage);
  const pageSize = useSelector((state) => state.invoice.pageSize);
  const searchKey = useSelector((state) => state.invoice.searchKey);
  const filterBranchData = useSelector((state) => state.invoice.branch);
  const startDate = useSelector((state) => state.invoice.startDate);
  const endDate = useSelector((state) => state.invoice.endDate);
  const startDueDate = useSelector((state) => state.invoice.startDueDate);
  const endDueDate = useSelector((state) => state.invoice.endDueDate);

  var invoiceLoading = Invoice_Details_Data?.invoiceDetailsLoading;

  const [branchValue, setBranchValue] = useState("");

  const branchOptions = [];

  const { hospital_branch_Data } = useSelector((state) => state.hospital);

  const branchData = hospital_branch_Data?.branchInfo;

  branchData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    branchOptions.push(obj);
  });

  useEffect(() => {
    setLoading(invoiceLoading);
  }, [invoiceLoading]);

  function getShiftDate(param) {
    var shiftdate = param?.row?.shiftDate.split("T")[0];

    var dateNOW = moment(shiftdate).format("YYYY/MM/DD");
    return dateNOW;
  }

  function getDepartment(param) {
    return `${param?.row?.department?.departmentName || "NA"}`;
  }

  function getProfession(param) {
    return `${param?.row?.user?.name || "NA"}`;
  }

  function getPan(param) {
    return `${param?.row?.user?.pan || "NA"}`;
  }

  function getAccountNumber(param) {
    return `${param?.row?.bank?.accountNumber || "NA"}`;
  }

  function getBankName(param) {
    return `${param?.row?.bank?.bankName || "NA"}`;
  }

  function getIFSC(param) {
    return `${param?.row?.bank?.ifsc || "NA"}`;
  }

  function getCreatedAt(params) {
    var a;
    const roleName = params?.row?.createdAt;
    // const a = moment(roleName).format("YYYY/MM/DD");
    // return a;
    if (roleName) {
      a = moment(roleName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  function getBatchTransferId(param) {
    // return `${param?.row?.bank?.ifsc || "NA"}`;
    var rowName = param?.row?.cashFreeData;
      if (rowName && rowName.length > 0) {
        const cashfreeData = rowName[0].batchTransferId
        return cashfreeData
      } else {
        return "NA";
      }
  }

  const handleView = (id) => {
    navigate(`/userInvoice/view/${id}`);
    // const url = `/userInvoice/view/${id}`;
    // window.open(url, "_blank");
  };

  // const handleOptionChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setSelectedOptionPaid(selectedValue);
  //   // Perform any additional actions based on the selected option
  //   // For example, you can call a function like handleView with the selected option
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // setSelectedFileName(file.name);
    dispatch(uploadPaidInvoice(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        // const logoUrl = response.payload.data;
        // console.log("url", logoUrl);
        // Assuming data.client.logo is an array
        // setValue("client.logo", logoUrl);
        // setFileLogo(logoUrl);

        if (response.payload.data.status === true) {
          const updatedPaidInvoices = invoiceData?.map((item) => {
            // Assuming each item has an '_id' property
            return { ...item, isPaid: true };
          });
          setSelectedOptionPaid(true);

          dispatch(fetchInvoiceDetailsData(updatedPaidInvoices));
          dispatch(getFilteredInvoiceDetails(updatedPaidInvoices));
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };
  const columns = [
    {
      field: "invoiceNumber",
      headerName: `Invoice Number`,
      // flex: 4,
    },
    {
      field: "jobId",
      headerName: `Job ID`,
      // flex: 2.5,
    },
    {
      field: "professionalName",
      headerName: `Professional Name`,
      valueGetter: getProfession,
      // flex: 3.5,
    },
    {
      field: "branchName",
      headerName: `Hospital Branch`,
      // flex: 3,
    },
    {
      field: "department",
      headerName: `Department`,
      valueGetter: getDepartment,
      // flex: 2.5,
    },
    {
      field: "shiftDate",
      headerName: `Shift Date`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var shiftdate = cellValues?.row?.shiftDate.split("T")[0];

        var dateNOW = moment(shiftdate).format("YYYY/MM/DD");
        return dateNOW;
      },
      // flex: 2.5,
      type: "date",
      valueGetter: getShiftDate,
    },
    {
      field: "finalAmount",
      headerName: `Amount`,
      renderCell: (cellValues) => {
        const amountData = cellValues?.row?.amount;
        return amountData;
      },
      // flex: 2.5,
    },
    {
      field: "pan",
      headerName: `Pan Number`,
      valueGetter: getPan,
      // flex: 2.5,
    },
    {
      field: "accountNumber",
      headerName: `Account Number`,
      valueGetter: getAccountNumber,
      // flex: 2.5,
    },
    {
      field: "bankName",
      headerName: `Bank Name`,
      valueGetter: getBankName,
      // flex: 2.5,
    },
    {
      field: "ifsc",
      headerName: `IFSC Number`,
      valueGetter: getIFSC,
      // flex: 2.5,
    },
    {
      field: "createdAt",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.createdAt;
        if (roleName) {
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const a = moment(roleName).format("YYYY/MM/DD");
        // aa
        // return a;
      },
      // flex: 2.5,
      type: "date",
      valueGetter: getCreatedAt,
    },
    {
      field: "invoices",
      headerName: `View`,

      renderCell: ({ row }) => {
        return (
          <IconButton
            aria-label="view"
            size="small"
            onClick={() => handleView(row._id)}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        );
      },
      flex: 1,
    },
    {
      field: "paymentDueDate",
      headerName: `Payment Due Date`,
      renderCell: (cellValues) => {
        var a;
        const roleName = cellValues?.row?.paymentDueDate;
        // console.log("1--->", roleName)
        if (roleName) {
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const a = moment(roleName).format("YYYY/MM/DD");
        // aa
        // return roleName;
      },
      // flex: 2.5,
      type: "date",
      // valueGetter: getCreatedAt,
    },

    {
      field: "utrno",
      headerName: `UTR NO`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var rowName = cellValues?.row?.cashFreeData;
        if (rowName && rowName.length > 0) {
          const cashfreeData = rowName[0].utrNo;
          return cashfreeData;
        } else {
          return "NA";
        }
      },
      // flex: 2.5,
    },
    

    {
      field: "operationApprove",
      headerName: `Operation Approve`,

      renderCell: (cellValues) => {
        // console.log(cellValues);
        const isPaid = cellValues?.row?.operationApprove;
        // console.log("isPaid", isPaid);
        // console.log("selectedOptionPaid", selectedOptionPaid);

        // Set the value for the Select based on the isPaid value
        //  selectedOptionPaid = isPaid ? "Paid" : "Unpaid";
        // const selectedOptionPaidValue = selectedOptionPaid || isPaid ? "Paid" : "Unpaid";

        let selectedOptionPaidValue;

        if (selectedOptionPaid === false && isPaid === true) {
          selectedOptionPaidValue = "True";
        } else if (selectedOptionPaid === true && isPaid === true) {
          selectedOptionPaidValue = "True";
        } else if (isPaid === true) {
          selectedOptionPaidValue = "True";
        } else {
          selectedOptionPaidValue = "False";
        }

        const handleOptionChange = async (event) => {
          const selectedValue = event.target.value === "True";

          // Update the local state
          setSelectedOptionPaid(selectedValue);

          // const updatedIsPaidValue = selectedValue === 'Paid';
          const payload = {
            operationApprove: selectedValue,
          };

          try {
            await dispatch(
              updateInvoiceById({ payload, id: cellValues.row._id })
            );

            // Update the local item
            const updatedData = invoiceData?.map((item) => {
              // console.log("itemId", item._id)
              // console.log("cellId", cellValues.row._id)
              if (item?._id === cellValues.row?._id) {
                return { ...item, operationApprove: selectedValue };
              }
              return item;
            });
            dispatch(fetchInvoiceDetailsData(updatedData));
            dispatch(getFilteredInvoiceDetails(updatedData));
          } catch (error) {
            console.error("Error updating status:", error);
            // Handle error if needed
          }
        };

        // console.log("selectedOptionPaid", selectedOptionPaidValue)
        return (
          <div>
            <Select
              value={selectedOptionPaidValue}
              onChange={handleOptionChange}
              label="Payment Status"
              size="small"
              style={{ paddingRight: "20px" }}
            >
              <MenuItem value="True">
                {selectedOptionPaidValue ? "True" : "False"}
              </MenuItem>
              <MenuItem value="False">
                {selectedOptionPaidValue ? "False" : "True"}
              </MenuItem>
            </Select>
          </div>
        );
      },
      // flex: 2,
    },
    {
      field: "updatedAtOperation",
      headerName: `Ops Updated At`,
      renderCell: (cellValues) => {
        const data = cellValues?.row?.updatedAtOperation;
        console.log("data",data)
        // return amountData;
        return data ? moment(data).format('YYYY-MM-DD HH:mm') : 'N/A';
      },
      // flex: 2.5,
    },

    {
      field: "financeApprove",
      headerName: `Finance Approve`,

      renderCell: (cellValues) => {
        // console.log(cellValues);
        const isPaid = cellValues?.row?.financeApprove;
        // console.log("isPaid", isPaid);
        // console.log("selectedOptionPaid", selectedOptionPaid);

        // Set the value for the Select based on the isPaid value
        //  selectedOptionPaid = isPaid ? "Paid" : "Unpaid";
        // const selectedOptionPaidValue = selectedOptionPaid || isPaid ? "Paid" : "Unpaid";

        let selectedOptionPaidValue;

        if (selectedOptionPaid === false && isPaid === true) {
          selectedOptionPaidValue = "True";
        } else if (selectedOptionPaid === true && isPaid === true) {
          selectedOptionPaidValue = "True";
        } else if (isPaid === true) {
          selectedOptionPaidValue = "True";
        } else {
          selectedOptionPaidValue = "False";
        }

        const handleOptionChange = async (event) => {
          const selectedValue = event.target.value === "True";

          // Update the local state
          setSelectedOptionPaid(selectedValue);

          // const updatedIsPaidValue = selectedValue === 'Paid';
          const payload = {
            financeApprove: selectedValue,
          };

          try {
            await dispatch(
              updateInvoiceById({ payload, id: cellValues.row._id })
            );

            // Update the local item
            const updatedData = invoiceData?.map((item) => {
              // console.log("itemId", item._id)
              // console.log("cellId", cellValues.row._id)
              if (item?._id === cellValues.row?._id) {
                return { ...item, financeApprove: selectedValue };
              }
              return item;
            });
            dispatch(fetchInvoiceDetailsData(updatedData));
            dispatch(getFilteredInvoiceDetails(updatedData));
          } catch (error) {
            console.error("Error updating status:", error);
            // Handle error if needed
          }
        };

        // console.log("selectedOptionPaid", selectedOptionPaidValue)
        return (
          <div>
            <Select
              value={selectedOptionPaidValue}
              onChange={handleOptionChange}
              label="Payment Status"
              size="small"
              style={{ paddingRight: "20px" }}
            >
              <MenuItem value="True">
                {selectedOptionPaidValue ? "True" : "False"}
              </MenuItem>
              <MenuItem value="False">
                {selectedOptionPaidValue ? "False" : "True"}
              </MenuItem>
            </Select>
          </div>
        );
      },
      // flex: 2,
    },
    {
      field: "updatedAtFinanced",
      headerName: `Finance Updated At`,
      renderCell: (cellValues) => {
        const data = cellValues?.row?.updatedAtFinanced;
        console.log("data",data)
        // return amountData;
        return data ? moment(data).format('YYYY-MM-DD HH:mm') : 'N/A';
      },
      // flex: 2.5,
    },

    {
      field: "isPaid",
      headerName: `Paid Status`,

      renderCell: (cellValues) => {
        const isPaid = cellValues?.row?.isPaid;
        // console.log("isPaid", isPaid);
        // console.log("selectedOptionPaid", selectedOptionPaid);

        // Set the value for the Select based on the isPaid value
        //  selectedOptionPaid = isPaid ? "Paid" : "Unpaid";
        // const selectedOptionPaidValue = selectedOptionPaid || isPaid ? "Paid" : "Unpaid";

        let selectedOptionPaidValue;

        if (selectedOptionPaid === false && isPaid === true) {
          selectedOptionPaidValue = "Paid";
        } else if (selectedOptionPaid === true && isPaid === true) {
          selectedOptionPaidValue = "Paid";
        } else if (isPaid === true) {
          selectedOptionPaidValue = "Paid";
        } else {
          selectedOptionPaidValue = "Unpaid";
        }

        const handleOptionChange = async (event) => {
          const selectedValue = event.target.value === "Paid";

          // Update the local state
          setSelectedOptionPaid(selectedValue);

          // const updatedIsPaidValue = selectedValue === 'Paid';
          const payload = {
            isPaid: selectedValue,
          };

          try {
            await dispatch(
              updateInvoiceById({ payload, id: cellValues.row._id })
            );

            // Update the local item
            const updatedData = invoiceData?.map((item) => {
              // console.log("itemId", item._id)
              // console.log("cellId", cellValues.row._id)
              if (item?._id === cellValues.row?._id) {
                return { ...item, isPaid: selectedValue };
              }
              return item;
            });
            dispatch(fetchInvoiceDetailsData(updatedData));
            dispatch(getFilteredInvoiceDetails(updatedData));
          } catch (error) {
            console.error("Error updating status:", error);
            // Handle error if needed
          }
        };

        // console.log("selectedOptionPaid", selectedOptionPaidValue)
        return (
          <div>
            <Select
              value={selectedOptionPaidValue}
              onChange={handleOptionChange}
              label="Payment Status"
              size="small"
              style={{ paddingRight: "20px" }}
            >
              {/* <MenuItem value="Paid">Paid</MenuItem>
              <MenuItem value="Unpaid">Unpaid</MenuItem> */}
              <MenuItem value="Paid">
                {selectedOptionPaidValue ? "Paid" : "Unpaid"}
              </MenuItem>
              <MenuItem value="Unpaid">
                {selectedOptionPaidValue ? "Unpaid" : "Paid"}
              </MenuItem>
            </Select>
          </div>
        );
      },
      // flex: 2,
    },
    {
      field: "updatedAtPaid",
      headerName: `Status Updated At`,
      renderCell: (cellValues) => {
        const data = cellValues?.row?.updatedAtPaid;
        console.log("data",data)
        // return amountData;
        return data ? moment(data).format('YYYY-MM-DD HH:mm') : 'N/A';
      },
      // flex: 2.5,
    },

    {
      field: "batchTransferId",
      headerName: `Batch Transfer ID`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        // var rowName = cellValues?.row?.cashFreeData;
        // if (rowName && rowName.length > 0) {
          // const cashfreeData = rowName[0].batchTransferId
          // return cashfreeData
        // } else {
        //   return "NA";
        // }
      },
      valueGetter: getBatchTransferId
      // flex: 2.5,
    },

    {
      field: "paidStatus",
      headerName: `Cashfree Paid Status`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var rowName = cellValues?.row?.cashFreeData;
        if (rowName && rowName.length > 0) {
          const cashfreeData = rowName[0].paidStatus
          // console.log("cashfreeData", cashfreeData)
          if(cashfreeData === false){
            return "False"
          }else{
            return "True"
          }
          // return cashfreeData
        } else {
          return "NA";
        }
      },
      // flex: 2.5,
    },
    {
      field: "paidAmount",
      headerName: `Paid Amount`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var rowName = cellValues?.row?.cashFreeData;
        if (rowName && rowName.length > 0) {
          const cashfreeData = rowName[0].paidAmount
          return cashfreeData
        } else {
          return "NA";
        }
      },
      // flex: 2.5,
    },

    {
      field: "transferId",
      headerName: `Transfer ID`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var rowName = cellValues?.row?.cashFreeData;
        if (rowName && rowName.length > 0) {
          const cashfreeData = rowName[0].transferId
          return cashfreeData
        } else {
          return "NA";
        }
      },
      // flex: 2.5,
    },

    {
      field: "reason",
      headerName: `Reason`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues)
        var rowName = cellValues?.row?.cashFreeData;
        if (rowName && rowName.length > 0) {
          const cashfreeData = rowName[0].reason
          return cashfreeData
        } else {
          return "NA";
        }
      },
      // flex: 2.5,
    },

  ];

  //for display with paginated wise
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        fetchInvoiceDetailsData({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          branch: filterBranchData,
          // filterAll: filterAll,
          startDate: startDate,
          endDate: endDate,
          startDueDate: startDueDate,
          endDueDate: endDueDate,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    filterBranchData,
    // filterAll,
    startDate,
    endDate,
    startDueDate,
    endDueDate,
  ]);

  // //for export all csv in one click
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getFilteredInvoiceDetails({
  //         searchKey: searchKey,
  //         branch: filterBranchData,
  //         // filterAll: filterAll,
  //         startDate: startDate,
  //         endDate: endDate,
  //         startDueDate: startDueDate,
  //         endDueDate: endDueDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, searchKey, filterBranchData, startDate, endDate, startDueDate, endDueDate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getInvoiceFinanceByDetails({
  //         searchKey: searchKey,
  //         branch: filterBranchData,
  //         // filterAll: filterAll,
  //         startDate: startDate,
  //         endDate: endDate,
  //         startDueDate: startDueDate,
  //         endDueDate: endDueDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, searchKey, filterBranchData, startDate, endDate, startDueDate, endDueDate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getInvoiceOperationByDetails({
  //         searchKey: searchKey,
  //         branch: filterBranchData,
  //         // filterAll: filterAll,
  //         startDate: startDate,
  //         endDate: endDate,
  //         startDueDate: startDueDate,
  //         endDueDate: endDueDate,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [dispatch, searchKey, filterBranchData, startDate, endDate, startDueDate, endDueDate]);

  // //With filter and searching for exporting as pdf and csv
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const hasAnyFilter =
  //       searchKey.trim() !== "" ||
  //       filterBranchData.trim() !== "" ||
  //       startDate.trim() !== "" ||
  //       endDate.trim() !== "" ||
  //       startDueDate.trim() !== "" ||
  //       endDueDate.trim() !== "";

  //     try {
  //       if (hasAnyFilter) {
  //         await dispatch(
  //           // getFilteredInvoiceDetails({
  //           //   searchKey: searchKey,
  //           //   branch: filterBranchData,
  //           //   // filterAll: filterAll,
  //           //   startDate: startDate,
  //           //   endDate: endDate,
  //           // }),
  //           await Promise.all([
  //             dispatch(
  //               getFilteredInvoiceDetails({
  //                 searchKey: searchKey,
  //                 branch: filterBranchData,
  //                 // filterAll: filterAll,
  //                 startDate: startDate,
  //                 endDate: endDate,
  //                 startDueDate: startDueDate,
  //                 endDueDate: endDueDate,
  //               })
  //             ),
  //             dispatch(
  //               getInvoiceFinanceByDetails({
  //                 searchKey: searchKey,
  //                 branch: filterBranchData,
  //                 // filterAll: filterAll,
  //                 startDate: startDate,
  //                 endDate: endDate,
  //                 startDueDate: startDueDate,
  //                 endDueDate: endDueDate,
  //               })
  //             ),
  //             dispatch(
  //               getInvoiceOperationByDetails({
  //                 searchKey: searchKey,
  //                 branch: filterBranchData,
  //                 // filterAll: filterAll,
  //                 startDate: startDate,
  //                 endDate: endDate,
  //                 startDueDate: startDueDate,
  //                  endDueDate: endDueDate,
  //               })
  //             ),
  //           ])
  //         );
  //       } else {
  //         // If nothing is specified, you might want to handle this case
  //         await dispatch(
  //           fetchInvoiceDetailsData({
  //             currentPage: currentPage,
  //             pageSize: pageSize,
  //             searchKey: searchKey,
  //             branch: filterBranchData,
  //             // filterAll: filterAll,
  //             startDate: startDate,
  //             endDate: endDate,
  //             startDueDate: startDueDate,
  //             endDueDate: endDueDate,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKey,
  //   filterBranchData,
  //   // filterAll,
  //   startDate,
  //   endDate,
  //   startDueDate,
  //   endDueDate
  // ]);

  // useEffect(() => {
  //   if (invoiceFilteredData && invoiceFilteredData.length > 0) {
  //     setDataLoaded(true);
  //     setIsDownloading(false);
  //   } else {
  //     setDataLoaded(false);
  //     setIsDownloading(true); // Set loading state to true when waiting for data
  //   }
  // }, [invoiceFilteredData]);

  useEffect(() => {
    dispatch(HospitalBranchList([true]));
  }, []);

  const handleClose = () => {
    setCompletionMessage("");
  };

  const handlePageChange = (newPage) => {
    setCurrentFilterPage(newPage);
  };

  // const handleStartDateChange = (startDate) => {
  //   dispatch(updateStartDate(startDate));
  // };

  // const handleEndDateChange = (endDate) => {
  //   dispatch(updateEndDate(endDate));
  // }

  // const filterByDate = () => {
  //   if (!startDate) {
  //     setCompletionMessage("Please pick Start Date");
  //     return;
  //   }
  //   if (!endDate) {
  //     setCompletionMessage("Please pick End Date");
  //     return;
  //   }
  //   const startIndex = currentFilterPage * recordsPerPage;
  //   const endIndex = startIndex + recordsPerPage;

  //   // localStorage.setItem("startDate", startDate);
  //   // localStorage.setItem("endDate", endDate);

  //   const filteredData = invoiceData.filter((item) => {
  //     // console.log("item>>", item);
  //     // console.log("2->", item.createdAt);

  //     if (
  //       item.createdAt === undefined ||
  //       !item.createdAt ||
  //       item.createdAt === "NA"
  //     ) {
  //       return false;
  //     }
  //     const createdAt = moment(item.createdAt);
  //     // console.log("1-->", createdAt);
  //     return (
  //       (!startDate || createdAt.isSameOrAfter(startDate, "day")) &&
  //       (!endDate || createdAt.isSameOrBefore(endDate, "day"))
  //     );
  //   });
  //   // console.log("folteredData>>>", filteredData)
  //   if (filteredData.length === 0) {
  //     setCompletionMessage("No data found for the selected date range");
  //     setShowPagination(false);
  //     setFilteredInvoiceData([]);
  //     return;
  //   }
  //   setTotalRecords(filteredData.length);
  //   const slicedData = filteredData.slice(startIndex, endIndex);

  //   setFilteredInvoiceData(slicedData);
  //   setShowPagination(true);
  // };
  // console.log("3=======>", filteredInvoiceData);

  // useEffect(filterByDate, [currentPage, invoiceData, startDate, endDate]);
  // useEffect(() => {
  //   if (filterButtonClicked) {
  //     filterByDate();
  //   }
  // }, [currentFilterPage, invoiceData, startDate, endDate, filterButtonClicked]);

  // const handleRecordsPerPageChange = (event) => {
  //   const newRecordsPerPage = parseInt(event.target.value, 10);
  //   setRecordsPerPage(newRecordsPerPage);
  //   setCurrentFilterPage(0);
  //   filterByDate();
  // };

  // const handleDownloadData1 = () => {
  //   setLoading1(true);
  //   if (
  //     filterReset ||
  //     !filteredInvoiceData ||
  //     filteredInvoiceData.length === 0
  //   ) {
  //     setCompletionMessage("No filter data available. Please apply a filter.");
  //     setLoading1(false);
  //     setFilterReset(false);
  //     return;
  //   }
  //   // if (!filteredInvoiceData || filteredInvoiceData.length === 0) {
  //   //   setCompletionMessage("Filter data not found");
  //   //   setLoading1(false);
  //   //   return;
  //   // }
  //   const zip = new JSZip();
  //   Promise.all(
  //     filteredInvoiceData.map((invoiceItem, index) => {
  //       return new Promise((resolve, reject) => {
  //         const referredUsersTotal = invoiceItem?.referredUsers.reduce(
  //           (acc, user) => acc + user.amount,
  //           0
  //         );
  //         // console.log();
  //         const totalAmount = invoiceItem?.amount + referredUsersTotal;
  //         const pdf = new jsPDF({
  //           orientation: "portrait",
  //           unit: "px",
  //           hotfixes: ["px_scaling"],
  //           format: [1200, 800],
  //         });
  //         const pdfContent = `

  // <!DOCTYPE html>
  // <html lang="en">
  //   <head>
  //     <meta charset="UTF-8" />
  //     <title>Invoice</title>
  //     <style>
  //     * {
  //       margin:0;
  //       padding:0;
  //       font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  //       color:  #001f3f;
  //     }
  //      @media (max-width: 600px) {
  //        .child {
  //         width: 90%;
  //         height: auto;
  //       }
  //     }
  //     </style>
  //   </head>
  //   <body>
  //     <div style=" display: flex;justify-content: center;align-items: center; width:850px;height: 1190px;">
  //       <div style=" border: 1px solid black;box-shadow: 20px;width: 880px;height: 100%;padding: 15px; margin: auto;">
  //         <h2 style="text-align: center; font-weight: bold;">Invoice</h2>
  //         <div style=" padding-left: 10px;display: flex;justify-content: space-between;text-align: start; width:700px;margin-bottom: 5px;font-size: 12px;">
  //           <div style="width: 70%;">
  //           <span> ${moment(invoiceItem?.shiftDate).format("DD/MM/YYYY")}
  //           </span>
  //             <br />
  //             <span>Name: ${invoiceItem?.user?.name} </span><br />
  //             <span>Address: ${
  //               invoiceItem?.user?.address?.address
  //                 ? invoiceItem?.user?.address?.address
  //                 : "NA"
  //             } </span><br /><br />
  //             <span>Client Name: IFANFLEX PRIVATE LIMITED </span><br />
  //             <span>Address: PLOT 89, SECTOR 44, GURUGRAM, Haryana-122003 </span><br /><br />
  //           </div>

  //           <div style="float: right; width: 30%;">
  //             <span >Invoice Number:  ${
  //               invoiceItem?.invoiceNumber
  //             } </span><br />
  //             <span>PAN: ${invoiceItem?.user?.pan} </span><br />
  //             <span>Job Id: ${invoiceItem?.jobId} </span><br />
  //             <span>Shift Date: ${moment(invoiceItem?.shiftDate).format(
  //               "DD/MM/YYYY"
  //             )} </span><br />
  //             <span>Shift Start Time: ${moment(
  //               new Date(+invoiceItem?.shiftStartDate)
  //             ).format("HH:mm")} </span><br />
  //             <span>Shift End Time: ${moment(
  //               new Date(+invoiceItem?.shiftEndDate)
  //             ).format("HH:mm")}</span><br /><br />
  //           </div>
  //         </div>
  //         <div>
  //           <table style="text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px; font-size: 12px;">
  //             <thead>
  //               <tr>
  //                 <th class="heading", style=" font-weight: bold; text-align: left; border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Particulars</th>
  //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Hours Worked</th>
  //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Rate Per Hour</th>
  //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Amount (INR)</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Consultancy Fees For ${
  //                   invoiceItem?.jobId
  //                 }</td>
  //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
  //                   invoiceItem?.numberOfHours
  //                 }</td>
  //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
  //                   invoiceItem?.ratePerHour
  //                 }</td>
  //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
  //                   invoiceItem?.amount
  //                 }</td>
  //               </tr>

  //               ${invoiceItem?.referredUsers.map(
  //                 (data, index) => `
  //             <tr key="${index}">
  //               <td colspan="3" style="border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Referral Amount (${data.emailAddress})</td>
  //               <td style="border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${data.amount}</td>
  //             </tr>
  //           `
  //               )}
  //               <tr>
  //                 <td colspan="3", style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Total</td>
  //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${totalAmount}</td>
  //               </tr>
  //             </tbody>
  //           </table>
  //         </div></br>
  //         <div>
  //           <h3>Wire Details:</h3>
  //           <table style="width: 100%;table-layout: fixed;  text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px;font-size: 12px;">
  //             <tr>
  //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Name of Beneficiary</th>
  //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left; height: 5px;">${
  //                 invoiceItem?.user?.name
  //               }</td>
  //             </tr>
  //             <tr>
  //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Bank Name</th>
  //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
  //                 invoiceItem?.bank?.bankName
  //                   ? invoiceItem?.bank?.bankName
  //                   : "NA"
  //               }</td>
  //             </tr>
  //             <tr>
  //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Branch Address</th>
  //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
  //                 invoiceItem?.bank?.branchAddress
  //                   ? invoiceItem?.bank?.branchAddress
  //                   : "NA"
  //               }</td>
  //             </tr>
  //             <tr>
  //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">IBAN/ACC NUMBER</th>
  //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
  //                 invoiceItem?.bank?.accountNumber
  //                   ? invoiceItem?.bank?.accountNumber
  //                   : "NA"
  //               }</td>
  //             </tr>
  //             <tr>
  //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">IFSC</th>
  //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
  //                 invoiceItem?.bank?.ifsc ? invoiceItem?.bank?.ifsc : "NA"
  //               }</td>
  //             </tr>
  //           </table>
  //           <p style="margin-top: -15px;margin-left: 8px;font-size: 11px;color: grey;font-style: italic;">
  //             *All the payments are subjected to a standard deduction of 10% TDS <br />
  //             *This is system generated invoice issued by Professional with assistance of Ifanflex Pvt. Ltd.
  //           </p>
  //         </div>
  //       </div>
  //     </div>

  //   </body>
  // </html>

  //       `;

  //         pdf.html(pdfContent, {
  //           callback: (pdf) => {
  //             // pdf.save("User Invoice.pdf");
  //             // const pdfBlob = window.open(pdf.output("bloburl"));
  //             const pdfBlob = pdf.output("blob");
  //             resolve({ pdfBlob, index });
  //           },
  //         });
  //       });
  //     })
  //   )
  //     .then((pdfResults) => {
  //       pdfResults.forEach(({ pdfBlob, index }) => {
  //         zip.file(`Invoice_${index + 1}.pdf`, pdfBlob);
  //       });

  //       // Generate ZIP file
  //       zip.generateAsync({ type: "blob" }).then(function (content) {
  //         const a = document.createElement("a");
  //         a.href = URL.createObjectURL(content);
  //         a.download = "Invoices.zip";
  //         a.click();
  //         setCompletionMessage(
  //           "All PDFs downloaded and wrapped in ZIP successfully!!"
  //         );
  //         setLoading1(false);
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDFs:", error);
  //       setLoading1(false);
  //     });

  //   //   try{

  //   //     filteredInvoiceData.forEach((invoiceItem, index) => {

  //   //       const referredUsersTotal = invoiceItem?.referredUsers.reduce(
  //   //         (acc, user) => acc + user.amount,
  //   //         0
  //   //       );
  //   //       const totalAmount = invoiceData[0]?.amount + referredUsersTotal;
  //   //       const pdf = new jsPDF({
  //   //         orientation: "portrait",
  //   //         unit: "px",
  //   //         hotfixes: ["px_scaling"],
  //   //         format: [1200, 800],
  //   //       });

  //   //       const pdfContent = `

  //   // <!DOCTYPE html>
  //   // <html lang="en">
  //   //   <head>
  //   //     <meta charset="UTF-8" />
  //   //     <title>Invoice</title>
  //   //     <style>
  //   //     * {
  //   //       margin:0;
  //   //       padding:0;
  //   //       font-family:Bookman Old Style, Georgia, serif;
  //   //     }
  //   //      @media (max-width: 600px) {
  //   //        .child {
  //   //         width: 90%;
  //   //         height: auto;
  //   //       }
  //   //     }
  //   //     </style>
  //   //   </head>
  //   //   <body>
  //   //     <div style=" display: flex;justify-content: center;align-items: center; width:800px;">
  //   //       <div style=" border: 1px solid black;box-shadow: 20px;width: 780px;height: 100%;padding: 15px; margin: auto;">
  //   //         <h2 style="text-align: center; font-weight: bold;">Invoice</h2>
  //   //         <div style=" padding-left: 10px;display: flex;justify-content: space-between;text-align: start; width:700px;">
  //   //           <div >
  //   //           <span> ${moment(invoiceItem?.shiftDate).format("DD/MM/YYYY")}
  //   //           </span>
  //   //             <br />
  //   //             <span>Name: ${invoiceItem?.user?.name} </span><br /><br />
  //   //             <span>Address: ${invoiceItem?.user?.address?.address ? invoiceItem?.user?.address?.address : 'NA'} </span><br />
  //   //             <br /><br />
  //   //             <span>Client Name: ${
  //   //               invoiceItem?.branchName ? invoiceItem.branchName : 'NA'
  //   //             } </span><br /><br /><br />
  //   //             <span>Address: ${
  //   //               invoiceItem?.branchAddress ? invoiceItem?.branchAddress : 'NA'
  //   //             } </span><br /><br /><br />
  //   //           </div>

  //   //           <div style="float: right;">
  //   //             <span>Invoice Number:  ${
  //   //               invoiceItem?.invoiceNumber
  //   //             } </span><br /><br />
  //   //             <span>PAN: ${invoiceItem?.user?.pan} </span><br /><br />
  //   //             <span>Job Id: ${invoiceItem?.jobId} </span><br /><br />
  //   //             <span>Shift Date: ${moment(invoiceItem?.shiftDate).format(
  //   //               "DD/MM/YYYY"
  //   //             )} </span><br /><br />
  //   //             <span>Shift Time: ${moment(
  //   //               new Date(+invoiceItem?.shiftStartDate)
  //   //             ).format("HH:mm")} </span><br /><br />
  //   //             <span>Shift Time: ${moment(
  //   //               new Date(+invoiceItem?.shiftEndDate)
  //   //             ).format("HH:mm")}</span><br /><br />
  //   //           </div>
  //   //         </div>
  //   //         <div>
  //   //           <table style="text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px;">
  //   //             <thead>
  //   //               <tr>
  //   //                 <th class="heading", style=" font-weight: bold; text-align: left; border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Particulars</th>
  //   //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Hours Worked</th>
  //   //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Rate Per Hour</th>
  //   //                 <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Amount (INR)</th>
  //   //               </tr>
  //   //             </thead>
  //   //             <tbody>
  //   //               <tr>
  //   //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Consultancy Fees For ${
  //   //                   invoiceItem?.jobId
  //   //                 }</td>
  //   //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${
  //   //                   invoiceItem?.numberOfHours
  //   //                 }</td>
  //   //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${
  //   //                   invoiceItem?.ratePerHour
  //   //                 }</td>
  //   //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${
  //   //                   invoiceItem?.amount
  //   //                 }</td>
  //   //               </tr>

  //   //               ${invoiceItem?.referredUsers.map((data, index) => (
  //   //                 <tr key={index}>
  //   //                     <td colspan="3">Referral Amount ({data.emailAddress})</td>
  //   //                     <td>{data.amount}</td>
  //   //                 </tr>
  //   //             ))}
  //   //               <tr>
  //   //                 <td colspan="3", style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Total</td>
  //   //                 <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${totalAmount}</td>
  //   //               </tr>
  //   //             </tbody>
  //   //           </table>
  //   //         </div></br>
  //   //         <div>
  //   //           <h3>Wire Details:</h3>
  //   //           <table style="width: 100%;table-layout: fixed;  text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px;">
  //   //             <tr>
  //   //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Name of Beneficiary</th>
  //   //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${
  //   //                 invoiceItem?.user?.name
  //   //               }</td>
  //   //             </tr>
  //   //             <tr>
  //   //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Bank Name</th>
  //   //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${invoiceItem?.bank?.bankName ? invoiceItem?.bank?.bankName : 'NA'}</td>
  //   //             </tr>
  //   //             <tr>
  //   //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">Branch Address</th>
  //   //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${invoiceItem?.bank?.branchAddress ? invoiceItem?.bank?.branchAddress : 'NA'}</td>
  //   //             </tr>
  //   //             <tr>
  //   //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">IBAN/ACC NUMBER</th>
  //   //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${invoiceItem?.bank?.accountNumber ? invoiceItem?.bank?.accountNumber : 'NA'}</td>
  //   //             </tr>
  //   //             <tr>
  //   //               <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">IFSC</th>
  //   //               <td style="  border: 1px solid black;border-collapse: collapse;padding: 10px;text-align: left;">${invoiceItem?.bank?.ifsc ? invoiceItem?.bank?.ifsc: 'NA'}</td>
  //   //             </tr>
  //   //           </table>
  //   //           <p style="padding: 5px;font-size: 15px;color: grey;font-style: italic;">
  //   //             *All the payments are subjected to a standard deduction of 10% TDS <br />
  //   //             *This is system generated invoice issued by Professional with assistance of Ifanflex Pvt. Ltd.
  //   //           </p>
  //   //         </div>
  //   //       </div>
  //   //     </div>

  //   //   </body>
  //   // </html>

  //   //       `;

  //   //       const opt = {
  //   //         callback: (pdf) => {
  //   //           // pdf.save(`User Invoice_${index + 1}.pdf`);
  //   //           // window.open(pdf.output("bloburl"));
  //   //             const pdfBlob = pdf.output("blob");

  //   //             zip.file(`Invoice_${index + 1}.pdf`, pdfBlob);

  //   //             if (index === filteredInvoiceData.length - 1) {
  //   //               zip.generateAsync({ type: "blob" }).then(function (content) {
  //   //                 const a = document.createElement("a");
  //   //                 a.href = URL.createObjectURL(content);
  //   //                 a.download = "Invoices.zip";
  //   //                 a.click();
  //   //                 setCompletionMessage("Export completed!");
  //   //                 setLoading1(false);
  //   //               });
  //   //             }
  //   //         },
  //   //       };

  //   //       pdf.html(pdfContent, opt);
  //   //     });

  //   //   }catch (error) {
  //   //     console.error("Error exporting PDFs and zip:", error);
  //   //   // Handle errors as needed
  //   //   setLoading1(false);
  //   //   }
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFClose = () => {
    setOpen(false);
  };

  const handleDownloadDataWithConfirmation = () => {
    setOpen(true);
  };

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
    // console.log("in", invoiceData)
    setLoading1(true);
    setOpen(false);
    const zip = new JSZip();
    Promise.all(
      invoiceData.map((invoiceItem, index) => {
        return new Promise((resolve, reject) => {
          const referredUsersTotal = invoiceItem?.referredUsers.reduce(
            (acc, user) => acc + user.amount,
            0
          );
          // console.log("invoiceItem",invoiceItem);
          const newMarginCost = `${
            invoiceItem?.costToCandidate
              ? -(invoiceItem?.amount - invoiceItem?.costToCandidate)
              : "0"
          }`;
          const totalAmount = invoiceItem?.amount + referredUsersTotal;
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            hotfixes: ["px_scaling"],
            format: [1200, 800],
          });
          const pdfContent = `
        
        
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <title>Invoice</title>
      <style>
      * {
        margin:0;
        padding:0;
        font-family:"Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        color:  #001f3f;
      }
       @media (max-width: 600px) {
         .child {
          width: 90%;
          height: auto;
        }
      }
      </style>
    </head>
    <body>
      <div style=" display: flex;justify-content: center;align-items: center; width:800px;height: 1190px;">
        <div style=" border: 1px solid black;box-shadow: 20px;width: 880px;height: 100%;padding: 15px; margin: auto;">
          <h2 style="text-align: center; font-weight: bold;">Invoice</h2>
          <div style=" padding-left: 10px;display: flex;justify-content: space-between;text-align: start; width:700px;margin-bottom: 5px;font-size: 12px;">
            <div style="width: 70%;">
            <span> ${moment(invoiceItem?.shiftDate).format("DD/MM/YYYY")}
            </span>
              <br />
              <span>Name: ${invoiceItem?.user?.name} </span><br />
              <span>Address: ${
                invoiceItem?.user?.address?.address
                  ? invoiceItem?.user?.address?.address
                  : "NA"
              } </span><br /><br />
              <span>Client Name: IFANFLEX PRIVATE LIMITED </span><br />
              <span>Address: PLOT 89, SECTOR 44, GURUGRAM, Haryana-122003 </span><br /><br />
            </div>
  
            <div style="float: right; width: 30%;">
              <span >Invoice Number:  ${
                invoiceItem?.invoiceNumber
              } </span><br />
              <span>PAN: ${invoiceItem?.user?.pan} </span><br />
              <span>Job Id: ${invoiceItem?.jobId} </span><br />
              <span>Shift Date: ${moment(invoiceItem?.shiftDate).format(
                "DD/MM/YYYY"
              )} </span><br />
              <span>Shift Start Time: ${moment(
                new Date(+invoiceItem?.shiftStartDate)
              ).format("HH:mm")} </span><br />
              <span>Shift End Time: ${moment(
                new Date(+invoiceItem?.shiftEndDate)
              ).format("HH:mm")}</span><br /><br />
            </div>
          </div>
          <div>
            <table style="text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px; font-size: 12px;">
              <thead>
                <tr>
                  <th class="heading", style=" font-weight: bold; text-align: left; border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Particulars</th>
                  <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Hours Worked</th>
                  <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Rate Per Hour</th>
                  <th style="font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Amount (INR)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Consultancy Fees For ${
                    invoiceItem?.jobId
                  }</td>
                  <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
                    invoiceItem?.numberOfHours
                  }</td>
                  <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
                    invoiceItem?.ratePerHour
                  }</td>
                  <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${
                    invoiceItem?.amount
                  }</td>
                </tr>
  
                ${invoiceItem?.referredUsers
                  .map(
                    (data, index) => `
              <tr key="${index}">
                <td colspan="3" style="border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Referral Amount (${data.phoneNumber})</td>
                <td style="border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${data.amount}</td>
              </tr>
            `
                  )
                  .join("")}
                <tr>
                  <td colspan="3", style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">Total</td>
                  <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 1px;">${totalAmount}</td>
                </tr>
              </tbody>
            </table>
          </div></br>
          <div>
            <h3>Wire Details:</h3>
            <table style="width: 100%;table-layout: fixed;  text-align: left;width: 100%;border-collapse: collapse;margin-bottom: 20px;font-size: 12px;">
              <tr>
                <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Name of Beneficiary</th>
                <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left; height: 5px;">${
                  invoiceItem?.user?.name
                }</td>
              </tr>
              <tr>
                <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Bank Name</th>
                <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
                  invoiceItem?.bank?.bankName
                    ? invoiceItem?.bank?.bankName
                    : "NA"
                }</td>
              </tr>
              <tr>
                <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">Branch Address</th>
                <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
                  invoiceItem?.bank?.branchAddress
                    ? invoiceItem?.bank?.branchAddress
                    : "NA"
                }</td>
              </tr>
              <tr>
                <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">IBAN/ACC NUMBER</th>
                <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
                  invoiceItem?.bank?.accountNumber
                    ? invoiceItem?.bank?.accountNumber
                    : "NA"
                }</td>
              </tr>
              <tr>
                <th style="width: 50%; font-weight: bold; text-align: left;  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;">IFSC</th>
                <td style="  border: 1px solid black;border-collapse: collapse;padding: 5px;text-align: left;height: 5px;">${
                  invoiceItem?.bank?.ifsc ? invoiceItem?.bank?.ifsc : "NA"
                }</td>
              </tr>
            </table>
            <p style="margin-top: -15px;margin-left: 8px;font-size: 11px;color: grey;font-style: italic;">
              *All the payments are subjected to a standard deduction of 10% TDS <br />
              *This is system generated invoice issued by Professional with assistance of Ifanflex Pvt. Ltd.         
            </p>
          </div>
        </div>
      </div>
  
    </body>
  </html>
  
        
  
      
  
        
        `;

          pdf.html(pdfContent, {
            callback: (pdf) => {
              // pdf.save("User Invoice.pdf");
              // const pdfBlob = window.open(pdf.output("bloburl"));
              const pdfBlob = pdf.output("blob");
              resolve({ pdfBlob, index });
            },
          });
        });
      })
    )
      .then((pdfResults) => {
        pdfResults.forEach(({ pdfBlob, index }) => {
          // const invoiceNumber = invoiceData[index]?.invoiceNumber;
          // const folderPath = invoiceNumber.replace(/\//g, "_");
          // const fileName = `${folderPath}.pdf`;
          // zip.file(fileName, pdfBlob);

          // console.log(">>>invxData", invoiceData)
          const invoiceName = invoiceData[index]?.user?.name;
          // console.log("1--->", invoiceName)
          const isDr = invoiceName?.startsWith("Dr.");
          // console.log("2--->", isDr)
          const result = isDr ? invoiceName?.slice(4).trim() : invoiceName;
          // console.log("3--->", result)
          const nameParts = result?.split(" ");
          // console.log("4--->", nameParts)
          const firstName = nameParts?.length > 0 ? nameParts[0] : "";
          // console.log("5--->", firstName)
          function formatInvoiceJobDate(rawDate) {
            if (!rawDate) {
              return null;
            }

            const dateObject = new Date(rawDate);
            const day = String(dateObject.getDate()).padStart(2, "0");
            const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
            const year = dateObject.getFullYear();

            return `${day}_${month}_${year}`;
          }

          // const invoiceJobDate = invoiceData[index]?.job?.consequtiveTime?.start?.datetime ? invoiceData[index]?.job?.consequtiveTime?.start?.datetime : invoiceData[index]?.job?.nonConsequtiveTime?.start?.datetime;
          const invoiceJobDate = invoiceData[index]?.shiftDate;
          // console.log("1-->",invoiceJobDate)
          const formattedDate = formatInvoiceJobDate(invoiceJobDate);
          // console.log("2-->", formattedDate)
          const lastPart = invoiceData[index]?.invoiceNumber.split("/").pop();
          const fileName = `${firstName}_${formattedDate}_${lastPart}.pdf`;
          zip.file(fileName, pdfBlob);

          // zip.file(`Invoice_${index + 1}.pdf`, pdfBlob);
        });

        // Generate ZIP file
        zip.generateAsync({ type: "blob" }).then(function (content) {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(content);
          // a.download = "Invoices.zip";
          a.download = `BulkInvoice_${formatDate()}.zip`;
          a.click();
          setCompletionMessage(
            "All PDFs downloaded and wrapped in ZIP successfully!!"
          );
          setLoading1(false);
        });
      })
      .catch((error) => {
        console.error("Error generating PDFs:", error);
        setLoading1(false);
      });
  };
  console.log("isDownloading", isDownloading);
  const waitForInvoiceFilteredData = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (invoiceFilteredData.length > 0) {
          clearInterval(interval);
          resolve(invoiceFilteredData);
        }
      }, 1000);
    });
  };

  const handleDownloadDataCSV = async () => {
    // setIsDownloading(true);
    setIsDownloadingFor("csv");
    try {
      const hasAnyFilter =
        searchKey.trim() !== "" ||
        filterBranchData.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "" ||
        startDueDate.trim() !== "" ||
        endDueDate.trim() !== "";

      let csvData = ""
      if (hasAnyFilter) {
       const data =  await dispatch(
          getFilteredInvoiceDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      } else {
        const data = await dispatch(
          getFilteredInvoiceDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      }

      console.log("invoiceFilteredData", invoiceFilteredData)
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoices-${formatDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
     
    } catch (error) {
      console.error("Error during file download:", error);
    } finally {
      // setIsDownloading(false);
      setIsDownloadingFor(null);
    }
  };

  // invoice finance data
  const handleFinanceDownloadDataCSV = async () => {
    // setIsDownloading(true);
    setIsDownloadingFor("finance");
    try {
      const hasAnyFilter =
        searchKey.trim() !== "" ||
        filterBranchData.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "" ||
        startDueDate.trim() !== "" ||
        endDueDate.trim() !== "";
       let csvData = ""
      if (hasAnyFilter) {
       const data = await dispatch(
          getInvoiceFinanceByDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      } else {
        const data = await dispatch(
          getInvoiceFinanceByDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      }

        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `InvoicesForFinanceData-${formatDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
    } finally {
      // setIsDownloading(false);
      setIsDownloadingFor(null);
    }
  };
  // const handleFinanceDownloadDataCSV = async () => {
  //   try {
  //     // console.log("filter-->", filteredUserData)

  //     // if(filteredJobData && filteredJobData.length > 0){
  //     const response = invoiceFinanceByData;
  //     // console.log("1405-->",response)
  //     const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     // link.download = 'Jobizo Super Admin.csv';
  //     link.download = `InvoicesForFinanceData-${formatDate()}.csv`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error during file download:", error);
  //   }
  // };

  //invoices operations data
  const handleOperationDownloadDataCSV = async () => {
    // setIsDownloading(true);
    setIsDownloadingFor("operation");
    try {
      const hasAnyFilter =
        searchKey.trim() !== "" ||
        filterBranchData.trim() !== "" ||
        startDate.trim() !== "" ||
        endDate.trim() !== "" ||
        startDueDate.trim() !== "" ||
        endDueDate.trim() !== "";
        let csvData = ""
      if (hasAnyFilter) {
        const data = await dispatch(
          getInvoiceOperationByDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      } else {
       const data = await dispatch(
          getInvoiceOperationByDetails({
            searchKey,
            branch: filterBranchData,
            startDate,
            endDate,
            startDueDate,
            endDueDate,
          })
        );
        csvData = data.payload.data
      }

        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `InvoicesForOperationData-${formatDate()}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
      console.error("Error during file download:", error);
    } finally {
      // setIsDownloading(false);
      setIsDownloadingFor(null);
    }
  };
  // const handleOperationDownloadDataCSV = async () => {
  //   try {
  //     // console.log("filter-->", filteredUserData)

  //     // if(filteredJobData && filteredJobData.length > 0){
  //     const response = invoiceOperationByData;
  //     // console.log("1405-->",response)
  //     const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     // link.download = 'Jobizo Super Admin.csv';
  //     link.download = `InvoicesForOperationData-${formatDate()}.csv`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error during file download:", error);
  //   }
  // };

  // const currentDataRangeStart = currentFilterPage * recordsPerPage + 1;
  // const currentDataRangeEnd = Math.min(
  //   (currentFilterPage + 1) * recordsPerPage,
  //   totalRecords
  // );

  const handleResetFilter = () => {
    window.location.reload();
    dispatch(updateStartDate(""));
    dispatch(updateEndDate(""));
    dispatch(updateStartDueDate(""));
    dispatch(updateEndDueDate(""));
    // setStartDate("");
    // setEndDate("");
    setShowPagination(false);
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    setFilterReset(true);

    // setTimeout(() => {
    //   // window.location.reload();
    //   //   setLocalStartDate("");
    //   // setLocalEndDate("");
    //   setStartDate("");
    //   setEndDate("");
    //   setShowPagination(false);
    //   localStorage.removeItem("startDate");
    //   localStorage.removeItem("endDate");
    //   setFilterReset(true);
    // }, 0);
  };

  // useEffect(() => {
  //   const storedStartDate = localStorage.getItem("startDate");
  //   const storedEndDate = localStorage.getItem("endDate");

  //   // setLocalStartDate(storedStartDate || "");
  //   // setLocalEndDate(storedEndDate || "");

  //   if (storedStartDate || storedEndDate) {
  //     // setStartDate(storedStartDate || "");
  //     // setEndDate(storedEndDate || "");
  //     dispatch(updateStartDate(storedStartDate || ""));
  //     dispatch(updateEndDate(storedEndDate || ""));
  //     filterByDate();
  //   }
  // }, []);

  // const getPaginatedData = () => {
  //   const startIndex = currentFilterPage * recordsPerPage;
  //   const endIndex = startIndex + recordsPerPage;
  //   const slicedData = filteredInvoiceData.slice(startIndex, endIndex);
  //   return slicedData;
  // };

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    branchOptions.forEach((branch) => {
      if (branch.value === selectedBranch) {
        selectedBranchObjectID = branch.key;
      }
    });

    setBranchValue(selectedBranch);
    dispatch(filterBranch(selectedBranchObjectID));
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate));
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const handleStartDueDateChange = (startDueDate) => {
    dispatch(updateStartDueDate(startDueDate));
  };

  const handleEndDueDateChange = (endDueDate) => {
    dispatch(updateEndDueDate(endDueDate));
  };

  //   function formatDate() {
  //     const now = new Date();

  //     const day = String(now.getDate()).padStart(2, '0');

  //     const monthNames = [
  //       'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //       'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //     ];

  //     const month = monthNames[now.getMonth()];
  //     const year = now.getFullYear();

  //     const hours = String(now.getHours()).padStart(2, '0');
  //     const minutes = String(now.getMinutes()).padStart(2, '0');
  //     const seconds = String(now.getSeconds()).padStart(2, '0');

  //     const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

  //     return formattedDate;
  // }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadOptionClick = (option) => {
    setSelectedOption1(option);
    handleCloseMenu();
    // Add logic for PDF or CSV download based on the selected option
    console.log("Downloading as", option);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    if (sanitizedSearchKey) {
      dispatch(searchKeyword(sanitizedSearchKey));
    } else {
      dispatch(updateStartDate(inputStartDate));
      dispatch(updateEndDate(inputEndDate));
    }
  };
  // console.log("inputValue>>", inputValue)
  const handleFindButtonClick = () => {
    dispatch(updateStartDate(inputStartDate));
    dispatch(updateEndDate(inputEndDate));
  };

  const handleDueButtonClick = () => {
    dispatch(updateStartDueDate(inputDueStartDate));
    dispatch(updateEndDueDate(inputDueEndDate));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value.trim()) {
      // Check if the input value is empty or contains only whitespace
      // window.location.reload();
      setInputValue("");
      dispatch(fetchInvoiceDetailsData()); // Dispatch action to reset search
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClickSample = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseSample = () => {
    setAnchorEl1(null);
  };

  const handleDownloadSample = async (type) => {
    let sampleFile;
    let contentType;

    if (type === "finance") {
      sampleFile = SampleFinanceFile;
      contentType = "text/csv";
    } else if (type === "operation") {
      sampleFile = SampleOperationFile;
      contentType = "text/csv";
    }

    if (sampleFile) {
      const link = document.createElement("a");
      link.href = sampleFile;
      link.setAttribute(
        "download",
        `Sample ${type.charAt(0).toUpperCase() + type.slice(1)} Data.csv`
      );
      link.setAttribute("type", contentType);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // const handleDownloadSample = async (type) => {
  //   let sampleFile;
  //   if (type === "finance") {
  //     sampleFile = SampleFinanceFile;
  //   } else if (type === "operation") {
  //     sampleFile = SampleOperationFile;
  //   }

  //   if (sampleFile) {
  //   const link = document.createElement("a");
  //   link.href = sampleFile;
  //   link.setAttribute(
  //     "download",
  //     `Sample ${type.charAt(0).toUpperCase() + type.slice(1)} Data.xlsx`
  //   );
  //   link.setAttribute("type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); // Add Content-Type header
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  //   // if (sampleFile) {
  //   //   try {
  //   //     const response = await fetch(sampleFile);
  //   //     const blob = await response.blob();

  //   //     // Create a temporary anchor element to trigger download
  //   //     const link = document.createElement("a");
  //   //     link.href = window.URL.createObjectURL(blob);
  //   //     link.setAttribute(
  //   //       "download",
  //   //       `Sample ${type.charAt(0).toUpperCase() + type.slice(1)} Data.xlsx`
  //   //     );
  //   //     link.setAttribute(
  //   //       "type",
  //   //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //   //     );

  //   //     document.body.appendChild(link);
  //   //     link.click();
  //   //     document.body.removeChild(link);
  //   //   } catch (error) {
  //   //     console.error("Error downloading file:", error);
  //   //   }
  //   // }

  //   handleCloseSample();
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title1="Professional Invoice"
        showDialog={false}
        showisDeleted={false}
      />
      <Snackbar
        open={completionMessage !== ""}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
        >
          {completionMessage}
        </MuiAlert>
      </Snackbar>
      <Select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        displayEmpty
        style={{ marginLeft: "20px", height: "44px" }}
      >
        <MenuItem value="">Choose Filters</MenuItem>
        <MenuItem value="HospitalBranch">Hospital Branch</MenuItem>
        <MenuItem value="paymentDueDate">Payment Due Date</MenuItem>
      </Select>
      {selectedOption && (
        <>
          {selectedOption === "HospitalBranch" ? (
            <FormControl
              fullWidth
              style={{ marginLeft: "20px", width: "100px", height: "44px" }}
            >
              <InputLabel
                id="role-select-label"
                style={{ textAlign: "center" }}
              >
                Select HospitalBranch
              </InputLabel>
              <Select
                labelId="role-select-label"
                label="Select HospitalBranch"
                // value={filterAll.Role || ""}
                value={branchValue || ""}
                onChange={handleBranchFilterChange}
                fullWidth
                // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                style={{ width: "150px", height: "44px" }}
              >
                <MenuItem value="">Reset</MenuItem>
                {branchOptions.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : selectedOption === "paymentDueDate" ? (
            <>
              <TextField
                label="Due Start Date"
                type="date"
                // value={startDueDate}
                value={inputDueStartDate}
                // onChange={(e) => handleStartDueDateChange(e.target.value)}
                onChange={(e) => setInputDueStartDate(e.target.value)}
                onKeyPress={handleKeyPress}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  position: "absolute",
                  right: "820px",
                  width: "118px",
                  marginTop: "1px",
                }}
              />
              <TextField
                label="Due End Date"
                type="date"
                // value={endDueDate}
                value={inputDueEndDate}
                // onChange={(e) => handleEndDueDateChange(e.target.value)}
                onChange={(e) => setInputDueEndDate(e.target.value)}
                onKeyPress={handleKeyPress}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  position: "absolute",
                  right: "690px",
                  width: "118px",
                  marginTop: "1px",
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleDueButtonClick}
                style={{
                  position: "absolute",
                  right: "740px",
                  // width: "100px",
                  marginTop: "55px",
                }}
              >
                Find Due Date
              </Button>
            </>
          ) : null}
        </>
      )}
      <IconButton
        onClick={handleResetFilters}
        color="primary"
        style={{
          position: "absolute",
          right: "650px",
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </IconButton>
      <>
        <TextField
          label="Start Date"
          type="date"
          // value={startDate}
          value={inputStartDate}
          // onChange={(e) => handleStartDateChange(e.target.value)}
          onChange={(e) => setInputStartDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "525px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          // value={endDate}
          value={inputEndDate}
          // onChange={(e) => handleEndDateChange(e.target.value)}
          onChange={(e) => setInputEndDate(e.target.value)}
          onKeyPress={handleKeyPress}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            position: "absolute",
            right: "385px",
            width: "130px",
            marginTop: "1px",
          }}
        />
        {/* <Button
        variant="contained"
        color="primary"
        onClick={handleFindButtonClick}
        style={{
          position: "absolute",
          right: "520px",
          width: "50px",
          marginTop: "-50px",
        }}
      >
        Find
      </Button> */}
      </>
      {/* </div> */}

      {/* <Button
        onClick={handleDownloadDataWithConfirmation}
        variant="contained"
        color="primary"
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
        }}
      >
        Export
      </Button> */}

      <>
        <Button
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            right: "280px",
            height: "44px",
          }}
          // disabled={isDownloading}
        >
          {/* {selectedOption1 ? `Download as ${selectedOption1}` : "Export"} */}
          Export
          {/* {isDownloading ? <CircularProgress size={24} /> : "Export"} */}
        </Button>

        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDownloadDataWithConfirmation}>
            Download as PDF
          </MenuItem>

          <MenuItem
            onClick={handleDownloadDataCSV}
            disabled={isDownloadingFor === "csv"}
          >
            {isDownloadingFor === "csv" ? (
              <>
                Downloading...
                <CircularProgress size={20} style={{ marginLeft: 8 }} />
              </>
            ) : (
              "Download as CSV"
            )}
          </MenuItem>

          <MenuItem
            onClick={handleOperationDownloadDataCSV}
            disabled={isDownloadingFor === "operation"}
          >
            {isDownloadingFor === "operation" ? (
              <>
                Downloading...
                <CircularProgress size={20} style={{ marginLeft: 8 }} />
              </>
            ) : (
              "Download CSV for Operation"
            )}
          </MenuItem>

          <MenuItem
            onClick={handleFinanceDownloadDataCSV}
            disabled={isDownloadingFor === "finance"}
          >
            {isDownloadingFor === "finance" ? (
              <>
                Downloading...
                <CircularProgress size={20} style={{ marginLeft: 8 }} />
              </>
            ) : (
              "Download CSV for Finance"
            )}
          </MenuItem>
        </Menu>
      </>

      <Dialog open={open} onClose={handleFClose}>
        <DialogTitle>Export Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only 50 exports are allowed at a time. This might take some time as
            the data requested is large.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadData} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Button
        onClick={() => {
          // handleDownloadData()
          // alert('Please do not generate more than 50 invoices at once.');
          const confirmation = window.confirm(
            'Only 50 exports are allowed at a time. This might take some time as the data requested is large.'
          );
      
          if (confirmation) {
            handleDownloadData();
            // alert('Thanks for clicking!');
          } else {
            // alert('Export canceled.');
          }
        }}
        variant="contained"
        color="primary"
        style={{
          position: "absolute",
          right: "280px",
          height: "44px",
          // transition: 'background-color 0.3s',
          // backgroundColor: 'primary',
        }}
        // onMouseOver={(e) => e.target.style.backgroundColor = '#1976D2'} // Change to your primary color
        // onMouseOut={(e) => e.target.style.backgroundColor = 'primary'}
      >
        Export
      </Button> */}
      <>
        <TextField
          label={`Search by InvoiceNumber,JobId,PanNumber`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          // onChange={handleInputChange}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
      </>
      {/* <TextField
        label={`Search by invoiceNumber,JobId`}
        value={searchKey}
        onChange={(e) => dispatch(searchKeyword(e.target.value))}
        // fullWidth
        style={{ position: "absolute", right: "20px", width: "250px" }}
      /> */}
      {loading1 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <span style={{ fontSize: "15px", marginRight: "5px" }}>
            Please wait a few seconds...
          </span>
          <CircularProgress size={30} thickness={5} />
        </div>
      ) : completionMessage && !loading1 ? (
        <div style={{ marginLeft: "10px", color: "green" }}>
          {completionMessage}
        </div>
      ) : null}

      <div style={{ position: "absolute", right: "20px", marginTop: "1rem" }}>
        {/* <h2>Upload Dialog</h2> */}
        <input type="file" onChange={handleFileUpload} accept=".xlsx, .csv" />
        {/* <button onClick={handleUploadDialogClose}>Close</button> */}
      </div>

      <div style={{ position: "absolute", right: "350px", marginTop: "1rem" }}>
        <Grid container spacing={1}>
          {/* Rate Card UPLOADER */}
          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickSample}
              >
                Download Sample File &nbsp;
                <Icon>download</Icon>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleCloseSample}
              >
                <MenuItem onClick={() => handleDownloadSample("finance")}>
                  Sample for Finance
                </MenuItem>
                <MenuItem onClick={() => handleDownloadSample("operation")}>
                  Sample for Operation
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>
      </div>

      <MDBox mt={8}>
        <DataGrid
          getRowId={(row) => row?._id}
          loading={invoiceLoading ? true : false}
          columns={columns}
          // rows={[...invoiceData, ...filteredInvoiceData].reverse()}
          rows={[...invoiceData].reverse()}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              // showQuickFilter: true,
              csvOptions: { disableToolbarButton: true },
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              // csvOptions: {
              //   fileName: `UserInvoice-${formatDate()}`,
              // },
            },
          }}
          getRowHeight={() => "auto"}
          sx={{
            backgroundColor: "#ffff",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },

            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
          // pagination
          // density="compact"
          // pageSize={100}
          // rowsPerPageOptions={[100]}
          pageSize={pageSize}
          rowCount={totalCount}
          paginationMode="server"
          pagination
          page={currentPage}
          onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
          onPageChange={(newPage) => dispatch(pageChanged(newPage))}
          rowsPerPageOptions={[10, 25, 50, 100]}
          //isRowSelectable
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                invoiceNumber: true,
                jobId: true,
                professionalName: true,
                branchName: true,
                department: true,
                shiftDate: true,
                finalAmount: true,
                pan: true,
                accountNumber: true,
                bankName: true,
                ifsc: true,
                createdAt: true,
              },
            },
          }}
          disableColumnFilter
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default UserInvoiceDetails;
