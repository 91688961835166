import React, { useEffect, useRef } from "react";
import { InputLabel, Typography, Box, TextField } from "@mui/material";
import { FormHelperText } from "@material-ui/core";
import MDButton from "./../../components/MDButton/index";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import SelectDropDown from "./../../examples/DropDown/index";
// import Checkbox from "@mui/material/Checkbox";
import MDInput from "components/MDInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import adminPanelValidation, {
//   permanentJobCreateValidSchema,
// } from "../../util/validations/permanentJobCreateValidation";
import { addNewPermanentJob, getPermanentJobList } from "./Redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentType,
  getQualificationsList,
  getExperienceList,
  getSpecializationsList,
  getProfessionsList,
} from "../../layouts/common/Redux/actionCreator";
import {getQualificationsID} from "../../shared/services"
import { setPhoto } from "../../layouts/events/Redux/actionCreator";
import SelectDropDownNew from "examples/DropDownNew";
// import {permanentJobCreateValidations} from "util/validations/hospitalPanelValidation"
import { permanentJobCreateValidations } from "util/validations/permanentJobValidation";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { toast } from "react-toastify";
// import DatePicker from "@mui/lab/DatePicker";
import "@mui/icons-material/DateRange"; // Import the icons
import "@mui/lab/AdapterDateFns"; // Import the date-fns adapter
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import DateAdapter from "@mui/lab/AdapterDateFns"; // Import the date-fns adapter

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const JobFillForm = ({ handleDialogClose }) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    // defaultValues: {
    //   state: "OPEN",
    //   type: "DOMESTIC",
    // },
    resolver: yupResolver(permanentJobCreateValidations),
    // mode: "onTouched",
  });
  // console.log("register", register)

  // console.log("error>>>>", errors)
  // console.log("error>>>>", formState.errors)

  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isJobCreated, setIsJobCreated] = useState(false);
  const [isDepartmentSelected, setIsDepartmentSelected] = useState(false);
  const [isQualificationSelected, setIsQualificationSelected] = useState(false);
  const [isExperienceSelected, setIsExperienceSelected] = useState(false);
  const [isSpecializationSelected, setIsSpecializationSelected] =
    useState(false);
  const [isProfessionSelected, setIsProfessionSelected] = useState(false);
  const [isSalaryFilled, setIsSalaryFilled] = useState(false);

  const [department, setDepartment] = useState();
  const [qualification, setQualification] = useState();
  const [experience, setExperience] = useState();
  const [specialization, setSpecialization] = useState();
  const [profession, setProfession] = useState();
  const [salaryType, setSalaryType] = useState();
  const [fileLogo, setFileLogo] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [joiningDate, setJoiningDate] = useState(null);
  const [showLTCFields, setShowLTCFields] = useState(false);

  const selectStateOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ARCHIVED",
      value: "ARCHIVED",
    },
    {
      key: "HIRED",
      value: "HIRED",
    },
  ];

  const selectTypeOptions = [
    {
      key: "DOMESTIC",
      value: "DOMESTIC",
    },
    {
      key: "LTC",
      value: "LTC",
    },
    {
      key: "INTERNATIONAL",
      value: "INTERNATIONAL",
    },
  ];

  const selectSalaryTypeOptions = [
    {
      key: "ANNUAL",
      value: "ANNUAL",
    },
    {
      key: "MONTHLY",
      value: "MONTHLY",
    },
  ];

  const selectJoiningDatesOptions = [
    {
      key: "Immediate",
      value: "Immediate",
    },
    {
      key: "Within 7 days",
      value: "Within 7 days",
    },
    {
      key: "Within 15 days",
      value: "Within 15 days",
    },
    {
      key: "Within 30 days",
      value: "Within 30 days",
    },
    {
      key: "Within 45 days",
      value: "Within 45 days",
    },
    {
      key: "Within 60 days",
      value: "Within 60 days",
    },
  ];

  const selectTierTypeOptions = [
    {
      key: "FILLING_FAST",
      value: "FILLING_FAST",
    },
    {
      key: "HOT_VACANCY",
      value: "HOT_VACANCY",
    },
    {
      key: "TOP_TIER",
      value: "TOP_TIER",
    },
  ];

  const departmentOptions = [];
  const qualificationOptions = [];
  const experienceOptions = [];
  const specializationOptions = [];
  const professionOptions = [];

  const {
    DepartmentType_Data,
    QualificationsData,
    Experience_Data,
    Specialization_Data,
    ProfessionsData,
  } = useSelector((state) => state.common);
  const department_Info = DepartmentType_Data?.departmentTypeInfo;
  const qualifications = QualificationsData?.qualificationsInfo;
  const experiences = Experience_Data?.experienceInfo;
  const specializations = Specialization_Data?.specializationInfo;
  const professions = ProfessionsData?.professionsInfo;

  department_Info.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    departmentOptions.push(obj);
  });
// console.log("qualification", qualifications)
  qualifications?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    qualificationOptions.push(obj);
  });

  experiences.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    experienceOptions.push(obj);
  });

  specializations?.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    specializationOptions.push(obj);
  });

  professions.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });
 
  const handleCloseSnackbar = () => {
    setIsJobCreated(false);
  };
  // console.log("errors", errors);
  const onSubmit = async (data) => {
    try {
      await trigger();
      // clearErrors();

      // setError("jobTier", {
      //   type: "manual",
      //   message: "jobTier is required",
      // });

      setError("client.name", {
        type: "manual",
        message: data.client.name ? "" : "Client name is required",
      }); 

      setError("client.logo", {
        type: "manual",
        message: data.client.logo ? "" : "Logo  is required",
      });

      setError("department", {
        type: "manual",
        message: "Department is required",
      });
      setError("qualification", {
        type: "manual",
        message: "Qualification is required",
      });
      setError("numExperience", {
        type: "manual",
        message: "numExperience is required",
      });
      setError("specialization", {
        type: "manual",
        message: "Specialization is required",
      });
      setError("candidateType", {
        type: "manual",
        message: "CandidateType is required",
      });
      setError("jobDescription", {
        type: "manual",
        message: data.jobDescription ? "" : "JobDescription  is required",
      });
      setError("numberOfJobs", {
        type: "manual",
        message: data.numberOfJobs ? "" : "Number Of Jobs  is required",
      });
      setError("salary.type", {
        type: "manual",
        message: "CTC type is required",
      });
      setError("salary.min", {
        type: "manual",
        message: data.salary.min ? "" : "Salary min is required",
      });
      setError("salary.max", {
        type: "manual",
        message: data.salary.max ? "" : "Salary max is required",
      });
      setError("salary.currency", {
        type: "manual",
        message: data.salary.currency ? "" : "Currency  is required",
      });
      setError("salary.monthly", {
        type: "manual",
        message: data.salary.monthly ? "" : "Monthly salary  is required",
      });

      setError("address.address", {
        type: "manual",
        message: data.address.address ? "" : "Address is required",
      });
      setError("address.city", {
        type: "manual",
        message: data.address.city ? "" : "Address city is required",
      });
      setError("address.country", {
        type: "manual",
        message: data.address.country ? "" : "Country is required",
      });
      // setError("postedBy", {
      //   type: "manual",
      //   message: data.postedBy ? "" : "PostedBy is required",
      // });
      setError("joiningDate", {
        type: "manual",
        message: data.joiningDate ? "" : "Joining Date is required",
      });

      setError("ltcDateRange.startDate", {
        type: "manual",
        message: data.ltcDateRange?.startDate ? "" : "LTC start date is required",
      });
      
      setError("ltcDateRange.endDate", {
        type: "manual",
        message: data.ltcDateRange?.endDate ? "" : "LTC end date is required",
      });
      //  console.log("1-->",setError)
      const payload = {
        // state: data.state,
        type: data.type,
        jobTier: data.jobTier,
        ltcDateRange: {
          startDate: data.ltcDateRange?.startDate,
          endDate: data.ltcDateRange?.endDate,
        },
        client: {
          name: data.client.name,
          logo: data.client.logo,
        },
        department: data.department,
        postedBy: data.postedBy,
        qualification: data.qualification,
        numExperience: data.numExperience,
        specialization: data.specialization,
        candidateType: data.candidateType,
        jobDescription: data.jobDescription,
        numberOfJobs: data.numberOfJobs,
        salary: {
          type: data.salary.type,
          min: data.salary.min,
          max: data.salary.max,
          currency: data.salary.currency,
          monthly : data.salary.monthly,
        },
        address: {
          address: data.address.address,
          city: data.address.city,
          country: data.address.country,
        },
        joiningDate: data.joiningDate,
      };
      console.log("payload", payload);

      // Dispatch the addNewPermanentJob action
      dispatch(addNewPermanentJob(payload)).then(() => {
        // window.location.reload();
        // handleDialogClose();
        dispatch(getPermanentJobList());
      });

      var requireLTC;
      if(showLTCFields){
         requireLTC =
        data.ltcDateRange?.startDate &&
        data.ltcDateRange?.endDate
        // data.postedBy
      }

      const withoutTypeLTCVAlid = 
      // data.jobTier &&
      data.client.name &&
        data.client.logo &&
        data.department &&
        data.qualification &&
        data.numExperience &&
        data.specialization &&
        data.candidateType &&
        data.jobDescription &&
        data.numberOfJobs &&
        data.salary.type &&
        data.salary.min &&
        data.salary.max &&
        data.salary.currency &&
        // data.salary.monthly &&
        data.address.address &&
        data.address.city &&
        data.address.country &&
        // data.postedBy &&
        data.joiningDate;
      const isRequiredFieldsFilled =
        data.client.name &&
        data.client.logo &&
        data.department &&
        data.qualification &&
        data.numExperience &&
        data.specialization &&
        data.candidateType &&
        data.jobDescription &&
        data.numberOfJobs &&
        // data.salary.type &&
        // data.salary.min &&
        // data.salary.max &&
        data.salary.currency &&
        data.salary.monthly &&
        data.address.address &&
        data.address.city &&
        data.address.country 
        // data.postedBy &&
        // data.joiningDate;

        // console.log("isRequiredFieldsFilled:", isRequiredFieldsFilled);
        // console.log("requireLTC:", requireLTC);

      // if (requireLTC || isRequiredFieldsFilled) {
      //   handleDialogClose();
      // } else {
      //   console.log("All required fields must be filled.");
      //   toast.warning("All required fields must be filled.", {
      //     position: "top-right",
      //   });
      // }

      if (requireLTC) {
        if (isRequiredFieldsFilled) {
            handleDialogClose();
        } else {
            console.log("11-All required fields must be filled.");
            toast.warning("All required fields must be filled.", {
                position: "top-right",
            });
        }
    } else if (withoutTypeLTCVAlid) {
      handleDialogClose();
     } else {
        console.log("22-All required fields must be filled.");
        toast.warning("All required fields must be filled.", {
          position: "top-right",
        });
      }

      // setIsJobCreated(true);
      // handleDialogClose();
    } catch (error) {
      console.error("Error during form submission:", error);
      // Handle the error appropriately
    }
  };

  const handleDepartmentChange = (e) => {
    if (errors?.department?.message) errors.department.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    departmentOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setValue("department", userID);
    setDepartment(userID);
    // setIsDepartmentSelected(true);
  };

  const handleQualificationChange = (e) => {
    if (errors?.qualification?.message) errors.qualification.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    qualificationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setValue("qualification", userID);
    setQualification(userID);
    // setIsQualificationSelected(true)
  };

  const handleExperienceChange = (e) => {
    if (errors?.numExperience?.message) errors.numExperience.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    experienceOptions.map((obj) => {
      if (obj.value == e.target.value) { 
        userID = obj.key;
      }
    });
    setValue("numExperience", userID);
    setExperience(userID);
    // setIsExperienceSelected(true)
  };

  const handleSpecializationChange = (e) => {
    if (errors?.specialization?.message) errors.specialization.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    specializationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setValue("specialization", userID);
    setSpecialization(userID);
    // setIsSpecializationSelected(true)
  };

  const handleProfessionChange = (e) => {
    if (errors?.candidateType?.message) errors.candidateType.message = "";
    let userID;
    //Searching for the name in the Users array to find the ID
    professionOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
    setValue("candidateType", userID);
    setProfession(userID);
    // setIsProfessionSelected(true)
  };

  const handleSalaryChange = (e) => {
    // if(errors?.salary?.type?.message)
    // errors.salary.type.message="";

    // setValue("salary.type", e.target.value)

    const salaryValues = getValues("salary");
    // console.log("salaryValues", salaryValues)
    const isFilled =
      salaryValues &&
      salaryValues.type !== "" &&
      salaryValues.min !== "" &&
      salaryValues.max !== "" &&
      salaryValues.currency !== "";
    // console.log("isFilled", isFilled)
    setIsSalaryFilled(isFilled);
  };
  // console.log("isSalaryFilled", isSalaryFilled);

 

  const handleDateChange = (date) => {
    if (errors?.joiningDate?.message) errors.joiningDate.message = "";
    setValue("joiningDate", date);
    setSelectedDate(date);
  };

  const handleStartDateChange = (date) => {
    if (errors?.ltcDateRange?.startDate?.message) errors.ltcDateRange.startDate.message = "";
    setValue("ltcDateRange.startDate", date);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (errors?.ltcDateRange?.endDate?.message) errors.ltcDateRange.endDate.message = "";
    setValue("ltcDateRange.endDate", date);
    setSelectedEndDate(date);
  };
// console.log("profession", profession);


// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       // Construct the payload based on your criteria
//       const payload = {
//         criteria: {
//           listName: true,
//           profession: [profession]
//         }
//       };

//       // Dispatch the action with the payload
//       const response = await dispatch(getQualificationsID(payload));

//       // Handle the response if needed
//       console.log('Qualifications data:', response.data);
//       return response
//     } catch (error) {
//       // Handle errors
//       console.error('Error fetching qualifications:', error.message);
//     }
//   };

//   // Call the fetchData function
//   fetchData();
// }, [dispatch, profession]);

useEffect(() => {
 
  if (profession) {
    dispatch(getQualificationsList(
      {
        listName: true,
        profession: [profession]
      }));
      dispatch(getSpecializationsList({
        profession: [profession]
      }));
  }
   else {
    dispatch(getDepartmentType());
    dispatch(getExperienceList());
    dispatch(getProfessionsList());
  }
  
}, [profession]);

  // useEffect(() => {
  //   // if (profession) {
  //   //   dispatch(getQualificationsList({ profession: [profession]}));
  //   // } else {
  //   //   dispatch(getDepartmentType());
  //   //   dispatch(getQualificationsList());
  //   //   dispatch(getExperienceList());
  //   // dispatch(getSpecializationsList());
  //   // dispatch(getProfessionsList());
  //   // }
  //   const fetchData = async () => {
  //     try {
  //       dispatch(getDepartmentType());

  //       // Dispatch getQualificationsList
  //       dispatch(getQualificationsList());

  //       // Dispatch getExperienceList
  //       dispatch(getExperienceList());

  //      await  dispatch(getProfessionsList(profession));

  //       // Dispatch getQualificationsList after getProfessionsList
  //       dispatch(getQualificationsList());
        
  //       // Dispatch getSpecializationsList
  //       dispatch(getSpecializationsList());
  //     } catch (error) {
  //       // Handle errors here
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   // Call fetchData function
  //   fetchData();
    
  // }, [dispatch,profession]);

  useEffect(() => {
    getPermanentJobList();
  }, [dispatch,profession]);

  const handleFileIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (errors?.client?.logo?.message) errors.client.logo.message = "";
    const file = event.target.files[0];
    setSelectedFileName(file.name);
    dispatch(setPhoto(file))
      .then((response) => {
        // Assuming the response contains the URL or other information
        // console.log("File Upload Response:", response.payload.data.data[0].url);
        const logoUrl = response.payload.data.data[0].url;
        // console.log("url", logoUrl);
        // Assuming data.client.logo is an array
        setValue("client.logo", logoUrl);
        setFileLogo(logoUrl);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle error
      });
  };


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* State */}
        <Grid container spacing={1}>
          {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              State
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={selectStateOptions}
              placeholder="State"
              control={control}
              name="state"
              defaultValue="OPEN"
              getValues={getValues}
              customOnChange={(e) => setValue("state", e.target.value)}
            />
          </Grid> */}

          {/*Job Type */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Job Type
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={selectTypeOptions}
              placeholder="Job Type"
              control={control}
              name="type"
              defaultValue="DOMESTIC"
              getValues={getValues}
              // customOnChange={(e) => setValue("type", e.target.value)}
              customOnChange={(e) => {
                setValue("type", e.target.value);
                setShowLTCFields(e.target.value === "LTC");
              }}
              
            />
          </Grid>

            {/*jobTier Type */}
            <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
            Job Tier
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={selectTierTypeOptions}
              placeholder="Job Tier"
              control={control}
              name="jobTier"
              // defaultValue="DOMESTIC"
              getValues={getValues}
              // customOnChange={(e) => setValue("type", e.target.value)}
              customOnChange={(e) => {
                // if (errors?.jobTier.message)
                //   errors.jobTier.message = "";
                setValue("jobTier", e.target.value);
                // setShowLTCFields(e.target.value === "LTC");
              }}
              
            />
          </Grid>

          <>
    {showLTCFields && (
      <>
        {/* LTC Start Date */}
        <Grid item xs={3}>
          <InputLabel fullWidth className="input-labels">
            LTC Start Date
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <Controller
            name={"LTC Start Date"}
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  inputFormat="DD/MM/YYYY"
                  value={selectedStartDate}
                  onChange={(date) => handleStartDateChange(date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                    />
                  )}
                  minDate={new Date()}
                />
              </LocalizationProvider>
            )}
          />
           {selectedStartDate ? (
              <></>
            ) : (
              <FormHelperText>{errors?.ltcDateRange?.startDate?.message}</FormHelperText>
            )}
        </Grid>

        {/* LTC End Date */}
        <Grid item xs={3}>
          <InputLabel fullWidth className="input-labels">
            LTC End Date
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <Controller
            name={"LTC End Date"}
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  inputFormat="DD/MM/YYYY"
                  value={selectedEndDate}
                  onChange={(date) => handleEndDateChange(date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ width: "100%", marginTop: "0.4rem" }}
                    />
                  )}
                  minDate={new Date()}
                />
              </LocalizationProvider>
            )}
          />
           {selectedEndDate ? (
              <></>
            ) : (
              <FormHelperText>{errors?.ltcDateRange?.endDate?.message}</FormHelperText>
            )}
        </Grid>
      </>
    )}
  </>

           {/* LTC Start Date */}
           {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
            LTC Start Date
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <Controller
              name={"LTC Start Date"}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    value={selectedStartDate} 
                    // onChange={handleDateChange}
                    onChange={(date) => handleStartDateChange(date)}
                    
                    renderInput={(params) => <TextField {...params}
                    fullWidth 
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    
                    />}
                    minDate={new Date()}  
                  />
                </LocalizationProvider>
              )}
            />
          </Grid> */}
          

          {/* LTC Start Date */}
          {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
            LTC End Date
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <Controller
              name={"LTC End Date"}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    value={selectedEndDate} 
                    // onChange={handleDateChange}
                    onChange={(date) => handleEndDateChange(date)}
                    
                    renderInput={(params) => <TextField {...params}
                    fullWidth 
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    
                    />}
                    minDate={new Date()}  
                  />
                </LocalizationProvider>
              )}
            />
          </Grid> */}

          {/* Client Name */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Client Name
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Name"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("client.name")}
                error={errors?.client?.name?.message}
                helperText={errors?.client?.name?.message}
              />
            </form> 
          </Grid>

          {/* Client Logo */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Client Logo
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <Box display="flex" alignItems="center">
                <MDInput
                  placeholder="Upload Client Logo"
                  sx={{ width: "100%", marginTop: "0.4rem" }}
                  inputProps={{
                    style: { fontSize: 15, fontWeight: "bold" },
                  }}
                  value={selectedFileName}
                  readOnly
                />

                <AttachFileIcon
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={handleFileIconClick}
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  // accept=".img, .png"
                  onChange={handleFileChange}
                />
              </Box>
            </form>
            {fileLogo ? (
              <></>
            ) : (
              <FormHelperText>{errors?.client?.logo?.message}</FormHelperText>
            )}
          </Grid>

          {/* Department */}

          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Department
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={departmentOptions}
              placeholder="Department"
              control={control}
              name="department"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleDepartmentChange}
            />
            {/* <FormHelperText>{errors?.department?.message}</FormHelperText> */}
            {department ? (
              <></>
            ) : (
              <FormHelperText>{errors?.department?.message}</FormHelperText>
            )}
          </Grid>

          {/* candidateType */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              CandidateType
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={professionOptions}
              placeholder="CandidateType"
              control={control}
              name="candidateType"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleProfessionChange}
            />
            {/* <FormHelperText>{errors?.candidateType?.message}</FormHelperText> */}
            {profession ? (
              <></>
            ) : (
              <FormHelperText>{errors?.candidateType?.message}</FormHelperText>
            )}
          </Grid>

          {/* Qualification */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Qualification
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={qualificationOptions}
              placeholder="Qualification"
              control={control}
              name="qualification"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleQualificationChange}
            />
            {/* <FormHelperText>{errors?.qualification?.message}</FormHelperText> */}
            {qualification ? (
              <></>
            ) : (
              <FormHelperText>{errors?.qualification?.message}</FormHelperText>
            )}
          </Grid>

          {/* specialization */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Specialization
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={specializationOptions}
              placeholder="Specialization"
              control={control}
              name="specialization"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleSpecializationChange}
            />
            {/* <FormHelperText>{errors?.specialization?.message}</FormHelperText> */}
            {specialization ? (
              <></>
            ) : (
              <FormHelperText>{errors?.specialization?.message}</FormHelperText>
            )}
          </Grid>

          {/* numExperience */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              NumExperience
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={experienceOptions}
              placeholder="NumExperience"
              control={control}
              name="numExperience"
              defaultValue="none"
              getValues={getValues}
              // {...register("department")}
              customOnChange={handleExperienceChange}
            />
            {/* <FormHelperText>{errors?.numExperience?.message}</FormHelperText> */}
            {experience ? (
              <></>
            ) : (
              <FormHelperText>{errors?.numExperience?.message}</FormHelperText>
            )}
          </Grid>

          {/* jobDescription */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Job Description
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Job Description"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                multiline
                inputProps={{
                  rows: 6,
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("jobDescription")}
                error={errors?.jobDescription?.message}
                helperText={errors?.jobDescription?.message}
              />
            </form>
          </Grid>

          {/* numberOfJobs */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Number Of Jobs
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Number Of Jobs"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("numberOfJobs")}
                error={errors?.numberOfJobs?.message}
                helperText={errors?.numberOfJobs?.message}
              />
            </form>
          </Grid>


          {/* Joining Date */}
          <>
    {!showLTCFields && (
      <>
       <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Tentative Joining date
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={selectJoiningDatesOptions}
              placeholder="Tentative Joining date"
              control={control}
              name="joiningDate"
              // defaultValue="OPEN"
              getValues={getValues}
              customOnChange={(e) => {
                if (errors?.joiningDate?.message)
                  errors.joiningDate.message = "";
                // handleSalaryChange()
                setValue("joiningDate", e.target.value);
                setJoiningDate(e.target.value);
              }}
            />
            {joiningDate  ? (
              <></>
            ) : (
              <FormHelperText>{errors?.joiningDate?.message}</FormHelperText>
            )}
          </Grid>
        
      </>
    )}
  </>

          {/* Joining Date */}
          {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Tentative Joining date
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <SelectDropDown
              options={selectJoiningDatesOptions}
              placeholder="Tentative Joining date"
              control={control}
              name="joiningDate"
              // defaultValue="OPEN"
              getValues={getValues}
              customOnChange={(e) => {
                if (errors?.joiningDate?.message)
                  errors.joiningDate.message = "";
                // handleSalaryChange()
                setValue("joiningDate", e.target.value);
                setJoiningDate(e.target.value);
              }}
            />
            {joiningDate ? (
              <></>
            ) : (
              <FormHelperText>{errors?.joiningDate?.message}</FormHelperText>
            )}
          </Grid> */}
          {/* <Grid item xs={9}>
            <Controller
              name={"joiningDate"}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate} 
                    // onChange={handleDateChange}
                    onChange={(date) => handleDateChange(date)}
                    
                    renderInput={(params) => <TextField {...params}
                    fullWidth 
                    sx={{ width: "100%", marginTop: "0.4rem" }}
                    
                    />}
                    minDate={new Date()}  
                  />
                </LocalizationProvider>
              )}
            />
            {selectedDate ? (
              <></>
            ) : (
              <FormHelperText>{errors?.joiningDate?.message}</FormHelperText>
            )}
          </Grid> */}
          {/* <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Joining Date"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("JoiningDate")}
                // error={errors?.postedBy?.message}
                // helperText={errors?.postedBy?.message}
              />
            </form>
          </Grid> */}

{/* {console.log("Before rendering:", showLTCFields)} */}

{showLTCFields && (
  <>
    <Grid item xs={3}>
      <InputLabel fullWidth className="input-labels">
        CTC Type
      </InputLabel>
    </Grid>
    <Grid item xs={9}>
      <form noValidate>
        <MDInput
          placeholder="salary type"
          sx={{ width: "100%", marginTop: "0.4rem" }}
          inputProps={{
            style: { fontSize: 15, fontWeight: "bold" },
          }}
          {...register("salary.type")}
          defaultValue="MONTHLY"
          disabled
        />
      </form>
    </Grid>
  </>
)}


{!showLTCFields && (
      <>
      {/* salary type */}
      <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              CTC Type
            </InputLabel>
          </Grid>
          <Grid item xs={9} sx={{ marginTop: "0.4rem" }}>
            <SelectDropDown
              options={selectSalaryTypeOptions}
              placeholder="CTC Type"
              control={control}
              name="salary.type"
              // defaultValue="none"
              // defaultValue={showLTCFields ? "MONTHLY" : "none"}
              getValues={getValues}
              // customOnChange={handleSalaryChange}

              customOnChange={(e) => {
                if (errors?.salary?.type?.message)
                  errors.salary.type.message = "";
                // handleSalaryChange()
                setValue("salary.type", e.target.value);
                setSalaryType(e.target.value);
              }}
            />
            {/* <FormHelperText>{errors?.salary?.type?.message}</FormHelperText> */}
            {salaryType ? (
              <></>
            ) : (
              <FormHelperText>{errors?.salary?.type?.message}</FormHelperText>
            )}
          </Grid>
        
      </>
    )}

          <>
    {showLTCFields && (
      <>
      
          {/* monthly salary */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
            Monthly Salary
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Monthly Salary"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("salary.monthly")}
                onKeyDown={(e) => {
                  // Allow only digits (0-9) and prevent the minus sign (-)
                  if (
                    !((e.key >= "0" && e.key <= "9") || e.key === "Backspace")
                  ) {
                    // Invalid input, prevent the key press
                    e.preventDefault();
                  }
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.monthly?.message}
                helperText={errors?.salary?.monthly?.message}
              />
              {/* <FormHelperText>{errors?.salary?.min?.message}</FormHelperText> */}
            </form>
          </Grid>
        
      </>
    )}
  </>

          {/* min and max */}
          <>
    {!showLTCFields && (
      <>
      
          {/* salary min */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Minimum Salary
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Minimum Salary"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("salary.min")}
                onKeyDown={(e) => {
                  // Allow only digits (0-9) and prevent the minus sign (-)
                  if (
                    !((e.key >= "0" && e.key <= "9") || e.key === "Backspace")
                  ) {
                    // Invalid input, prevent the key press
                    e.preventDefault();
                  }
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.min?.message}
                helperText={errors?.salary?.min?.message}
              />
              {/* <FormHelperText>{errors?.salary?.min?.message}</FormHelperText> */}
            </form>
          </Grid>

          {/* salary max */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Maximum Salary
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Maximum Salary"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("salary.max")}
                onKeyDown={(e) => {
                  // Allow only digits (0-9) and prevent the minus sign (-)
                  if (
                    !((e.key >= "0" && e.key <= "9") || e.key === "Backspace")
                  ) {
                    // Invalid input, prevent the key press
                    e.preventDefault();
                  }
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.max?.message}
                helperText={errors?.salary?.max?.message}
              />
              {/* <FormHelperText>{errors?.salary?.max?.message}</FormHelperText> */}
            </form>
          </Grid>
        
      </>
    )}
  </>

          {/* salary min */}
          {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Minimum Salary
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Minimum Salary"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("salary.min")}
                onKeyDown={(e) => {
                  // Allow only digits (0-9) and prevent the minus sign (-)
                  if (
                    !((e.key >= "0" && e.key <= "9") || e.key === "Backspace")
                  ) {
                    // Invalid input, prevent the key press
                    e.preventDefault();
                  }
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.min?.message}
                helperText={errors?.salary?.min?.message}
              />
              {/* <FormHelperText>{errors?.salary?.min?.message}</FormHelperText> */}
            {/* </form>
          </Grid>  */}

          {/* salary max */}
          {/* <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Maximum Salary
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Maximum Salary"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("salary.max")}
                onKeyDown={(e) => {
                  // Allow only digits (0-9) and prevent the minus sign (-)
                  if (
                    !((e.key >= "0" && e.key <= "9") || e.key === "Backspace")
                  ) {
                    // Invalid input, prevent the key press
                    e.preventDefault();
                  }
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.max?.message}
                helperText={errors?.salary?.max?.message}
              />
              {/* <FormHelperText>{errors?.salary?.max?.message}</FormHelperText> */}
            {/* </form>
          </Grid>  */}

          {/* currency */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Currency
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="currency"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                  maxLength: 3,
                }}
                {...register("salary.currency")}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
                }}
                // onChange={handleSalaryChange}
                error={errors?.salary?.currency?.message}
                helperText={errors?.salary?.currency?.message}
              />
            </form>
          </Grid>

          {/* address */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Client Address
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Client Address"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                multiline
                inputProps={{
                  rows: 6,
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("address.address")}
                error={errors?.address?.address?.message}
                helperText={errors?.address?.address?.message}
              />
            </form>
          </Grid>

          {/* city */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Address City
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Address City"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("address.city")}
                error={errors?.address?.city?.message}
                helperText={errors?.address?.city?.message}
              />
            </form>
          </Grid>

          {/* country */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              Country
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
              <MDInput
                placeholder="Country"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("address.country")}
                error={errors?.address?.country?.message}
                helperText={errors?.address?.country?.message}
              />
            </form>
          </Grid>

          {/* PostedBy */}
          <Grid item xs={3}>
            <InputLabel fullWidth className="input-labels">
              PostedBy
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <form noValidate>
            {showLTCFields ? (
      <MDInput
        placeholder="PostedBy"
        sx={{ width: "100%", marginTop: "0.4rem" }}
        inputProps={{
          style: { fontSize: 15, fontWeight: "bold" },
        }}
        {...register("postedBy")}
        // error={errors?.postedBy?.message}
        // helperText={errors?.postedBy?.message}
      />
    ) : (
      <MDInput
        placeholder="PostedBy"
        sx={{ width: "100%", marginTop: "0.4rem" }}
        inputProps={{
          style: { fontSize: 15, fontWeight: "bold" },
        }}
        {...register("postedBy")}
      />
    )}
              {/* <MDInput
                placeholder="PostedBy"
                sx={{ width: "100%", marginTop: "0.4rem" }}
                inputProps={{
                  style: { fontSize: 15, fontWeight: "bold" },
                }}
                {...register("postedBy")}
                error={errors?.postedBy?.message}
                helperText={errors?.postedBy?.message}
              /> */}
            </form>
          </Grid>
        </Grid>

        <MDButton
          variant="contained"
          color="info"
          type="submit"
          sx={{ marginTop: "1.5rem" }}
          // disabled={!isDepartmentSelected || !isQualificationSelected || !isExperienceSelected || !isSpecializationSelected || !isProfessionSelected}
        >
          create
        </MDButton>
      </form>
    </div>
  );
};

export default JobFillForm;
