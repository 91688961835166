import axiosInstance from "../axios";
import { toast } from "react-toastify";




//get all monetization list
export const getVideoService = async (payload) => {
    try {
      if (payload === undefined) payload = false;
      let criteria = {
        criteria: {
    
        },
        limit: payload.pageSize,
        offset: payload.currentPage,
      };
      // console.log("criteria-->", criteria);
      const response = await axiosInstance.post(`/video/list`, criteria);
    //   console.log("response111", response);
      return response;
    } catch (e) {
      return { error: e.response.data };
    }
  };

  // Add User
export const addNewVideo = async (payload) => {
    try {
      const response = await axiosInstance.post(`/video/create`, payload);
    //   console.log("responseCreate", response);
      if(response.data.msg === "Video added successfully"){
        toast.success("Video added successfully")
      }
      return response;
    } catch (e) {
        toast.error("Index already exists", {
            position: "top-right",
        });
      return { error: e.response.data.message };
    }
  };


  //upload video
  export const uploadVideo = async (payload) => {
    try {
    //   console.log("payload", payload);
      var video = payload;
  
      var formdata = new FormData();
  
      //if we want upload multiple images
      // payload.forEach((image, index) => {
      //   formdata.append(`file${index}`, image);
      // });
  
      formdata.append("video", video);
  
      const response = await axiosInstance.post(`/common/uploadVideo`, formdata);
    //   console.log("imageResponse", response);
  
      return response;
    } catch (e) {
      return { error: e.response.data.message };
    }
  };

  //get video by ID
  export const getVideoByIdService = async (payload) => {
    try {
      // console.log("payload>>",payload)
      let criteria = { criteria: { _id: payload } };
      // console.log("criteria>>", criteria)
      const response = await axiosInstance.post(`/video/list`, criteria);
      // console.log("1--->",response)
      return response?.data?.data;
    } catch (e) {
      return { error: e.response.data.message };
    }
  };

export const updateVideoById = async (payload) => {
    // console.log("getVideo-->", payload);

    try {
      const response = await axiosInstance.patch(`/video/${payload.id}`, payload.payload);
    //   console.log("responseUpdate", response);
  
    //   if (response.data.status === true) {
    //     toast.success("Updated Successfully", {
    //       position: "top-right",
    //     });
    //   }
  
      return response;
    } catch (e) {
      console.error("Error", e);
      toast.error("Index already exists", {
                position: "top-right",
            });
    //   toast.error("Update failed", {
    //     position: "top-right",
    //   });
      return { error: e.response?.data || e.message }; // Ensure to handle cases where e.response might be undefined
    }
  };


  //user videos
  //get user videos
  export const getUserVideoService = async (payload) => {
    try {
      if (payload === undefined) payload = false;
      let criteria = {
        criteria: {
          isDeleted: payload ? true : false 
        },
        limit: payload.pageSize,
        offset: payload.currentPage,
      };
      // console.log("criteria-->", criteria);
      const response = await axiosInstance.post(`/userVideo/list`, criteria);
    //   console.log("response111", response);
      return response;
    } catch (e) {
      return { error: e.response.data };
    }
  };

  //update by ID
  export const updateUserVideoById = async (payload) => {
    // console.log("getVideo-->", payload);

    try {
      const response = await axiosInstance.patch(`/userVideo/${payload.id}`, payload.payload);
        if (response.data.status === true) {
        toast.success("Updated Successfully", {
          position: "top-right",
        });
      }
      return response;
    } catch (e) {
      console.error("Error", e);
      return { error: e.response?.data || e.message };
    }
  };

  