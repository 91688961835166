
import { Delete, PhotoCamera, Upload } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getRates } from "services/hospitalServices";
import { deleteRate, getRatesList } from "../hospital/Redux/actionCreator";
import { getMonetizationList} from "../users/Redux/actionCreator";
import { getVideoList} from "../users/Redux/actionCreator";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { formatDate } from "util/Helper";
import MDButton from "components/MDButton";
import { uploadHospitalRateFile } from "services/hospitalServices";
import { uploadMonetizationFile } from "services/userServices";
import UploadMonetizationFile from "../users/UploadMonetizationFile";
import axiosInstance from "../../axios";
import Pagination from "@mui/material/Pagination";
import AddVideos from './components/addVideos';
const Videos = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  //Handler Functions
  const handleDelete = (ID) => {
    dispatch(deleteRate(ID));
  };

  const handleCellClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleRowClick = (event) => {
    event.originalEvent?.stopPropagation();
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };
  

  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        // const rowName = cellValues?.row?._id
        // return rowName
        const Id = cellValues?.row?._id;
        // return Id;
        return <Link to={`/videoManagement/video/${Id}`}>{Id}</Link>;
      },
      flex: 1,
    },
    {
      field: "index",
      headerName: `Index`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.index
        return rowName
      },
      flex: 1,

      // valueGetter: getCreatedAT,
    },
    {
      field: "title",
      headerName: `Title`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.title
        return rowName || "NA"
      },
      flex: 1,

      // valueGetter: getCreatedAT,
    },
    {
      field: "url",
      headerName: `Video Url`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.url
        return rowName || "NA"
      },
      flex: 2.5,
    },
    {
      field: "thumbNailUrl",
      headerName: `Thumb Nail Url`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.thumbNailUrl
        return rowName || "NA"
      },
      flex: 2.5,
    },
    
    {
        field: "enable",
        headerName: `Enable`,
        renderCell: (cellValues) => {
          const rowName = cellValues?.row?.enable
          if(rowName === false){
            return "False"
          }else{
            return "True"
          }
        },
        flex: 1,
      },

  ];  

const [isOpen, setisOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getVideoList());
  }, []);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const uploadDialogClose = () => {
    setIsOpenUpload(false);
  };

  const uploadDialogOpen = () => {
    setIsOpenUpload(true);
  };

  const { hospital_rates_Data } = useSelector((state) => state?.hospital);
  var rates = hospital_rates_Data?.ratesInfo;


      const { Videos_Data } = useSelector(
        (state) => state.user
      );
      // console.log("1382-->", Users_Data)
      const monetizationUsers = Videos_Data?.Videos_DataInfo;
    
      console.log("1-->", monetizationUsers);
    
      //   console.log("DocVerifaction_List_Data", DocVerifaction_List_Data);
    //   const totalCount = Monetization_MLP?.total;


  function formatDate() {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        title="Add Video"
        title1="Videos"
        isOpen={isOpen}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        handleUploadDialogClose={uploadDialogClose}
        handleUploadDialogOpen={uploadDialogOpen}
        dialogContent={
          <AddVideos handleDialogClose={handleDialogClose} />
        }
        uploadDialogContent={
          <UploadMonetizationFile
            handleUploadDialogClose={uploadDialogClose}
            title="Monetization File"
          />
        }
      />

      <MDBox sx={{ marginTop: "2rem" }}>
        <DataGrid
          density="compact"
          getRowId={(row) => row._id}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: {
                fileName: `Rates-${formatDate()}`,
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                marginCandidate: false,
                marginHospital: false,
                createdBy: false,
                updatedBy: false,
                Delete: false,
              },
            },
          }}
          slots={{
            loadingOverlay: <CircularProgress disableShrink />,
          }}
          loading={monetizationUsers.length > 0 ? false : true}
          columns={columns}
          rows={[...monetizationUsers]}
          pagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          //isRowSelectable
          sx={{
            backgroundColor: "#ffff",
            color: "grey",
            height: "35rem",
            margin: "1rem",
            borderRadius: "0.5rem",
            "& .MuiDataGrid-cell": {
              padding: "15px",
              fontSize: "12px",
            },
            borderColor: "light-grey",
            "& .MuiDataGrid-cell:hover": {
              color: "info",
            },
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default Videos;

