import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteDialog from "components/DeleteDialogBox/DeleteDialog";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { getEventList } from "services/eventServices";
import { getInterviewList } from "services/eventServices";
import { formatDate } from "util/Helper";
//import { eventDeletion, eventList, interviewList } from "./Redux/actionCreator";
import { eventDeletion, eventList, interviewList } from "../events/Redux/actionCreator";
import { fetchBankDetailsData } from "./Redux/actionCreator";
import moment from "moment";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const BankDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //VIEW
  const handleView = (id) => {
    navigate(`/eventDetails/update/${id}`);
  };

  //VALUE GETTERS

  function getID(params) {
    return `${params?.row?._id || "NA"}`;
  }

  function getVerified(params) {
    // return `${params?.row?.isVerified || "NA"}`;
    return `${params?.row?.isVerified}` ? `${params?.row?.isVerified}` : "NA";
  }

  function getAccountNumber(params) {
    return `${params?.row?.accountNumber || "NA"}`;
  }

  function getBankName(params) {
    return `${params?.row?.bankName || "NA"}`;
  }

  function getIfsc(params) {
    return `${params?.row?.ifsc || "NA"}`;
  }

  function getAccountType(params) {
    return `${params?.row?.accountType|| "NA"}`;
  }

  function getBranchAddress(params) {
    return `${params?.row?.branchAddress || "NA"}`;
  }

  function getUser(params) {
    return `${params?.row?.user?.name || "NA"}`;
  }

  function getPan(params) {
    return `${params?.row?.user?.panNumber || "NA"}`;
  }

  function getStatus(params) {
    return `${params?.row?.status || "NA"}`;
  }


  function getIsActive(params) {
    // console.log("1------>", params)
    return `${params?.row?.isActive}` ? `${params?.row?.isActive}` : "NA";
  }

  function getCreatedAT(params) {
    var a;
    // var rowName = formatDate(params?.row?.createdAt);
    var rowName = params?.row?.createdAt;
    // var dateNOW = new Date(rowName);
    // var dateNOW = moment(rowName).format("YYYY/MM/DD")
    // return dateNOW;
    if(rowName){
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    }else{
      a = "NA";
      return `${a}`;
    }
  }

//   function getUpdatedAT(params) {
//     var rowName = formatDate(params?.row?.createdAt);
//     var dateNOW = new Date(rowName);
//     return dateNOW;
//   }

  //InterviewsSlot Listing Columns
  const columns = [
    {
      field: "_id",
      headerName: `ID`,
      renderCell: (cellValues) => {
        const rowId = cellValues?.row?._id;
        return <Link to={`/bankId/${rowId}`}>{rowId}</Link>;
        //return rowId;
      },

      valueGetter: getID,
      flex: 1.2,
    },
    {
      field: "isVerified",
      headerName: `IsVerified`,
      renderCell: (cellValues) => {
        return `${cellValues?.row?.isVerified}`
        ? `${cellValues?.row?.isVerified}`
        : "NA";
      },
      valueGetter: getVerified,
      flex: 1.3,
    },

    {
      field: "accountNumber",
      headerName: `Account Number`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.accountNumber;
        return rowName
      },
      valueGetter: getAccountNumber,
      flex: 2,
    },

    {
      field: "bankName",
      headerName: "Bank Name",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.bankName;
        return rowName
      },

      valueGetter: getBankName,
      flex: 1.5,
    },
    {
      field: "ifsc",
      headerName: "Ifsc",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.ifsc;
        return rowName;
      },

      valueGetter: getIfsc,
      flex: 1,
    },
    {
      field: "accountType",
      headerName: "Account Type",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.accountType;
        // const rowId = cellValues?.row?.employer?._id;
        // return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        return rowName
      },

      valueGetter: getAccountType,
      flex: 1.5,
    },
    {
      field: "branchAddress",

      headerName: "Branch Address",

      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.branchAddress;
        return rowName
      },
      flex: 2,
      valueGetter: getBranchAddress,
    },
    {
      field: "user",
      headerName: "User",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.user?.name;
        const rowId = cellValues?.row?.user?._id;
        return <Link to={`/users/name/${rowId}`}>{rowName}</Link>;
        //console.log("Candidate-->", cellValues.row._id);
      },
      valueGetter: getUser,
      flex: 1,
    },
    {
      field: "panNumber",
      headerName: "Pan Number",
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.user?.panNumber
        return rowName;
        //console.log("Candidate-->", cellValues.row._id);
      },
      valueGetter: getPan,
      flex: 1,
    },
    {
      field: "status",
      headerName: `Status`,
      renderCell: (cellValues) => {
        const rowName = cellValues?.row?.status;
        return rowName;
      },
      valueGetter: getStatus,
      flex: 1,
    },
    {
      field: "isActive",
      headerName: `IsActive`,
      renderCell: (cellValues) => {
        return `${cellValues?.row?.isActive}`
        ? `${cellValues?.row?.isActive}`
        : "NA";
      },
      valueGetter: getIsActive,
      flex: 1,
    },
  //   return `${formattedDate}`;
  // } else {
  //   formattedDate = "NA";
  //   return `${formattedDate}`;
  // }
    {
      field: "createdAt",
      type: "date",
      headerName: `Created At`,
      renderCell: (cellValues) => {
        var a;
        // console.log("cellValues: ",  cellValues)
        const roleName = cellValues?.row?.createdAt;
        if(roleName){
          a = moment(roleName).format("YYYY/MM/DD");
          return `${a}`;
        }else{
          a = "NA";
          return `${a}`;
        }
       
      },
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },
    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    //   flex: 1.5,
    // },
  ];
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { Bank_Details_Data } = useSelector((state) => state.invoice);
  const eventListData = Bank_Details_Data?.bankDetailsInfo;
  // console.log("eventListData1-->", eventListData);
  
  var bankLoading = Bank_Details_Data?.bankDetailsLoading;
//   const { Interview_Details_Data } = useSelector((state) => state.events);
//   const eventListData = Interview_Details_Data?.interviewDetailsInfo;
//   console.log("eventListData-->", eventListData);
  
//   var eventLoading = Interview_Details_Data?.interviewDetailsLoading;

  useEffect(() => {
    setLoading(bankLoading);
  }, [bankLoading]);

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  useEffect(() => {
    dispatch(fetchBankDetailsData(false));
  }, []);

  function formatDate() {
    const now = new Date();
    // console.log("date>>", now);
    
    const day = String(now.getDate()).padStart(2, '0');
    
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();
  
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;
    
    return formattedDate;
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FilterBar
        //title="Create Event"
        title1="Bank Details"
        isOpen={isOpen}
        showDialog={false}
        showisDeleted={false}
        handleDialogOpen={handleDialogOpen}
        handleDialogClose={handleDialogClose}
        //handleDeleteApi={handleDeleteApi}
        // customDialogOnClick={() => {
        //   navigate(`/eventDetails/create`);
        // }}
      />

      <DataGrid
        getRowId={(row) => row._id}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName: `BankDetails-${formatDate()}`,
            },
          },
        }}
        density="compact"
        initialState={{
          columns: {
            // columnVisibilityModel: {
            //   isDeleted: false,
            //   professionalType: false,
            //   qualification: false,
            //   specialization: false,
            //   numExperience: false,
            //   workCity: false,
            //   createdAt: false,
            //   updatedAt: false,
            //   createdBy: false,
            //   updatedBy: false,
            //},
          },
        }}
        //isRowSelectable
        loading={bankLoading ? true : false}
        columns={columns}
        rows={[...eventListData]}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        sx={{
          backgroundColor: "#ffff",
          color: "grey",
          height: "35rem",
          height: "35rem",
          margin: "1rem",
          borderRadius: "0.5rem",
          "& .MuiDataGrid-cell": {
            padding: "15px",
            fontSize: "12px",
          },
          borderColor: "light-grey",
          "& .MuiDataGrid-cell:hover": {
            color: "info",
          },
        }}
      />
    </DashboardLayout>
  );
};
export default BankDetails;
